import {FormValues} from "../lib/types";
import {Editable} from "../../../../../shared";
import React from "react";
import {ContactInformationView} from "./ContactInformationView";
import {ContactInformationForm} from "./ContactInformationForm";
import {Card} from "@mui/material";
import {CityData, CityFormValue} from "../../../../../feature/city-selector/lib/types";

interface Props extends Editable {
  linkedInUrl?: string,
  email?: string,
  phone?: string,
  loading: boolean,
  cities?: CityData[],
  onSubmit: (values: FormValues) => Promise<any>,
}

export function BasicContactInformation(props: Props) {
  const {
    linkedInUrl,
    cities,
    onSubmit,
    loading
  } = props
  const [edit, setEdit] = React.useState(false);

  if (edit) {
    return <Card sx={{p: '20px 24px'}}>
      <ContactInformationForm loading={loading}
                              onBackClick={() => setEdit(false)}
                              onSubmit={async values => {
              await onSubmit(values);
              setEdit(false);
            }}
            initial={{
              linkedInUrl: linkedInUrl || '',
              cities: cities as CityFormValue[],
            }}
      />
    </Card>
  }

  return <Card sx={{p: '20px 24px'}}>
    <ContactInformationView {...props} onEdit={() => setEdit(true)} />
  </Card>
}
