import {
  Box, Container, Typography
} from "@mui/material";
import React from "react";
import {
  useCompanyContractsQuery,
} from "../../api/sdl";
import Loading from "../../shared/components/Loading";
import CompanyContract from "../../shared/components/CompanyContract";


function CompanyContracts() {

  const contractsQuery = useCompanyContractsQuery({fetchPolicy: 'no-cache'});

  const contractList = React.useMemo(() => {
    return contractsQuery.data?.companyContracts|| [];
  }, [contractsQuery.data])

  return <Box>
    <Container sx={{p: 3}} maxWidth={'lg'}>
      <Box mt={2}>
      <Typography variant={'h2'} sx={{mb: 1}}>Contracts</Typography>
        <Loading in={contractsQuery.loading}>
          {(!contractList.length) && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '500px'}}>
            <Typography sx={{fontWeight: 600, color: '#2C92D5', mb: 7}}>You didn’t hire any contractor yet.</Typography>
            <Box component={'img'} src={`/img/teenyicons/text-document-blue.svg`} width={157} height={157} />
          </Box>}
          {!!contractList.length && <>
            {contractList.map((item, i) => <CompanyContract key={i} item={item} />)}
            <Box sx={{display: 'flex', gap: 5, width: 1, justifyContent: 'center'}}>
            </Box>
          </>}
        </Loading>
      </Box>
    </Container>
  </Box>;
}

export default CompanyContracts
