import {LayoutType} from "../../shared/types";
import React, {Dispatch} from "react";
import {
  PositionFilterForContractorInput,
  PositionOrder,
  SalaryRangeType
} from "../../api/sdl";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  List, ListSubheader,
  MenuItem,
  Select, TextField,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import {CaretDown, SquaresFour, XCircle} from "phosphor-react";
import {getCategoryColor, states} from "../../shared/utils";
import LayoutTypeButton from "../../shared/components/LayoutTypeButton";
import AttributesFilter from "../../shared/components/AttributesFilter";
import StatesFilter from "../../shared/components/StatesFilter";

interface PositionFilterProps {
  layoutType: LayoutType,
  onLayoutTypeChange: Dispatch<React.SetStateAction<LayoutType>>,
  loading: boolean,
  filter: PositionFilterForContractorInput,
  onFilterChange: Dispatch<React.SetStateAction<PositionFilterForContractorInput>>
}
const ContractorPositionFilter = (props: PositionFilterProps) => {

  const {layoutType,
    onLayoutTypeChange: setLayoutType,
    filter,
    onFilterChange: setFilter,
    loading
  } = props;

  return <Box sx={{display: {xs: 'none', md: 'flex'}, justifyContent: 'space-between', mb: 8}}>
    <Box sx={{display: 'flex', gap: 1.5, alignItems: 'center'}}>

      <LayoutTypeButton active={layoutType === 'grid'} onClick={() => setLayoutType('grid')}>
        <SquaresFour size={16} color={layoutType !== 'grid' ? '#838383' : "#FFF"} weight="regular" />
      </LayoutTypeButton>

      <LayoutTypeButton active={layoutType !== 'grid'} onClick={() => setLayoutType('list')}>
        {layoutType === 'grid' && <Box component={'img'} src={`/img/teenyicons/list-layout.svg`}  width={15} height={15} />}
        {layoutType !== 'grid' && <Box component={'img'} src={`/img/teenyicons/list-layout-white.svg`}  width={15} height={15} />}
      </LayoutTypeButton>

      <Typography variant={'body2'} sx={{ml: '20px', whiteSpace: 'nowrap'}}>Sort by:</Typography>
      <Select value={filter.orderBy}
              sx={{minWidth: 110}}
              onChange={(e) => setFilter(prev => ({...prev, orderBy: e.target.value as PositionOrder}))}>
        <MenuItem value={PositionOrder.Recent}>Newest</MenuItem>
        <MenuItem value={PositionOrder.Oldest}>Oldest</MenuItem>
      </Select>
      <Typography variant={'body2'} sx={{ml: '20px', whiteSpace: 'nowrap'}}>Filter by:</Typography>

      <StatesFilter multiple loading={loading} value={filter.locations || []} onChange={locations => setFilter({...filter, locations})} />

      <AttributesFilter ids={filter.attributes || []} onChange={attributes => setFilter({...filter, attributes})} />

      <Select
        value={[filter.salaryRangeMin, filter.salaryRangeMax, filter.salaryRangeType].filter(Boolean).join('_') || 'all'}

        onChange={(e, v) => {
          const [min, max, type] = e.target.value ? e.target.value.split('_') : [];
          if (type) {
            setFilter(prev => ({
              ...prev,
              salaryRangeMin: +min,
              salaryRangeMax: +max,
              salaryRangeType: type as SalaryRangeType
            }))
          } else {
            setFilter(prev => ({
              ...prev,
              salaryRangeMin: null,
              salaryRangeMax: null,
              salaryRangeType: null
            }))
          }
        }}
        sx={{ width: 200 }}
      >
        <MenuItem value={'all'}>All salaries</MenuItem>
        <ListSubheader sx={{borderBottom: '1px solid #37c9ef', mt: 1}}>
          <Typography variant={'body2'} sx={{color: '#37c9ef', fontWeight: 600}}>HOURLY</Typography>
        </ListSubheader>
        <MenuItem value={'0_24_HOURLY'}>$25 -</MenuItem>
        <MenuItem value={'25_49_HOURLY'}>$25 - $50</MenuItem>
        <MenuItem value={'50_74_HOURLY'}>$50 - $75</MenuItem>
        <MenuItem value={'75_99_HOURLY'}>$75 - $100</MenuItem>
        <MenuItem value={'100_10000_HOURLY'}>$100 +</MenuItem>
        <ListSubheader sx={{borderBottom: '1px solid #37c9ef', mt: 1}}>
          <Typography variant={'body2'} sx={{color: '#37c9ef', fontWeight: 600}}>MONTHLY</Typography>
        </ListSubheader>
        <MenuItem value={'0_3999_MONTHLY'}>$4,000 -</MenuItem>
        <MenuItem value={'4000_7999_MONTHLY'}>$4,000 - $8,000</MenuItem>
        <MenuItem value={'8000_11999_MONTHLY'}>$8,000 - $12,000</MenuItem>
        <MenuItem value={'12000_16000_MONTHLY'}>$12,000 - $16,000</MenuItem>
        <MenuItem value={'16000_1000000_MONTHLY'}>$16,000 +</MenuItem>
      </Select>
    </Box>
  </Box>
}

export default ContractorPositionFilter;
