import {Box, Typography} from "@mui/material";
import React from "react";
import {GuestPerson} from "../../../api/sdl";

interface Props {
  index: number,
  guest: GuestPerson,
  slots?: {
    button?: JSX.Element,
  }
}

export function GuestCard({slots, guest, index}: Props) {
  return <Box sx={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 1,
    gap: 2,
  }} data-test={'guest-card'}>
    <div>
      <Typography sx={{fontSize: 12, fontWeight: 500, lineHeight: '15px'}}>{guest.name}</Typography>
      <Typography sx={{fontWeight: 500, fontStyle:'italic', fontSize: 10, lineHeight: '13px' }} color={'textSecondary'}>{guest.email}</Typography>
    </div>

    {slots?.button}
  </Box>
}