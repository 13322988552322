import {CreateContractPayloadInput, useCreateContractMutation} from "../../../../../api/sdl";
import {useSnackbar} from "notistack";

export default function useCreateContract() {
  const { enqueueSnackbar } = useSnackbar();

  const [create, {loading}] = useCreateContractMutation({
    onCompleted: data => enqueueSnackbar('Contract was created successfully', {variant: 'success'})
  });

  const createContract = (payload: CreateContractPayloadInput) => (
    create({variables: {payload}})
  )

  return {createContract, loading}
}
