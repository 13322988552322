import * as React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {ContractorAttribute} from "../../../../../api/sdl";
import {
  Box, Button,
  FormControl,
  FormHelperText,
  FormLabel, InputAdornment,
  TextField
} from "@mui/material";
import {
  SalaryRangeTypeSelect
} from "../../../../../shared";
import {LoadingButton} from "@mui/lab";
import {useContext} from "react";
import { FormValues } from "../lib/types";
import {
  ContractorAttributesContext,
  RolesAutocomplete,
  SpecialitiesAutocomplete,
  withContractorAttributes
} from "../../../../../entity";

const schemaFields: any = {
  roles: yup
    .array()
    .max(5, 'You must choose no more than five'),
  specialities: yup
    .array()
    .min(1, 'You must choose at least one')
    .max(5, 'You must choose no more than five'),
  salaryRangeMax: yup
    .number(),
  salaryRangeMin: yup
    .number(),
  salaryRangeType: yup
    .string()
    .test(
          'allOrNothing',
          'Fill all salary fields, or leave them empty',
          function(item){

            // Allow all fields to be empty
            if(!this.parent.salaryRangeMax && !this.parent.salaryRangeMin && !this.parent.salaryRangeType) {
              return true;
            }
            // Or all fields must be filled
            else {
              return !!this.parent.salaryRangeMax && !!this.parent.salaryRangeMin && !!this.parent.salaryRangeType;
            }
          }
    )
    .test(
        'negativeDifference',
        'Minimal value should not be greater than minimum value',
        function(item){
          // Allow fields to be optional
          if(!this.parent.salaryRangeMax && !this.parent.salaryRangeMin) {
            return true
          }
          return (this.parent.salaryRangeMax >= this.parent.salaryRangeMin)
        }
    )
}

interface Props {
  onSubmit: (values: FormValues) => void,
  onBackClick: () => void,
  roles: ContractorAttribute[],
  specialities: ContractorAttribute[],
  loading: boolean,
  initialValues: FormValues,
  cvUrl?: string,
}

export function Form(props: Props) {
  const {
    roles,
    specialities,
    loading,
    onSubmit,
    onBackClick,
    initialValues,
  } = props;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      formik.isValid && onSubmit(values)
    },
    validateOnChange: true
  });


  return <Box component={'form'} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>

      <RolesAutocomplete onChange={v => formik.setFieldValue('roles', v)}
                         value={formik.values.roles}
                         error={formik.touched.roles && formik.errors.roles}
                         multiple
                         options={roles}/>

      <SpecialitiesAutocomplete onChange={v => formik.setFieldValue('specialities', v)}
                         value={formik.values.specialities}
                         error={formik.touched.specialities && formik.errors.specialities}
                         options={specialities}/>

      <div>
        <FormControl sx={{width: 1}}>
          <FormLabel>Preferred salary range (optional):</FormLabel>

          <SalaryRangeTypeSelect value={formik.values.salaryRangeType}
                                 onChange={value => {
                                   formik.setFieldValue('salaryRangeType', value)
                                 }}
                                 error=''
          />
          <Box sx={{display: 'flex', flexDirection: "row", gap: 1 }}>
            <Box sx={{py: 1, width: 1}}>
              <TextField placeholder="From"
                         name="salaryRangeMin"
                         InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                         }}
                         type={'number'}
                         value={formik.values.salaryRangeMin}
                         onChange={formik.handleChange}
                         error={formik.touched.salaryRangeMin && Boolean(formik.errors.salaryRangeMin)}
              />
              {(formik.touched.salaryRangeMin && formik.errors.salaryRangeMin) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.salaryRangeMin}</FormHelperText>}
            </Box>
            <Box sx={{width: '20px', mt: '10px'}}> - </Box>
            <Box sx={{py: 1, width: '100%'}}>

              <TextField placeholder="To"
                         name="salaryRangeMax"
                         InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                         }}
                         type={'number'}
                         value={formik.values.salaryRangeMax}
                         onChange={formik.handleChange}
                         error={formik.touched.salaryRangeMax && Boolean(formik.errors.salaryRangeMax)}
              />
              {(formik.touched.salaryRangeMax && formik.errors.salaryRangeMax) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.salaryRangeMax}</FormHelperText>}
            </Box>
          </Box>
          <Box>
            {(formik.touched.salaryRangeType && formik.errors.salaryRangeType) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.salaryRangeType}</FormHelperText>}
          </Box>
        </FormControl>
      </div>

      <Box sx={{mt: 2, display: 'flex', gap: 3, width: 1, flexDirection: 'row'}}>
        <Box>
          <Button variant={'outlined'} color={'secondary'} onClick={onBackClick}>Cancel</Button>
        </Box>
        <Box sx={{textAlign: 'right', width: 1}}>
          <LoadingButton loading={loading} variant={'contained'} type="submit">Save</LoadingButton>
        </Box>
      </Box>
    </Box>
  </Box>
}

export default withContractorAttributes(function(props: Omit<Props, 'roles' | 'specialities'>) {
  const attributes = useContext(ContractorAttributesContext);
  return <Form {...attributes} {...props} />
})
