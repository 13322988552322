import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import {GuestCard} from "../../../feature/calendar/ui/GuestCard";
import React, {useState} from "react";
import {GuestPerson, MeetingDuration} from "../../../api/sdl";
import {DateTime} from "luxon";
import {X} from "phosphor-react";
import {durationToMinutes} from "../lib/utils";
import {ChangeMeetingDuration} from "./ChangeMeetingDuration";
import {MeetingListItem} from "../../../domain/matching_process/lib/types";

interface Props  {

  meeting: MeetingListItem,
  onGuestRemoveClick?: (guest: GuestPerson) => void,
  showGuests?: boolean,
  onDurationChange?: (duration: MeetingDuration) => void,
  slots?: {
    button?: JSX.Element,
    icon?: JSX.Element,
  }
}

export function MeetingCard({meeting, slots, onGuestRemoveClick, showGuests = false, onDurationChange}: Props) {
  const meetingDate = meeting.meetingScheduledOn;
  const [showGuestList, setShowGuestList] = useState(showGuests)

  return <Box sx={{display: 'flex', flexDirection: 'column'}}>
    <Box sx={{display: 'flex', gap: 1}}>
      {slots?.icon}

      <Stack spacing={1} sx={{width: 1}}>
        <Typography sx={{fontWeight: '500', lineHeight:'18px'}} data-test={"meeting-time"}>
          {meetingDate.toFormat('LLL d, h:mm a')} - {meetingDate.plus({minutes: durationToMinutes(meeting.duration)}).toFormat('h:mm a')}
        </Typography>
        <Typography sx={{color: '#949494', fontSize: '12px',}}>
          ({DateTime.now().zoneName})
        </Typography>

        {!!onDurationChange &&
            <ChangeMeetingDuration duration={meeting.duration} onChange={ onDurationChange } />
        }

        {meeting.guests && meeting.guests.length > 0 && <>
          <Button sx={{fontSize: 12, alignSelf: 'flex-start', p: 0, fontWeight: 500}}
                  onClick={() => setShowGuestList(prev => !prev)}>
            {showGuestList ? 'hide': 'show'} all guests
          </Button>
          {showGuestList && meeting.guests.map((guest, i) => (
            <GuestCard key={guest.email}
                       index={i + 1}
                       guest={guest}
                       slots={{button: onGuestRemoveClick ? (
                           <IconButton data-test='remove-guest'
                                       onClick={() => onGuestRemoveClick(guest)}>
                             <X size={16} color="#000" weight="regular" />
                           </IconButton>
                         ) : undefined}}/>
          ))}
        </>}
      </Stack>
    </Box>
    {slots?.button}
  </Box>
}
