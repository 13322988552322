import React, {useContext} from "react";
import {KeycloakContext} from "../../keycloak";
import {Navigate, Outlet} from "react-router-dom";

export function PublicRoute() {
  const {initialized, keycloak, authenticated} = useContext(KeycloakContext);

  if(initialized) {
    if(!authenticated) {
      return <Outlet />
    } else {
      return <Navigate to={"/start"} />
    }
  }

  return null
}