import {CompanyNameListQuery, useCompanyNameListQuery} from "../../../../../api/sdl";
import {useMemo} from "react";

export default function useCompanies(): ArrayElement<CompanyNameListQuery['companyList']['data']>['companyProfile'][] {
  const companyData = useCompanyNameListQuery();

  const companies = useMemo(() => {
    return companyData.data?.companyList.data.map(c => c.companyProfile) || [];
  }, [companyData.data]);

  return companies;
}
