import React, {useEffect} from "react";
import {useUploadCvMutation} from "../../api/sdl";
import {uploadClient} from "../../UploadApolloClient";
import {LoadingButton} from "@mui/lab";
import {Box, FormControl, FormHelperText} from "@mui/material";
import {useSnackbar} from "notistack";
import {ApolloError} from "@apollo/client";

interface Props {
  contractorId?: string,
  onUpload?: (url: string) => void
}

export function UploadResumeButton({onUpload, contractorId}: Props) {
  const [uploadCV, {loading, data, error}] = useUploadCvMutation({client: uploadClient});
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage(undefined);
      uploadCV({variables: {contractorProfileId: contractorId, cvFile: (e?.target?.files as any)[0]},
    }).catch((e: ApolloError) => {
      if(e.graphQLErrors.map(it => it.extensions).map(it => it?.error_code).includes("INVALID_RESUME_FILE_TYPE")) {
        setErrorMessage("This file type is not supported. Please upload a PDF file.");
      } else {
        setErrorMessage("Upload failed, please try again later.");
      }
    })
  }

  useEffect(() => {
    if(data?.uploadCV) {
      enqueueSnackbar("Resume successfully uploaded.", {variant: "success"});
      onUpload && onUpload(data?.uploadCV as string);
    }
  }, [data]);

  return <>
    <FormControl sx={{width: 1, height: 1}}>
      <input onChange={onChange}
             style={{display: 'none'}}
             id={'upload-cv'}
             type="file" />
      <LoadingButton component={'label'}
                     loading={loading}
                     htmlFor="upload-cv"
                     color="primary"
                     data-test="upload-cv"
                     variant="outlined">
        Upload Resume
      </LoadingButton>
      {!!errorMessage && <FormHelperText sx={{color: '#D3232F'}}>{errorMessage}</FormHelperText>}

    </FormControl>
  </>
}
