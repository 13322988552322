import {
  AttributeCategory,
} from "../../../../api/sdl";
import {Editable} from "../../../../shared";
import React from "react";
import {getProfileAttributes} from "./lib/getProfileAttributes";
import {FormValues} from "./lib/types";
import {BaseCurrentExperience} from "./ui/BaseCurrentExperience";
import {ContractorProfile} from "../../../../entity/contractor";

interface Props {
  profile?: ContractorProfile,
  onFormSubmit: (values: FormValues) => Promise<any>,
  loading?: boolean,
}

export default function CurrentExperience(props: Props & Editable) {
  const {profile, onFormSubmit, loading, ...rest} = props;

  return <BaseCurrentExperience roles={getProfileAttributes(AttributeCategory.RoleCategory, profile)}
                            specialities={getProfileAttributes(AttributeCategory.CallPointSpeciality, profile)}
                            salaryRangeMax={profile?.salaryRangeMax || undefined}
                            salaryRangeMin={profile?.salaryRangeMin || undefined}
                            loading={!!loading}
                            cvUrl={profile?.cvUrl || undefined}
                            salaryRangeType={profile?.salaryRangeType || undefined}
                            onSubmit={onFormSubmit}
                            {...rest} />
}
