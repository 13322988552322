import {CalendarSlot, CalendarSlotStatus} from "../../../api/sdl";
import {GridCoordinate} from "../ui/CalendarRow";

export interface MeetingSlotCoords extends GridCoordinate {
  slot: CalendarCell
}

export enum CalendarInternalStatus {
  SELECTED
}

export type CalendarCellType = CalendarSlotStatus | CalendarInternalStatus

export interface CalendarCell extends Omit<CalendarSlot, 'status'> {
  status: CalendarCellType
}

// export type CalendarSlot = {
//   __typename?: 'CalendarSlot';
//   date: Scalars['Instant']['output'];
//   properties: Array<NotificationProperty>;
//   status: CalendarSlotStatus;
//   title?: Maybe<Scalars['String']['output']>;
// };