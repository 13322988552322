import {Box, Card, MenuItem, Select, Typography} from "@mui/material";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import React from "react";
import {
  useAccountManagerListQuery, useAssignAccountManagerMutation,
  useCompanyByIdQuery
} from "../../../api/sdl";
import {useConfirm} from "../../../shared/ConfirmDialogContext";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {useNavigate, useParams} from "react-router-dom";

const Manager = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [manager, setManager] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const companyData = useCompanyByIdQuery({variables: {id}})
  const {data} = useAccountManagerListQuery();
  const [saveAccountManager, {loading}] = useAssignAccountManagerMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Candidate was added successfully', {variant: 'success'});
      navigate('../company/' + company?.companyProfile?.id);
    },
  })

  const company = React.useMemo(() => {
    return companyData.data?.companyById
  }, [companyData.data?.companyById])

  React.useEffect(() => {
    setManager(company?.accountManager?.id || '')
  }, [company])

  const list = React.useMemo(() => {
    return data?.accountManagerList
  }, [data?.accountManagerList])

  const onSaveClick = () => confirm({
    content: 'Are sure you want to apply this manager?',
    okButtonTitle: 'Yes - I want to apply this manager',
    cancelButtonTitle: 'No - return'
  }).then(val => val && saveAccountManager({variables: {companyId: id, managerUserId: manager}}));


  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>Edit manager</Typography>
    </Box>
    <Box ml={2} mt={1}>
      <Breadcrumbs crumbs={[
        {title: 'All companies', link: '../companies'},
        {title: company?.companyProfile?.name + ' positions', link: '../company/' + company?.companyProfile?.id},
        {title: `Manager`}]} />
    </Box>
    <Card sx={{mt: 1, p: 2}}>
      <Box sx={{display: 'flex', gap: 3}}>
        <Select value={manager}
                onChange={(e) => setManager(e.target.value || '')}>
          {list?.map(row => <MenuItem key={row.id} value={row.id}>{[row.firstName, row.lastName].join(' ')}</MenuItem>)}
        </Select>
        <LoadingButton onClick={onSaveClick} variant={'contained'} loading={loading}>Save</LoadingButton>
      </Box>
    </Card>
  </div>
}

export default Manager;
