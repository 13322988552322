import {CandidateMatchApiData} from "../lib/types";
import {Box, Button, Card, IconButton, Link, Stack, Typography} from "@mui/material";
import {Copy, Download} from "phosphor-react";
import React, {useState} from "react";
import {AttributesChips, toCurrency} from "../../../shared";
import {DateTime} from "luxon";
import {ReadableCandidateMatchStatus} from "./ReadableCandidateMatchStatus";
import config from "../../../config";
import {CandidateMeetingsDrawer} from "../../candidate-meetings-drawer";
import {CandidateLocation} from "./CandidateLocation";
import {PositionMatchStatus} from "../../../api/sdl";

interface Props {
  candidateMatch: CandidateMatchApiData,
  onRemoveClick: () => void,
  onRemoveWithNotificationClick: () => void,
  onConfirm: () => void,
  onProfileReview: () => void,
}

export function CandidateMatchCard({candidateMatch, onRemoveClick, onRemoveWithNotificationClick, onConfirm, onProfileReview}: Props) {
  const [showMeetingsDrawer, setShowMeetingsDrawer] = useState(false);

  function handleCopyClick() {
    if(!!candidateMatch.email) {
      window.navigator.clipboard.writeText(candidateMatch.email);
    }
  }

  function handleDownloadClick() {
    const absoluteLink = `${config.s3FilesUrl}/${candidateMatch.contractorProfile?.generatedResumeUrl}`;
    window.open(absoluteLink)
  }

  return <Card>
    <Box sx={{flexDirection: "row", display: "flex", padding: 3, width: 1, justifyContent: "space-between"}}>

      <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>

        <Box sx={{flexDirection: "row", display: "flex", alignItems: "center", gap: 3}}>
          <Typography variant={"body1"}>
            #{candidateMatch.candidateNumber}
          </Typography>
          <Typography variant={"body1"} sx={{cursor: "pointer", fontSize: "18px", color: "#1976d2", textDecoration: "underline", fontWeight: 500}}  onClick={onProfileReview} data-test={"match-name"}>
              {candidateMatch.firstName} {candidateMatch.lastName}
          </Typography>
          <Box sx={{flexDirection: "row", display: "flex", alignItems: "center"}}>
            <Typography variant={"body1"}>{candidateMatch.email}</Typography>
            <IconButton sx={{borderRadius: '50%'}} size={'small'} data-test='prevclick' onClick={handleCopyClick}>
              <Copy size={24} color={"#000000"} weight="regular"/>
            </IconButton>
          </Box>

          {candidateMatch.contractorProfile?.generatedResumeUrl &&
              <Button endIcon={<Download/>} onClick={handleDownloadClick}>Resume</Button>}

        </Box>

        <Box sx={{display: "flex", gap: 1, alignItems: 'center'}}>
          <CandidateLocation candidateMatch={candidateMatch} />
          <AttributesChips color={'info'} attributes={candidateMatch.contractorProfile.attributes?.map(a => a.attribute)} />
        </Box>

        <Box sx={{display: "flex", gap: 1, alignItems: 'center'}}>
          <Typography variant={"body1"}>Expected rate: {toCurrency(candidateMatch.expectedRate)} </Typography>
        </Box>

        <Box sx={{display: "flex", gap: 2}}>
          {!!candidateMatch.meetings &&
            <Link onClick={() => {setShowMeetingsDrawer(true)}} data-test={`meeting_modal_${candidateMatch.email}`} sx={{cursor: "pointer"}}>Scheduled meetings - {candidateMatch.meetings.length}</Link>
          }
          <Box>
            <Typography variant={"body1"} sx={{px:"8px", borderRadius: 2, background: candidateMatch.availabilitySlotsCount > 1 ? "#c0f3bf": "#f6c6c4"}}>Available time slots - {candidateMatch.availabilitySlotsCount}</Typography>
          </Box>
        </Box>

      </Box>

      {/*LEFT HAND SECTION */}
      <Box sx={{flexBasis: '250px', display: "flex", flexDirection: "column", gap: 2}}>
        <Box sx={{textAlign: "right"}}>
            <ReadableCandidateMatchStatus status={candidateMatch?.status} />
          <Typography variant={'body2'}>
            Matched on {DateTime.fromISO(candidateMatch.createdAt).toFormat('LLL d, yyyy')}
          </Typography>
        </Box>
        <Box sx={{justifyContent: "flex-end", display: "flex", gap: 1, width: "250px"}}>
          <Stack spacing={1}>
            {candidateMatch.status === PositionMatchStatus.ContractorAppliedToPosition &&
                <Button onClick={onConfirm} color={"primary"} variant={"contained"} data-test={`confirm_${candidateMatch.email}`}  size={"small"}>Confirm</Button>
            }
            <Button onClick={onRemoveClick} color={"error"} variant={"outlined"} data-test={`remove_${candidateMatch.email}`} size={"small"}>Remove silently</Button>
            <Button onClick={onRemoveWithNotificationClick} color={"error"} variant={"outlined"} data-test={`remove_with_notice_${candidateMatch.email}`} size={"small"}>Remove and notify</Button>
          </Stack>
        </Box>
      </Box>
    </Box>

    {showMeetingsDrawer && (
      <CandidateMeetingsDrawer positionMatchId={candidateMatch.id!!}
                               onClose={() => {
                                 setShowMeetingsDrawer(false)
                               }}/>
    )}
  </Card>
}