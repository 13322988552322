import {Box, Typography} from "@mui/material";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ProfileContext} from "../../shared/ProfileContext";
import {ContractorStatus} from "../../api/sdl";

const Stepper = () => {
  const navigate = useNavigate();
  const [profile] = React.useContext(ProfileContext);
  const location = useLocation()


  const steps = React.useMemo(() => {
    return [{
      label: 'Personal background',
      path: '/personal-info',
      active: true,
      disabled: profile?.contractorProfile?.status === ContractorStatus.OnboardingCompleted,
    }, {
      label: 'Confirmation and next steps',
      path: '/onboarding-completed',
      active: true,
      disabled: !profile?.contractorProfile?.cities.length
    }]
  }, [profile?.contractorProfile?.cvUrl, profile?.contractorProfile?.cities]);

  const Steps = React.useMemo(() => {
    const activeIndex = steps.map(s => s.path).indexOf(location.pathname);
    return steps.map((step, i) => <Step active={activeIndex === i}
                                        key={i}
                                        disabled={!!step.disabled}
                                        onClick={() => navigate(step.path)}
                                        index={i + 1}
                                        label={step.label} />)
  }, [location.pathname, steps])

  return <Box sx={{display: 'flex', flexDirection: 'column', gap: 2,}}>
    {Steps}
  </Box>
}

export default Stepper;

const Step = (props: {active: boolean, label: string, index: number, disabled?: boolean, onClick: () => void}) => (
  <Box sx={{border: `2px solid ${props.active ? '#39C8EE' : '#FFFFFF'}`, cursor: props.disabled ? 'default' : 'pointer', opacity: props.disabled ? .5 : 1, borderRadius: '8px', p: 1}}
       onClick={() => !props.disabled && props.onClick()}
       data-test={`step ${props.index}`}
  >
    <Typography variant={'h3'} sx={{mb: '4px'}}>Step {props.index}</Typography>
    <Typography>{props.label}</Typography>
  </Box>
)
