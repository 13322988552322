import useCreateContract from "../hooks/useCreateContract";
import CreateContractForm from "../ui/CreateContractForm";
import {CreateContractPayloadInput} from "../../../../../api/sdl";
import useCompanies from "../hooks/useCompanies";
import {useState} from "react";
import useContractors from "../hooks/useContractors";

export default function CreateContractFormDataProvided(props: {onSubmit: (contractId: string) => void}) {
  const {createContract, loading} = useCreateContract();
  const companies = useCompanies();
  const [term, setTerm] = useState('')
  const {contractors, loading: contractorsLoading} = useContractors(term);

  const onSubmit = (data: CreateContractPayloadInput) => {
    createContract(data).then(res => props.onSubmit(res.data!.createContract.id))
  }

  return <CreateContractForm loading={loading}
                             onSubmit={onSubmit}
                             companies={companies}
                             contractorsLoading={contractorsLoading}
                             contractors={contractors.map(c => ({
                               id: c.contractorProfile?.contractorId,
                               label: c.profile ? `${c.profile.firstName} ${c.profile.lastName} (${c.profile.email})` : 'Without profile'
                             }))}
                             onContractorInputChange={setTerm}
  />;
}
