import * as React from "react";
import {JointProfileOutput, useGetMyProfileLazyQuery} from "../api/sdl";
import {useContext} from "react";
import {KeycloakContext} from "../keycloak";
import {EducationApiData} from "../feature";

export const ProfileContext = React.createContext<[JointProfileOutput | undefined, () => void, boolean, boolean, EducationApiData[] | undefined]>([undefined, () => {}, false, false, []]);

/**
 * MY PROFILE MUST BE SPLIT TO SMALL PIECE AND CONTRACTOR PROFILE.
 */
export const ProfileContextProvider = (props: any) => {
  const [profile, setProfile] = React.useState<JointProfileOutput>();
  const [educations, setEducations] = React.useState<EducationApiData[]>();
  const {keycloak, authenticated} = useContext(KeycloakContext);
  const [getProfile, {loading, called}] = useGetMyProfileLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    refresh()
  }, [authenticated])

  const refresh = React.useCallback(() => {
    if (authenticated) {
      return getProfile().then(res => {
        setProfile(res.data?.myProfile as JointProfileOutput)
        setEducations(res.data?.educations as EducationApiData[])
      })
    }
  }, [authenticated])

  if(authenticated !== true || !profile) {
    return null
  }

  return (
    <ProfileContext.Provider value={[profile, refresh, loading, called, educations]}>
      {props.children}
    </ProfileContext.Provider>
  );
};

export const withProfileContextProvider = (WrappedComponent: any) => (props: any) => {
  return <ProfileContextProvider>
    <WrappedComponent {...props}/>
  </ProfileContextProvider>
}
