import React from 'react';
import {Box, Typography} from "@mui/material";
import {Award, useDeleteAwardMutation} from "../../../api/sdl";
import {CrossIconButton} from "../../cross-icon-button";

interface Props {
    awards?: Award[];
    onUpdate: () => void;
}

export const AwardListEditing = ({awards, onUpdate}: Props) => {

    const [deleteRequest] = useDeleteAwardMutation()
    const handleRemove = (awardId: string) => {
        deleteRequest({variables: {
            awardId,
            contractorProfileId: null,
        }}).then(onUpdate)
    }

    return <>
        <Box sx={{gap: 2, display: 'flex', flexDirection: 'column'}}>
            {awards && awards.map((a) =>
            <Box key={a.id} sx={{background: '#fff', border: '1px solid #D6DADC', borderRadius: '10px', p: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center'}}>
                <Typography>{a.title}</Typography>
                <CrossIconButton onClose={() => {handleRemove(a.id)}} />
            </Box>
            )}
        </Box>
    </>
}