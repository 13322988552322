import {Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {ContractorAttributeAutocompleteProps} from "../lib/types";


export function EmploymentTypesSelector(props: ContractorAttributeAutocompleteProps<string[], string>) {
    const {options, value, onChange, error} = props;

    return <>
        <FormControl>
            <FormLabel>
                Employment type (for this job)
            </FormLabel>
            <Select
                value={props.value[0]}
                data-test="work-type"
                onChange={(e) => {
                    props.onChange(e.target.value)
                }}
            >
                {options?.map(o => <MenuItem key={o.id} value={o.id}>{o.attribute}</MenuItem>)}
            </Select>
            {(!!error) && <FormHelperText sx={{color: '#D3232F'}}>{error}</FormHelperText>}
        </FormControl>
    </>
}

export default {EmploymentTypesSelector}