import React from "react";
import {usePositionByIdLazyQuery} from "../../api/sdl";

export function usePositionByIdApi(id?: string) {
  const [getPosition, {data, loading, called, refetch}] = usePositionByIdLazyQuery();

  React.useEffect(() => {
    if (id) {
      getPosition({variables: {id}})
    }
  }, [id])

  const position = React.useMemo(() => {
    return data?.positionById
  }, [data])

  return {position, loading, called, refetch};
}
