import {Box, Button, Typography} from "@mui/material";
import React from "react";
import InfoCard from "../../../../../shared/components/InfoCard";

interface Props {
  onClick: () => void
}
export function MobileForwardToCalendar(props: Props) {
  return <>
    <InfoCard
      hideIcon
      title={<Typography sx={{fontSize: 16, whiteSpace: 'pre-line', color: '#000'}}>
        Click Apply to interview for this position, set your calendar, and add your compensation requirement.
      </Typography>}
      content={<>
        <Box sx={{display: "flex", justifyContent: "flex-end", width: 1}}>
          <Button onClick={props.onClick} variant={"contained"} color={"primary"}>Apply</Button>
        </Box>
      </>}
    />
  </>;
}