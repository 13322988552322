import {useCityByStateLazyQuery, useStateListQuery} from "../../../api/sdl";
import React, {useEffect} from "react";
import {CityData, CityFormValue} from "../lib/types";
import {Box, MenuItem, Select, Typography} from "@mui/material";

interface CitySelectorItemProps {
  onChange: (value: CityFormValue) => void,
  value?: CityFormValue,
}

// a20240425-1@yopmail.com

const CitySelectorItem = (props: CitySelectorItemProps) => {

  const {data: stateData} = useStateListQuery();
  const [stateValue, setStateValue] = React.useState(props?.value?.stateShort || '');
  const [cityValue, setCityValue] = React.useState(props?.value?.id || '');

  const [cities, setCities] = React.useState<CityData[]>([])
  const [cityRequest] = useCityByStateLazyQuery();

  useEffect(() => {
    if(stateValue) {
      cityRequest({variables: {stateShortName: stateValue}})
        .then((v)=> setCities(v.data?.cityByState || []))
    }

  }, [stateValue, cityRequest]);

  useEffect(() => {
    props.onChange({
      id: cityValue,
      stateShort: stateValue,
    } as CityFormValue)
  },[cityValue]);

  return <>
    <Box sx={{display: 'flex', flexDirection: "row", flexWrap: 'wrap', gap: 2, minHeight: '48px', my: 1}}>
      <Box sx={{minWidth: 200}}>
        <Typography>State:</Typography>
        <Select data-test="state" value={stateValue} onChange={(e) => {setStateValue(e.target.value)}} sx={{width: "100%"}}>
          <MenuItem value={''}>Select state</MenuItem>
          {!!stateData && stateData.stateList.map(v => <MenuItem key={'st' + v.shortName } value={v.shortName}>{v.name}</MenuItem>)}
        </Select>
      </Box>
      <Box sx={{minWidth: 200}}>
        <Typography>City / Town:</Typography>
        <Select data-test="city" value={cityValue} onChange={(e) => {setCityValue(e.target.value)}} sx={{width: "100%"}}>
          {!!cities && cities.map(v => <MenuItem key={'st' + v.id } value={v.id}>{v.city}</MenuItem>)}
        </Select>
      </Box>
    </Box>
  </>
}

export {CitySelectorItem}