import {Box, Card, Typography, Button, IconButton} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import usePageSize from "./hooks/usePageSize";
import usePage from "./hooks/usePage";
import {PlusCircle, X} from 'phosphor-react';
import {
  AttributeCategory,
  ContractorAttribute,
  useContractorAttributePageQuery,
  useDeleteAttributeMutation
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../../shared/components/DataGrid";
import * as _ from "lodash";
import {useConfirm} from "../../../shared/ConfirmDialogContext";
import {AttributeCategorySelector} from "../../../feature/attribute-category-selector";


const columns: (onDelete: (id: string) => void) => DataGrigColumn<ContractorAttribute>[]  = (onDelete: (id: string) => void) => [{
  title: 'Category',
  value: o => _.startCase(_.lowerCase(o.category))
}, {
  title: 'Attribute',
  value: o => o.attribute,
}, {
  title: '',
  value: o => <IconButton size={'small'} color={'secondary'} onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(o.id)
  }}>
    <X size={24} color="#A10E25" weight="regular" />
  </IconButton>,
}]

export default function AttributeList() {
  const [rowsPerPage, setRowsPerPage] = usePageSize();
  const [page, setPage] = usePage();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const {data, loading, refetch, error} = useContractorAttributePageQuery({
    fetchPolicy: 'no-cache',
    variables: {
      category: selectedCategory !== "" ? selectedCategory as AttributeCategory : undefined,
      page: {
        size: rowsPerPage,
        page
      }
    },
  });
  const [deleteAttribute, {loading: deleteLoading}] = useDeleteAttributeMutation();
  const confirm = useConfirm();

  const onDeleteClick = (id: string) => confirm({
    content: 'Are you sure you want to delete this attribute??',
    okButtonTitle: 'Yes - I want to reject this position',
    cancelButtonTitle: 'No - return to position',
    width: 500
  }).then(val => val && deleteAttribute({variables: {id}}).then(() => refetch()));

  const rows = React.useMemo(() => {
    return data?.contractorAttributePage.data || [];
  }, [data?.contractorAttributePage.data])

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 4}}>
      <Box sx={{flexDirection: "row", display: 'flex'}}>
        <Typography variant={'h3'} sx={{ml: 2, mr: 2}}>Attributes</Typography>
        <AttributeCategorySelector
          value={selectedCategory}
          defaultValue={"All Categories"}
          onChange={ (value) => {
            setSelectedCategory(value);
          }}
        />
      </Box>

      <Button variant={'contained'} color={'primary'}
              onClick={() => navigate('../attribute')}
              startIcon={<PlusCircle size={24} color="#ffffff" weight="regular" />}>Add</Button>
    </Box>
    <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns(onDeleteClick)}
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                error={error?.message}
                onRowClick={row => navigate('../attribute/' + row.id)}
                onNextClick={() => setPage(p => p + 1)}
                onPrevClick={() => setPage(p => p - 1)}
                loading={loading || deleteLoading}
                isLastPage={data?.contractorAttributePage?.isLast}
                />
    </Card>
  </div>
}
