import React, {useContext} from 'react';
import {KeycloakContext} from "../../keycloak";
import {DefaultRedirects} from "../../domain/routing/DefaultRedirects";

export function StartRedirect() {
  const {initialized, authenticated, keycloak} = useContext(KeycloakContext);

  React.useEffect(() => {
    if(initialized && authenticated === false ) {
      keycloak.login();
    } else {

      // if(keycloak.hasRealmRole("ROLE_ADMIN")) {
      //   navigate("/admin/notifications")
      // }
      //
      // if(keycloak.hasRealmRole("ROLE_COMPANY")) {
      //   navigate("/company/positions")
      // }
      //
      // if(keycloak.hasRealmRole("ROLE_CONTRACTOR")) {
      //   navigate("/contractor/notifications")
      // }
    }


  }, [initialized, authenticated]);


  if(authenticated) {
    return <DefaultRedirects keycloak={keycloak} />;

  }

  return null;

}