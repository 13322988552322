import {WorkContractOutput} from "../../../../../api/sdl";
import {Box, CircularProgress, Typography} from "@mui/material";
import * as React from "react";
import {PropsWithChildren, useMemo} from "react";
import {toCurrency} from "../../../../../shared/utils";
import {DateTime} from "luxon";
import {useHarvestClients} from "../../../../components/HarvestClientSelect";
import {useHarvestProjects} from "../../../../components/HarvestProjectSelect";

const ContractDetails = ({contract}: { contract?: WorkContractOutput }) => {
  const {clients, loading: clientsLoading} = useHarvestClients();
  const {projects, loading : projectsLoading} = useHarvestProjects(contract?.harvestClientId)

  const clientName = useMemo(() => {
    return clients.find(c => c.id === contract?.harvestClientId)?.name
  }, [clients]);

  const projectName = useMemo(() => {
    return projects.find(c => c.id === contract?.harvestProjectId)?.name
  }, [projects]);

  if (!contract) {
    return null;
  }

  return <>
    <Box data-test='contract-cards' sx={{display: 'flex', gap: 5}}>
      <Card imagePath={`/img/teenyicons/user-circle.svg`}>
        <Typography sx={{fontWeight: 400}}>Company: {contract.companyName}</Typography>
        <Typography sx={{fontWeight: 400}}>Harvest Client: {clientName} {clientsLoading && <CircularProgress size={10}/>}</Typography>
        <Typography sx={{fontWeight: 400}}>Harvest Client: {contract.harvestClientId}</Typography>

      </Card>
      <Card imagePath={`/img/teenyicons/user-circle.svg`}>
        <Typography sx={{fontWeight: 400}}>Contractor: {contract.contractorName}</Typography>
        <Typography sx={{fontWeight: 400}}>Email: {contract.contractorEmail}</Typography>
        <Typography sx={{fontWeight: 400}}>Harvest Project: {projectName} {projectsLoading && <CircularProgress size={10}/>}</Typography>
        <Typography sx={{fontWeight: 400}}>Harvest Project ID: {contract.harvestProjectId}</Typography>
      </Card>
    </Box>
    <Box>

      <Card imagePath={`/img/teenyicons/dollar.svg`}>
        <Typography sx={{fontWeight: 400}}>Position: {contract.positionTitle}</Typography>
        <Typography sx={{fontWeight: 400}}>Contractor Rate: {toCurrency(contract.contractorExpectedRate)}</Typography>
        <Typography sx={{fontWeight: 400}}>Anticipated Start Date: {contract.anticipatedStartDate && DateTime.fromISO(contract.anticipatedStartDate).toLocaleString(DateTime.DATE_MED)} </Typography>
      </Card>
    </Box>
  </>
}

export default ContractDetails;

const Card = (props: PropsWithChildren<IconProps>) => (
  <Box sx={{
    display: 'flex',
    width: 1,
    alignItems: 'flex-start',
    gap: 3,
    background: 'white',
    border: '1px solid #E4E4E4',
    borderRadius: '10px',
    p: '16px 18px',
    mb: '20px'
  }}>
    <Icon imagePath={props.imagePath}/>
    <Box flex={1}>
      <Box sx={{display: 'flex', alignItems: 'flex-start', gap: '10px', mb: '6px', flexDirection: 'column'}}>
        {props.children}
      </Box>
    </Box>
  </Box>
)

const Icon = (props: IconProps) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    background: '#E4E4E7',
    borderRadius: '24px',
    width: 48,
    height: 48
  }}>
    <Box component={'img'} src={props.imagePath} width={22} height={22}/>
  </Box>
)

interface IconProps {
  imagePath: string
}
