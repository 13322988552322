import {Box, Button, Card, Chip, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PositionOutput, PositionStatus, ReferenceType, usePositionsByCompanyIdQuery,} from "../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../shared/components/DataGrid";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import * as _ from 'lodash';
import {CompanyCard} from "./ui/CompanyCard";
import {useCompanyApi} from "../../entity/company";
import {NotesDrawer} from "../../domain/admin-interface/feature/notes-drawer/NotesDrawer";

const columns: DataGrigColumn<Partial<PositionOutput>>[]  = [{
  title: 'Title',
  value: o => o.title || ''
}, {
  title: 'Candidates',
  value: o => (o.numberOfCandidates || 0) + '',
}, {
  title: 'Status',
  value: o => <Chip size={'small'}
                    label={_.startCase(o.status?.toLowerCase())}
                    sx={{background: [PositionStatus.Archived, PositionStatus.Closed, PositionStatus.Deleted].includes(o.status as any) ? '#bf2121' : '#3075b8'}} />,
},
  {
    title: 'Commission',
    value: o => <>{o.platformCommission}%</>,
  }];

export default function PositionListByCompany() {
  const navigate = useNavigate();
  const {id} = useParams();
  const {company, userProfile} = useCompanyApi(id!!);

  const {loading, data, error} = usePositionsByCompanyIdQuery({
    fetchPolicy: 'no-cache',
    variables: {companyId: id, page: {page: 0, size: 1000}}
  });

  const rows = React.useMemo(() => {
    return data?.positionsByCompanyId?.data || [];
  }, [data?.positionsByCompanyId])

  return <Box>
    <Stack direction={"row"} spacing={2}>
      <Typography variant={'h3'} sx={{ml: 2}}>{company?.companyProfile?.name ? `${company?.companyProfile?.name} positions` : 'Loading...'} </Typography>
      {!!company && <NotesDrawer reference={company!.companyProfile.userId} referenceType={ReferenceType.Company}/>}
    </Stack>
    <Box sx={{mx: 2, my: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Breadcrumbs crumbs={[
        {title: 'All companies', link: '../companies'},
        {title: company?.companyProfile?.name ? `${company?.companyProfile?.name} positions` : '...'},
      ]} />
      <Button size={'small'} variant={'contained'} onClick={() => navigate(`../company/${id}/position`)}>Create new</Button>
    </Box>
    <Divider />
    {company && userProfile &&
        <CompanyCard company={company} userProfile={userProfile} />
    }
    <Divider />
    <Card sx={{mt: 1, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={1000}
                page={0}
                hidePaginator
                onRowClick={row => navigate(`../company/${company?.companyProfile?.id}/position/` + row.id)}
                error={error?.message}
                onNextClick={() => {}}
                onPrevClick={() => {}}
                loading={loading}
                isLastPage={true}
      />
    </Card>
  </Box>
}
