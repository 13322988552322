import {Box, CircularProgress, Button} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  useContractorProfileByIdQuery
} from "../../../api/sdl";
import {useSnackbar} from "notistack";
import ContractorApprovement from "../../components/ContractorApprovement";
import {ContractorProfile} from "../../../company/pages/Candidates/lib/types";
import {ContractorCard} from "../../../feature/contractor-card";
import {EducationApiData} from "../../../feature";
import {CompositeAnswerApiData} from "../../../feature/contractor-card/ui/combined-answers/lib/types";


export function ContractorAdminDetails() {
  const navigate = useNavigate();
  const {id} = useParams();
  const [profile, setProfile] = useState<ContractorProfile>()
  const [educations, setEducations] = useState<EducationApiData[]>()
  const [compositeAnswers, setCompositeAnswers] = useState<CompositeAnswerApiData[] | null>(null)
  const {loading,data: profileData, refetch} = useContractorProfileByIdQuery({
    variables: {id},
    // fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setProfile(profileData?.contractorProfileById)
    setEducations(profileData?.educations || [])
    setCompositeAnswers(profileData?.compositeAnswers || null)
  }, [profileData]);
  const {enqueueSnackbar} = useSnackbar();

  return <Box sx={{position: 'relative', minHeight: '50vh'}}>
    {loading && <Box sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
        <CircularProgress size="15"/>
    </Box>}
    {!!profile && <>
        <Button variant="text" sx={{mb: 3, textDecoration: 'underline'}} color="inherit"
                onClick={() => navigate('../contractors')}>Back</Button>
        <ContractorCard profile={profile.contractorProfile || undefined}
                        userProfile={profile.profile || undefined}
                        onUpdate={() => {
                          enqueueSnackbar('Profile was saved successfully', {variant: 'success'})
                          refetch()
                        }}
                        extraContent={profile.contractorProfile ?
                          <ContractorApprovement profile={profile.contractorProfile!!} onChange={() => {
                            enqueueSnackbar('The status has been changed', {variant: 'success'})
                            refetch()
                          }}/> : undefined}
                        editable
                        educations={educations}
                        compositeAnswers={compositeAnswers}
                        showSideStripe={true}
        />
    </>}
  </Box>
}
