import {IntegrationStatus, useAffindaStatusLazyQuery, useAffindaStatusQuery} from "../../../api/sdl";
import React, {useState} from "react";

export function useAffindaStatusApi(contractorId?: string, onCompleted?: () => void) {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = useState(false)

  const [getAffindaStatus, {stopPolling}] = useAffindaStatusLazyQuery({
    fetchPolicy: "no-cache",
    variables: {contractorProfileId: contractorId},
    pollInterval: 5000,
    onCompleted: data => {
      if(data.affindaStatus !== IntegrationStatus.Requested) {
        stopPolling();
        setLoading(false);
        if(data.affindaStatus === IntegrationStatus.Completed) {
          onCompleted && onCompleted();
        }
        if(data.affindaStatus === IntegrationStatus.Error) {
          setError(`Can't parse document. Please, try another resume file.`);
        }
      }
    }
  })

  useAffindaStatusQuery({
    fetchPolicy: "no-cache",
    variables: {contractorProfileId: contractorId},
    onCompleted: data => {
      switch (data.affindaStatus) {
        case IntegrationStatus.Requested:
          setLoading(true);
          getAffindaStatus();
          break;
        case IntegrationStatus.Error:
          setLoading(false);
          setError(`Can't parse document. Please, try another resume file.`);
          break;
      }
    }
  })

  return {error, loading, getAffindaStatus}
}
