import * as React from "react";
import {Button} from "@mui/material";

interface Props {
  onClick?: () => void,
}

export function EditButton(props: Props) {
  return <Button size={'small'}
                 variant={'outlined'}
                 onClick={props.onClick} {...props}>
    Edit
  </Button>
}
