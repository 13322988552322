import {Box, Stack} from "@mui/material";
import React from "react";
import {NavLink} from "react-router-dom";

export function NotFound() {
  return <>
    <Stack sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%", marginTop: "-200px"}} spacing={2}>

        <Box component={'img'} src={'/img/MDliaison_logo.png'} width={200} height={44} />
        <h1>The requested resource was not found</h1>
        <p>Oops! We couldn't find the resource you were looking for.</p>

      <Stack direction={"row"} spacing={5}>
        <NavLink to={"/start"}>Login</NavLink>
        <NavLink to={"/company-onboarding"}>Sign up as Company</NavLink>
        <NavLink to={"/contractor"}>Sign up as Contractor</NavLink>
      </Stack>
    </Stack>
  </>
}