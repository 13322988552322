import {Box, Typography} from "@mui/material";
import {WorkContractOutput, WorkContractStatus,} from "../../api/sdl";
import React from "react";
import {fullCommissionMultiplier, toCurrency} from "../utils";
import {DateTime} from "luxon";


/**
 * @TODO This component is not "Shared" the logic is different for each user type.
 */
const CompanyContract = ({item, onClick}: {item: WorkContractOutput, onClick?: () => void}) => {

    return <Box data-test='contract' sx={{
        display: 'flex',
        cursor: onClick ? 'pointer' : undefined,
        alignItems: 'center',
        gap: 3,
        background: 'white',
        border: '1px solid #E4E4E4',
        borderRadius: '10px',
        p: '16px 18px',
        mb: '20px'
    }}
    onClick={onClick}
    >
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, background: '#E4E4E7', borderRadius: '24px', width: 48, height: 48}}>
            <Box component={'img'} src={`/img/teenyicons/appointments.svg`} width={22} height={22} />
        </Box>
        <Box flex={1}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', mb: '6px'}}>
                <Typography  sx={{fontWeight: 400}}>{item.contractorName}</Typography>
                <Typography color={contractStatusColor(item.status)} sx={{fontWeight: 600, ml: 'auto'}}>{item.status}</Typography>
            </Box>
            <Box sx={{display: "flex", gap: 2}}>
                <Typography  sx={{fontWeight: 400}}>Rate: {toCurrency(item.contractorExpectedRate * fullCommissionMultiplier(item.platformCommission), 2, 2)}</Typography>
            </Box>
            <Box sx={{py: 1}}>
                <Typography sx={{fontWeight: 400}}><b>Start on:</b> {DateTime.fromISO(item.anticipatedStartDate).toLocaleString(DateTime.DATE_MED)}</Typography>
            </Box>
        </Box>
    </Box>
}

export default CompanyContract


const contractStatusColor = (status?: WorkContractStatus) => {
    switch (status) {
      case WorkContractStatus.Active: return '#1ec129';
      case WorkContractStatus.Closed: return '#d32f2f';
      default: return '#dfa21e'
    }
  }
