import {JointProfileOutput, Maybe, useContractorSearchTableQuery} from "../../../../../api/sdl";
import React, {useEffect, useMemo, useState} from "react";
import {useDebouncedCallback} from "../../../../../shared/hooks/useDebouncedCallback";
import {Autocomplete, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";

interface Props {
  error?: string,
  value: Maybe<string>,
  onInputChange: (value: string) => void,
  onChange: (value: Maybe<string>) => void,
  contractors: {id: string, label: string}[],
  loading: boolean,
}

const ContractorSelect = (props: Props) => {
  return <FormControl disabled={props.loading} error={!!props.error}>
    <FormLabel >
      Contractor
    </FormLabel>
    <Autocomplete
      placeholder="Select contractor"
      loading={props.loading}
      onInputChange={(e, value) => {
        if (!props.contractors.some(c => c.label === value)) {
          props.onInputChange(value)
        }
      }}
      filterOptions={(x) => x}
      options={props.contractors.map(c => c.id)}
      value={props.value}
      onChange={(event, newValue) => {
        props.onChange(newValue);
      }}
      getOptionLabel={id => props.contractors.find(c => c.id === id)?.label ?? ''}
      sx={{ width: '500px' }}
      renderInput={(params) => <TextField {...params} />}
    />
    {props.error && <FormHelperText sx={{color: '#D3232F'}}>{props.error}</FormHelperText>}
  </FormControl>
}

export default ContractorSelect;
