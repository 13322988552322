import {Box, Button, Card, Typography} from "@mui/material";
import React from "react";
import _ from "lodash";
import {BalanceReportApiData} from "../lib/types";
import {formatDate, formatDateTime} from "../../../shared";
import {BalanceReportStatus} from "../../../api/sdl";

interface Props {
  item: BalanceReportApiData,
  onClick: () => void,
}

// {report.id} - {report.status} - {report.createdAt}
// gridTemplateRows: "1fr min-content",
export function BalanceReportLine({item, onClick}: Props) {
  return <Card sx={{p: 3}}>
    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Box display="flex" gap={2} alignItems="center">
        <Typography sx={{color: "#267ECA", fontWeight: 500, fontSize: "20px"}}>
          {formatDate(item.startDate)} - {formatDate(item.endDate)}
        </Typography>
        <Typography variant={"body2"}>Requested on {formatDateTime(item.createdAt)}</Typography>
      </Box>

      <Box display={"flex"}>
        {item.status === BalanceReportStatus.Completed &&
            <Button variant={'contained'} onClick={onClick}>Download</Button>
        }
        {item.status === BalanceReportStatus.Requested &&
            <Typography variant={"body1"}>Pending</Typography>
        }
      </Box>
    </Box>
  </Card>
}