import React from 'react';
import {AwardListEditing} from "./AwardListEditing";
import {AwardListView} from "./AwardListView";
import {AwardData} from "../lib/types";
import {EmptyAwardList} from "./EmptyAwardList";

interface Props {
    awards?: AwardData[];
    editing: boolean;
    onUpdate: () => void;
}
export const AwardList = ({awards, editing, onUpdate}: Props) => {

    if(editing) {
        return <AwardListEditing awards={awards} onUpdate={onUpdate} />
    }

    if(!!awards && awards.length > 0) {
        return <AwardListView awards={awards} />
    }

    return <EmptyAwardList />
}