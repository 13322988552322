import * as yup from "yup";

export const schemaFields: any = {
  roles: yup
    .array()
    .required('Role is required'),
  locations: yup
    .array(),

  specialities: yup
    .array()
    .min(1, 'Please choose at least one'),
  expectedContractLengths: yup
    .array()
    .min(1, 'Please choose at least one')
  ,
  title: yup
    .string()
    .required('Title is required'),
  description: yup
    .string()
    .required('This is required')
  ,
  commissionBonus: yup
    .string(),
  salaryRangeMax: yup
    .number()
    .nullable()
    .test(
      'negativeDifference',
      'Must be higher than the first value',
      function(item){
        if(this.parent.salaryRangeMax === undefined) {
          return true;
        }

        return (this.parent.salaryRangeMax >= this.parent.salaryRangeMin)
      }
    )
    .test(
      'requiredIfExists',
      'Please specify a value or remove the range',
      function (item) {

        if(this.parent.salaryIsRange === true && !item) {
          return false;
        }
        return true;
      }
    )
  ,
  salaryRangeMin: yup
    .number()
    .min(0, 'Should not be negative')
    .required('This is required')
    .test(
      'negativeDifference',
      'Should not be greater than maximum value',
      function(item){
        return true;
        // return (this.parent.salaryRangeMax >= this.parent.salaryRangeMin)
      }
    ),
  hoursPerWeekMax: yup
    .number()
    .nullable()
    .min(0, 'Should not be negative')
    .test(
      'requiredIfExists',
      'Please specify a value or remove the range',
      function (item) {

        if(this.parent.hoursAreRange === true && !item) {
          return false;
        } 
        return true;
      }
    )
    .test(
      'negativeDifference',
      'Must be higher than the first value',
      function(item){
        if(!this.parent.hoursAreRange) {
          return true;
        }

        return (this.parent.hoursPerWeekMax >= this.parent.hoursPerWeekMin)
      }
    )
  ,
  hoursPerWeekMin: yup
    .number()
    .required('This is required')
    .min(0, 'Should not be negative'),
  salaryRangeType: yup
    .string()
    .required('Select a salary type'),
  preferableStartDate: yup
    .date()
    .required('This is required'),

  hoursAreRange: yup
    .boolean()
    .nullable(),

  salaryIsRange: yup
    .boolean()
    .nullable()
}