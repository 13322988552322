import {useAdminStripeAccountLazyQuery} from "../../../api/sdl";
import {enqueueSnackbar} from "notistack";
import React from "react";

export default function useStripeAccount(contractorId?: string) {
  const [getStripeAccount, {loading, data, refetch}] = useAdminStripeAccountLazyQuery({
    fetchPolicy: 'no-cache',
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
  });

  React.useEffect(() => {
    if (contractorId) {
      getStripeAccount({variables: {contractorId}})
    }
  }, [contractorId])

  const stripeAccount = React.useMemo(() => {
    return data?.adminStripeAccount
  }, [data])

  return {stripeAccount, loading, refetch}
}