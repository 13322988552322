import {keycloak} from "../keycloak";
import {client} from "../ApolloClient";
import { gql } from "@apollo/client";

const contractorMenu = async () => {
  const notifications = client.query({
    query: gql`{myNotificationCounter}`,
    fetchPolicy: 'no-cache'
  })
  return {
    top: [
      {
        title: 'Notifications',
        route: '/contractor/notifications',
        icon: 'bell',
        notifications: await notifications.then(r => r.data.myNotificationCounter),
      },
      {
        title: 'My profile',
        route: '/contractor/profile',
        icon: 'users',
      },
      {
        title: 'Find Work',
        route: '/contractor/positions',
        icon: 'briefcase',
      },
      {
        title: 'My calendar',
        route: '/contractor/calendar',
        icon: 'appointments',
      },
      {
        title: 'Manage Payments',
        route: '/contractor/settings',
        icon: 'credit-card',
      },
      {
        title: 'Payments',
        route: '/contractor/payouts',
        icon: 'briefcase',
      },
      {
        title: 'MDL Bridge',
        icon: 'users-three',
        onClick: () => {
          localStorage.clear();
          window.location.href = "https://mdliaison.circle.so/home"
        }
      }
    ],
    bottom: [
      {
        title: 'Logout',
        icon: 'user-circle',
        onClick: () => {
          localStorage.clear();
          keycloak.logout({redirectUri: document.location.origin})
        }
      }
    ]
  }
}

export default contractorMenu;