import {Button, Skeleton, Stack} from "@mui/material";
import {NoteItemData, NoteValue} from "../lib/types";
import {NoteItemView} from "./NoteItemView";
import {NotesForm} from "./NotesForm";
import {ReferenceType, useDeleteNoteMutation, useNotesListQuery, useSaveNoteMutation} from "../../../../../api/sdl";
import React, {useState} from "react";

interface Props {
  reference: string,
  referenceType: ReferenceType,
}
export function NotesEditor(props: Props) {

  const [value, setValue] = useState<NoteValue>()

  const {data: notes, refetch, loading} = useNotesListQuery({fetchPolicy: "no-cache", variables: {reference: props.reference}})
  const [deleteNote, {loading: deleteLoading}] = useDeleteNoteMutation();
  const [save, {loading: saveLoading}] = useSaveNoteMutation()


  const handleSubmit = async (value: NoteValue) => {
    const payload = {...value, referenceType: props.referenceType}

    delete payload.id
    await save({variables: {payload, id: value.id}})

    setValue(undefined)
    await refetch()
  }


  const handleAdd = () => {
    setValue({
      reference: props.reference,
      id: undefined,
      message: "",
    })
  }

  const handleDelete = async (id: string) => {
    await deleteNote({variables: {id}})
    await refetch()
  }

  const handleEdit = async(note: NoteItemData) => {
    setValue({
      reference: note.reference,
      message: note.message || "",
      id: note.id!,
    })
  }

  const editMode = !!value

  if(loading || saveLoading || deleteLoading) {
    return <Stack spacing={2} sx={{p: '16px 18px', borderRadius: '10px',}}>
      {/* For variant="text", adjust the height via font-size */}

      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
      <Skeleton variant="rounded" height={160} />
    </Stack>
  }

  return <>
    {!editMode &&
      <Stack justifyContent={"space-between"} direction={"row"}>
        <Button onClick={handleAdd} variant={"outlined"}>Add</Button>
      </Stack>
    }
  <Stack spacing={2} sx={{marginTop: 2}}>
    {!editMode && !!notes && notes.getNotes.map( (note: NoteItemData) =>
      <NoteItemView item={note} key={note.id} onDelete={() => handleDelete(note.id)} onEdit={() => handleEdit(note)}/>
    )}
  </Stack>

    {value &&
        <NotesForm initialValue={value} onSubmit={handleSubmit} onCancel={() => setValue(undefined)}/>
    }
  </>
}