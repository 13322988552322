import {createBrowserRouter, Navigate} from "react-router-dom";
import MeetingCardPage from "./shared/pages/MeetingCardPage";
import RegisterCompany from "./company/pages/RegisterCompany";
import {NotFound} from "./feature/not-found/NotFound";
import Positions from "./company/pages/Positions";
import React, {Suspense} from "react";
import {StartRedirect} from "./feature/start-redirect";
import {LayoutWithMenu} from "./layout";
import CompanyContracts from "./company/pages/CompanyContracts";
import {HirePage} from "./domain/hiring";
import CompanyMeetings from "./company/pages/company-meetings";
import Notifications from "./feature/notifications";
import ThankYou from "./company/pages/ThankYou";
import HiringComplete from "./company/pages/HiringComplete";
import {
  AdminPositionPage,
  CandidatesPage,
  ContractorPositionDetailsPage,
  ReschedulePage
} from "./domain/matching_process";
import {ErrorBoundary} from "./feature/error-boundary/ErrorBoundary";
import ContractorPositions from "./contractor/pages/ContractorPositions";
import ProfessionalProfile from "./contractor/pages/ProfessionalProfile";
import {ContractorPayouts} from "./contractor/pages/contractor-payouts/ContractorPayouts";
import Settings from "./company/pages/Settings";
import CalendarPage from "./contractor/pages/CalendarPage";
// import VideoPage from "./contractor/pages/VideoPage";
import {BalanceReportsPage} from "./feature/balance-reports";
import {GoogleWorkspaceCodeReader} from "./feature/google-workspace-code-reader";
import AdminContractList from "./admin/pages/contracts/AdminContractList";
import AdminContract from "./admin/pages/contracts/AdminContract";
import CompanyList from "./admin/pages/companies/CompanyList";
import InvoiceList from "./admin/pages/invoices/InvoiceList";
import PositionListByCompany from "./feature/position-list-by-company/PositionListByCompany";
import Manager from "./admin/pages/companies/Manager";
import {adminMenu} from "./admin/AdminApp";
import ServicePage from "./admin/pages/service/ServicePage";
import {PositionListActive} from "./feature/position-list-active/PositionListActive";
import {LastScheduledEmails} from "./feature/service";
import ExternalContractorList from "./admin/pages/external/ExternalContractorList";
import AttributeForm from "./admin/pages/attributes/AttributeForm";
import AttributeList from "./admin/pages/attributes/AttributeList";
import CreateContractor from "./admin/pages/contractors/CreateContractor";
import {ContractorsPage} from "./admin/pages/contractors/ContractorsPage";
import EmailForm from "./admin/pages/emails/EmailForm";
import EmailList from "./admin/pages/emails/EmailList";
import RegisterContractor from "./contractor/pages/onboarding/RegisterContractor";
import {AppRoot} from "./layout/AppRoot";
import {PrivateRouteUpdated} from "./domain/routing/PrivateRouteUpdated";
import {PublicRoute} from "./domain/routing/PublicRoute";
import PersonalInfo from "./contractor/pages/onboarding/PersonalInfo";
import {ContractorRoute} from "./domain/routing/ContractorRoute";
import OnboardingCompleted from "./contractor/pages/onboarding/OnboardingCompleted";
import {ContractorAdminDetails} from "./admin/pages/contractors/ContractorAdminDetails";
import {CandidateCarouselLayout} from "./feature/candidate-carousel-layout/CandidateCarouselLayout";
import {CandidateCardPreview} from "./feature/candidate-card-preview/CandidateCardPreview";
import {PositionPage} from "./company/pages/position-page/PositionPage";
import {companyMenu} from "./layout/companyMenu";
import {positionCandidatesLoader} from "./feature/candidate-carousel-layout/loader/PositionCandidatesLoader";
import {Skeleton} from "@mui/material";

export const routerRules = createBrowserRouter([

  {
    path: "/",
    element: <AppRoot/>,
    children: [
      {
        path: "/start",
        element: <StartRedirect/>
      },
      {
        path: "/interview-meeting-v2/:meetingId",
        element: <MeetingCardPage/>,
      },
      // {
      //   path: "/contractor-onboarding/personal-info",
      //   element: <PersonalInfo />
      // },
      {
        element: <PublicRoute/>,
        children: [

          {
            path: "/interview-meeting/:meetingId",
            element: <MeetingCardPage/>,
          },
          {
            path: "/company-onboarding",
            element: <RegisterCompany/>
          },
          {
            path: "/contractor-onboarding",
            element: <RegisterContractor/>
          }

        ]
      },
      {
        element: <PrivateRouteUpdated/>,
        children: [
          {
            path: "/admin",
            element: <LayoutWithMenu menu={adminMenu} showMenu/>,
            children: [
              {
                errorElement: <ErrorBoundary/>,
                children: [
                  //           <PrivateRoute exact path={'/reschedule/:id'} component={ReschedulePage} />
                  {
                    path: "reschedule/:positionMatchId/meeting/:meetingId*",
                    element: <ReschedulePage />
                  },
                  {
                    path: "google-workspace",
                    element: <GoogleWorkspaceCodeReader/>,
                  },
                  {
                    path: "contracts",
                    element: <AdminContractList/>,
                  },
                  {
                    path: "contract/:id",
                    element: <AdminContract/>,
                  },
                  {
                    path: "companies",
                    element: <CompanyList/>
                  },
                  {
                    path: "invoices",
                    element: <InvoiceList/>
                  },
                  {
                    path: "company/:id",
                    element: <PositionListByCompany/>
                  },
                  {
                    path: "company/:id/manager",
                    element: <Manager/>
                  },
                  {
                    path: "company/:companyId/position/:positionId?/:contractorId?",
                    element: <AdminPositionPage/>
                  },
                  {
                    path: "emails",
                    element: <EmailList/>
                  },
                  {
                    path: "email/:id?",
                    element: <EmailForm/>
                  },
                  {
                    path: "meetings",
                    element: <CompanyMeetings/>
                  },
                  {
                    path: "contractors",
                    element: <ContractorsPage/>
                  },
                  {
                    path: "contractor/:id",
                    element: <ContractorAdminDetails/>
                  },
                  {
                    path: "contractor",
                    element: <CreateContractor/>
                  },
                  {
                    path: "attributes",
                    element: <AttributeList/>
                  },
                  {
                    path: "attribute/:id?",
                    element: <AttributeForm/>
                  },
                  {
                    path: "notifications",
                    element: <Notifications/>
                  },
                  {
                    path: "external",
                    element: <ExternalContractorList/>
                  },
                  {
                    path: "service",
                    element: <ServicePage/>
                  },
                  {
                    path: "service/scheduled-emails",
                    element: <LastScheduledEmails/>
                  },
                  {
                    path: "positions",
                    element: <PositionListActive/>
                  }
                ]
              }
            ]
          },
          {
            path: "/contractor",
            element: <ContractorRoute/>,
            children: [
              {
                errorElement: <ErrorBoundary/>,
                children: [
                  { index: true, element: <Navigate to={"/contractor/notifications"} /> },
                  {
                    path: "personal-info",
                    element: <PersonalInfo />
                  },
                  {
                    path: "onboarding-completed",
                    element: <OnboardingCompleted />
                  },
                  {
                    path: "positions/:positionId",
                    element: <ContractorPositionDetailsPage/>
                  },
                  {
                    path: "position/:positionId",
                    element: <ContractorPositionDetailsPage/>
                  },
                  {
                    path: "positions",
                    element: <ContractorPositions/>
                  },
                  {
                    path: "notifications",
                    element: <Notifications/>
                  },
                  {
                    path: "profile",
                    element: <ProfessionalProfile/>
                  },
                  {
                    path: "payouts",
                    element: <ContractorPayouts/>
                  },
                  {
                    path: "settings",
                    element: <Settings/>
                  },
                  {
                    path: "calendar",
                    element: <CalendarPage/>
                  },
                  {
                    path: "schedule",
                    element: <CalendarPage/>,
                  },
                  // {
                  //   path: "video",
                  //   element: <VideoPage/>
                  // },
                  {
                    path: "balance-reports",
                    element: <BalanceReportsPage/>
                  }
                  //       <PrivateRoute path={'/schedule'} element={() => withLayout(CalendarPage, menu)} />
                  //       <PrivateRoute path={'/video'} element={<VideoPage />} />
                  //       <PrivateRoute path={'/balance-reports'} element={() => withLayout(BalanceReportsPage, menu)} />
                ]
              }
            ]
          },
          {
            path: "/company",
            element: <LayoutWithMenu menu={companyMenu} showMenu />,
            children: [
              {
                errorElement: <ErrorBoundary/>,
                children: [
                  {
                    path: "positions",
                    element: <Positions/>
                  },
                  {
                    path: "position/:id?",
                    element: <PositionPage/>,
                  },
                  // {
                  //   path: "candidates/:positionId",
                  //   element: <CandidateCarouselLayout/>,
                  //   loader: positionCandidatesLoader,
                  //   children: [
                  //
                  //     {
                  //       path: ":positionMatchId",
                  //       element: <Suspense fallback={<Skeleton />}><CandidateCardPreview /></Suspense>
                  //     }
                  //   ]
                  // },
                  {
                    path: "candidates/:positionId/:positionMatchId?",
                    element: <CandidatesPage/>
                  },
                  {
                    path: "candidates/:positionId/:positionMatchId/schedule",
                    element: <CandidatesPage schedule={true}/>
                  },
                  {
                    path: "contracts",
                    element: <CompanyContracts/>
                  },
                  {
                    path: "hire/:positionId/:positionMatchId",
                    element: <HirePage/>
                  },
                  {
                    path: "interviews/:positionId?",
                    element: <CompanyMeetings/>
                  },
                  {
                    path: "notifications",
                    element: <Notifications/>
                  },
                  {
                    path: "hiring-complete",
                    element: <HiringComplete/>
                  },
                  {
                    path: "thank-you",
                    element: <ThankYou/>
                  },
                  {
                    path: "reschedule/:positionMatchId/meeting/:meetingId",
                    element: <ReschedulePage />
                  },
                  {
                    path: "*",
                    element: <ErrorBoundary/>
                  }
                ]
              }
            ]
          },
        ]
      }
    ]
  },

  {
    path: "/*",
    element: <NotFound/>
  }

], {
  future: {
    v7_partialHydration: true,
  }
});
