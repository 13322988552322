import {Button} from "@mui/material";
import * as React from "react";
import {useConfirm} from "../../../../../shared/ConfirmDialogContext";
import {useAdminRemoveContractMutation} from "../../../../../api/sdl";

export default function RemoveContractButton({contractId, onRemoveCallback}: {contractId: string, onRemoveCallback: () => void}) {
  const [removeContract] = useAdminRemoveContractMutation();
  const confirm = useConfirm();

  const onRemove = () => confirm({
    content: 'Are sure you want remove the contract?',
    okButtonTitle: 'Remove',
    cancelButtonTitle: 'Cancel'
  }).then((val) => {
    if(!!val) {
      removeContract({variables: {contractId}}).then(onRemoveCallback)
    }
  });

  return <Button variant={"contained"} color={"error"} onClick={onRemove}>Remove contract</Button>
}
