import {Navigate, Outlet} from 'react-router-dom'
import React, {useContext} from "react";
import {KeycloakContext} from "../../keycloak";
import {ProfileContextProvider} from "../../shared/ProfileContext";

export function PrivateRouteUpdated() {
  const {initialized, keycloak, authenticated} = useContext(KeycloakContext);

  if (initialized) {
    if (authenticated) {
      return <ProfileContextProvider>
        <Outlet/>
      </ProfileContextProvider>
    } else {
      return <Navigate to={"/contractor-onboarding"} />
      // document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})
    }
  }

  return null
}

