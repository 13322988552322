import {Autocomplete, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import { Many } from "lodash";
import * as React from "react";
import {ContractorAttributeAutocompleteProps} from "../lib/types";

export function RolesAutocomplete<T extends Many<string>>(props: ContractorAttributeAutocompleteProps<T, T> & {multiple?: boolean}) {
  const {options, value, onChange, error} = props;

  return <FormControl disabled={!options.length} error={!!error}>
    <FormLabel >
      Healthcare industry experience
    </FormLabel>
    <Autocomplete
      multiple={props.multiple}
      placeholder="Select roles"
      limitTags={2}
      data-test="role"
      options={options.map(a => a.id)}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      getOptionLabel={o => options.find(r => r.id === o)?.attribute || ''}
      renderInput={(params) => <TextField {...params} name={'role'} />}
    />
    {(!!error) && <FormHelperText data-test="role-error" sx={{color: '#D3232F'}}>{error}</FormHelperText>}
  </FormControl>
}

