import {Box, Button, Card, duration, styled, Typography} from "@mui/material";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import {GuestPerson, MeetingDuration} from "src/api/sdl";
import {AddGuestDialog} from "../../../../../feature/calendar/ui/AddGuestDialog";
import {MeetingCard} from "../../../../../entity/meeting/ui/MeetingCard";
import {AppointmentsIcon, RoundedIcon} from "../../../../../shared";

interface Props {
  guests?: GuestPerson[],
  meetingDate: DateTime,
  duration: MeetingDuration,
  onCancel: () => void,
  onScheduled: (guests: GuestPerson[], duration: MeetingDuration) => void,
}

export function MeetingSetupForm(props: Props) {
  const [meetingDuration, setMeetingDuration] = useState<MeetingDuration>(props.duration)

  const [addGuestOpen, setAddGuestOpen] = React.useState(false);
  const [guests, setGuests] = useState<GuestPerson[]>(props.guests || [])

  const handleAddGuestDialogSave = (guest: GuestPerson) => {
    setGuests(prevState => [...prevState, guest]);
    setAddGuestOpen(false);
  }


  useEffect(() => {
    setGuests(props.guests || [])
  }, [props.guests]);

  useEffect(() => {
    setMeetingDuration(props.duration)
  }, [props.duration]);

  const handleScheduleClick = () => {
    props.onScheduled(guests, meetingDuration);
  }

  const handleRemoveGuestClick = (guest: GuestPerson) => {
    setGuests(prev => prev.filter(g => g !== guest));
  }

  return <Wrapper data-test='metting-setup-form' >
    {addGuestOpen && (
      <AddGuestDialog open
                      onClose={() => setAddGuestOpen(false)}
                      onSave={handleAddGuestDialogSave}/>
    )}
    <Typography sx={{fontWeight: 700, fontSize: 18, marginTop: 2}} >
      Interview details
    </Typography>

    <Card sx={{py: 2, px: 3}}>
      <MeetingCard meeting={{ guests, meetingScheduledOn: props.meetingDate, duration: meetingDuration }}
                   showGuests
                   slots={{
                     icon: <RoundedIcon iconSize={20} size={36} icon={<AppointmentsIcon />} />,
                     button: (
                       <Button sx={{alignSelf: 'self-end', mt: 2}}
                               data-test="add-guest-button"
                               onClick={() => setAddGuestOpen(true)}>Add guest</Button>
                     )
                   }}
                   onGuestRemoveClick={handleRemoveGuestClick}
                   onDurationChange={setMeetingDuration}

      />
    </Card>

    <Box sx={{display: 'flex', flexDirection:'column', gap: 3, justifyContent: 'flex-end'}}>
      <Box sx={{display: 'flex', justifyContent: "space-between"}}>
        <Button sx={{color:'#6D6D6D'}} data-test="cancel-meeting"
                onClick={props.onCancel}>Discard changes</Button>
        <Button color={'primary'}
                variant={"contained"}
                data-test="schedule-meeting-button"
                onClick={handleScheduleClick}>
          Schedule meeting
        </Button>
      </Box>
      <Box>
        <Typography variant={"body2"} sx={{fontSize: '14px'}}>
          Once the meeting is scheduled, you and any guests will receive a calendar invite notification.
        </Typography>
      </Box>
    </Box>
  </Wrapper>
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})