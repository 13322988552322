import React from 'react';
import {Box, Divider, Drawer, List, ListItem, ListItemText, Typography} from "@mui/material";
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {MeetingListStory} from "./MeetingList.story";
import ListItemButton from "@mui/material/ListItemButton";

const drawerWidth = 240;

export function StoryBook() {
  const navigate = useNavigate();

  return <Box sx={{ display: 'flex' }}>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography variant={'h3'} sx={{p: 2}}>StoryBook</Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('../meeting-list')}>
            <ListItemText primary={'MeetingList'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>

    <Box sx={{p: 3}}>
      <Routes>
        <Route path={'/meeting-list'}>
          <MeetingListStory />
        </Route>
        <Route>
          <Navigate to="/meeting-list" />
        </Route>
      </Routes>
    </Box>

  </Box>
}