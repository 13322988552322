import {
  Box, FormControl, FormHelperText, FormLabel, Input,
  Stack, TextField
} from "@mui/material";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {LoadingButton} from "@mui/lab";
import {
  CreateContractPayloadInput, SalaryRangeType,
} from "../../../../../api/sdl";
import ContractorSelect from "./ContractorSelect";
import CompanySelect from "./CompanySelect";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";
import SalaryRangeTypeSelect from "../../../../../shared/components/SalaryRangeTypeSelect";

const schemaFields: any = {
  companyId: yup
    .string()
    .required('Company is required'),
  contractorId: yup
    .string()
    .required('Contractor is required'),
  salaryRangeType: yup
    .string()
    .required('Type is required'),
  anticipatedStartDate: yup
    .date()
    .typeError('Date is required')
    .required('Date is required'),
  positionTitle: yup
    .string()
    .required('Position title is required'),
  platformCommission: yup
      .number()
      .required('Commission is required')
      .default(25)
}

interface Props {
  initialValues?: CreateContractPayloadInput
  onSubmit: (data: CreateContractPayloadInput) => void,
  loading: boolean,
  companies: {id: string, name: string}[],
  contractors: {id: string, label: string}[],
  contractorsLoading: boolean,
  onContractorInputChange: (value: string) => void,
}

function CreateContractForm(props: Props) {
  const {loading, onSubmit, initialValues =
      {companyId: '', contractorId: '', anticipatedStartDate: null, contractorExpectedRate: null, positionTitle: '', salaryRangeType: '', platformCommission: 25}} = props;

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        onSubmit({
          ...values,
          anticipatedStartDate: DateTime.fromJSDate(values.anticipatedStartDate!).toFormat('yyyy-LL-dd'),
          salaryRangeType: values.salaryRangeType as SalaryRangeType,
          platformCommission: values.platformCommission,
        });
      }
    },
    validateOnChange: true
  });

  return <Box ml={2} mt={4} component={'form'} onSubmit={formik.handleSubmit}>
    <Stack spacing={3}>

      <FormControl sx={{flex: 1}}>
        <FormLabel>Position title</FormLabel>
        <TextField
                   name="positionTitle"
                   value={formik.values.positionTitle}
                   onChange={formik.handleChange}
                   error={formik.touched.positionTitle && Boolean(formik.errors.positionTitle)}
        />
      </FormControl>

      <Box sx={{display: 'flex', flexDirection: 'row', gap: 3, flex: 1, maxWidth: 500}}>

        <FormControl sx={{width: 1}}>
          <FormLabel>Contractor's expected rate</FormLabel>
          <TextField
                     name="contractorExpectedRate"
                     type={"number"}
                     value={formik.values.contractorExpectedRate}
                     onChange={formik.handleChange}
                     error={formik.touched.contractorExpectedRate && Boolean(formik.errors.contractorExpectedRate)}
          />
        </FormControl>
        <SalaryRangeTypeSelect value={formik.values.salaryRangeType}
                               onChange={value => {
                                 formik.setFieldValue('salaryRangeType', value)
                               }}
                               sx={{width: 1}}
                               error={formik.touched.salaryRangeType && formik.errors.salaryRangeType ? formik.errors.salaryRangeType : ''}
        />
      </Box>

      <CompanySelect value={formik.values.companyId}
                     onChange={value => formik.setFieldValue('companyId', value)}
                     error={formik.touched.companyId && formik.errors.companyId ? formik.errors.companyId as string : undefined}
                     companies={props.companies}
      />

      <ContractorSelect value={formik.values.contractorId}
                        onChange={value => formik.setFieldValue('contractorId', value)}
                        error={formik.touched.contractorId && formik.errors.contractorId ? formik.errors.contractorId as string : undefined}
                        contractors={props.contractors}
                        loading={props.contractorsLoading}
                        onInputChange={props.onContractorInputChange}
      />
      <FormControl>
        <FormLabel>Anticipated Start Date</FormLabel>
        <DatePicker selected={formik.values.anticipatedStartDate}
                    customInput={<TextField placeholder="Select date"
                                            error={formik.touched.anticipatedStartDate && Boolean(formik.errors.anticipatedStartDate)}
                    />}
                    minDate={new Date()}
                    onChange={(date: Date) => formik.setFieldValue('anticipatedStartDate', date)} />
        {(formik.touched.anticipatedStartDate && formik.errors.anticipatedStartDate) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.anticipatedStartDate}</FormHelperText>}
      </FormControl>

      <Box sx={{display: 'flex', flexDirection: 'row', gap: 3, flex: 1, maxWidth: 150}}>
        <FormControl sx={{width: 1}}>
          <FormLabel>Platform Commission</FormLabel>
          <TextField
              name="platformCommission"
              type={"number"}
              value={formik.values.platformCommission}
              onChange={formik.handleChange}
              error={formik.touched.platformCommission && Boolean(formik.errors.platformCommission)}
          />
        </FormControl>
      </Box>
{/*
      <FormControl sx={{flex: 1}}>
        <FormLabel>First Name</FormLabel>
        <TextField placeholder="First name"
                   name="firstName"
                   data-test="first-name"
                   autoFocus={true}
                   value={formik.values.firstName}
                   onChange={formik.handleChange}
                   error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        />
        {(formik.touched.firstName && formik.errors.firstName) && <FormHelperText data-test='first-name-error' sx={{color: '#D3232F'}}>{formik.errors.firstName}</FormHelperText>}
      </FormControl>
      */}
      <div>
        <LoadingButton loading={loading} disabled={loading} variant={'contained'} type={'submit'}>Submit</LoadingButton>
      </div>
    </Stack>
  </Box>
}

export default CreateContractForm
