import {Box, Button, Container, Divider} from "@mui/material";
import {SignIn, SignOut} from "phosphor-react";
import React, {PropsWithChildren, useContext} from "react";
import Stepper from "../../components/Stepper";
import OnboardingBottomNav from "../../components/OnboardingBottomNav";
import {KeycloakContext} from "../../../keycloak";
import {useIsMobile} from "../../../shared";
import {useNavigate} from "react-router-dom";

export default function OnboardingLayout(props: PropsWithChildren<{}>) {
  const {keycloak, authenticated} = useContext(KeycloakContext);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return <Container maxWidth={'lg'} sx={{mb: 10}}>
    <Box sx={{display: 'flex', width: 1, height: 80, mb: 3, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box component={'img'} src={'/img/MDliaison_logo.png'} width={200} height={44} />
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
        {authenticated && <Button variant={'outlined'} color={'primary'}
                                              onClick={() => {
                                                keycloak.logout({redirectUri: document.location.origin + "/"})
                                              }}
                                              startIcon={<SignOut size={24} color="#37c9ef" weight="regular" />}>Logout</Button>}
      </Box>
    </Box>
    <Box>
      <Box sx={{display: 'flex', gap: 4}}>
        {!isMobile && <>
            <Box flex={1}>
                <Stepper />
            </Box>
            <Divider orientation={'vertical'} />
        </>}
        <Box sx={{flex: 4, width: 1}}>
          {props.children}
        </Box>
        {isMobile && <OnboardingBottomNav />}
      </Box>
    </Box>
  </Container>
}

export const withOnboardingLayout = (WrappedComponent: any) => (props: any) => {
  return <OnboardingLayout>
    <WrappedComponent {...props}/>
  </OnboardingLayout>
}
