import {useCommissionClick} from "../lib/useCommissionClick";
import {Box, Button} from "@mui/material";
import React from "react";

export interface CommissionEditButtonProps {
    commission: number,
    positionId: string,
}

export const CommissionEditButton = (props: CommissionEditButtonProps) => {

    const [updatedCommission, setUpdatedCommission] = React.useState<number>(props.commission)
    const editCommission = useCommissionClick((value) => {
        setUpdatedCommission(value);
    });

    return <>
        <Box>Commission: <Button size={"small"} onClick={() => {
            editCommission({...props, commission: updatedCommission})
        }}>{updatedCommission}%</Button></Box>
    </>
};