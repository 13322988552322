import * as React from "react";
import {useEffect} from "react";
import {useAdminContractLazyQuery} from "../../../../../api/sdl";

export default function useContractById(contractId?: string) {
  const [getContract, { data}] = useAdminContractLazyQuery({
    variables: {contractId},
  });

  useEffect(() => {
    if (contractId) {
      getContract({variables: {contractId}});
    }
  }, [contractId]);

  return React.useMemo(() => {
    return data?.adminContract;
  }, [data]);
}
