import {Box, Typography, SxProps, styled} from "@mui/material";
import React from "react";

interface InfoCardProps {
  title?: string | JSX.Element,
  content: string | JSX.Element,
  icon?: JSX.Element,
  sx?: SxProps,
  hideIcon?: boolean,
}

export default function InfoCard(props: InfoCardProps) {
  return <Box data-test='infocard' sx={{
    display: 'flex',
    padding: 3,
    gap: 2,
    borderRadius: '10px',
    border: '1px solid #D8E9F3',
    background: '#F0F8FD', ...props.sx
  }}>
    {!props.hideIcon &&
        <IconBox>
          {!props.icon && <Box component={'img'} src={`/img/teenyicons/bulb-on.svg`} width={24} height={24}/>}
          {!!props.icon && <>{props.icon}</>}
        </IconBox>}

    <ContentBox>
      {!!props.title && <>
        {typeof props.title === 'string' &&
            <Typography sx={{fontWeight: 600, color: '#000'}}>{props.title}</Typography>}
        {typeof props.title !== 'string' && <>{props.title}</>}
      </>}

      {typeof props.content === 'string' && <Typography>{props.content}</Typography>}
      {typeof props.content !== 'string' && <>{props.content}</>}
    </ContentBox>
  </Box>
}


const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
});

const IconBox = styled(Box)({
  minWidth: '48px',
  minHeight: '48px',
  borderRadius: '50px',
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: '#9AE4F8',
  padding: '12px',
  width: '24px',
  height: '24px',
});