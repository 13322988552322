import {Box, DialogContent, DialogTitle, Divider, Drawer, Stack, Typography, Paper, IconButton} from "@mui/material";
import React, {useMemo} from "react";
import * as _ from 'lodash';
import {CrossIconButton} from "../../../feature";

export default function ExternalContractorHistoryList({workHistory, onClose}: {workHistory?: {[key: string]: string}[], onClose: () => void}) {

  const sorted = useMemo(() => {
    if (workHistory) {
      const res = workHistory.map(item => ({
        ..._.pick(item, ['Position', 'Description']),
        ..._.omit(item, ['Position', 'Description']),
      }));
      return _.orderBy(res, 'StartDate', 'desc');
    }
  }, [workHistory]);

  return <Drawer
    open={!!workHistory}
    anchor={'right'}
    onClose={onClose}
  >
    <Paper
      sx={{
        borderRadius: 'md',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        overflow: 'auto',
      }}
    >
      <DialogTitle sx={{display: 'flex', justifyContent: 'space-between'}}>
        Work History
          <CrossIconButton onClose={onClose} />
      </DialogTitle>
      <Divider sx={{ mt: 'auto' }} />
      <DialogContent sx={{ gap: 2 }}>
        <Stack spacing={1}>
          {sorted?.map((item: any, i) => <React.Fragment key={'h' + i}>
            <Box sx={{backgroundColor: '#f6f6f6', px: 2, py: 1}}>
              <table>
                <tbody>
                  {Object.keys(item).map((name, j) => (
                    <tr key={'p' + j}>
                      <Box component={'td'} sx={{verticalAlign: 'top'}}>
                        <Typography><b>{name}</b></Typography>
                      </Box>
                      <td>
                        <Typography sx={{whiteSpace: 'pre-wrap'}}>{item[name]}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>

            {i < sorted?.length - 1 && <Divider sx={{my: 4}}/>}
          </React.Fragment>)}
        </Stack>
      </DialogContent>
    </Paper>
  </Drawer>
}
