import React from "react";
import {
  GwStatus, useDeleteGoogleWorkspaceTokensMutation,
  useGoogleWorkspaceAuthorizationUrlLazyQuery,
  useGoogleWorkspaceHasCredentialsQuery
} from "../../../api/sdl";
import {Box, Button, Link, List, ListItem, Typography} from "@mui/material";


export function GoogleMeetButton() {
  const {data, refetch} = useGoogleWorkspaceHasCredentialsQuery();
  const [requestAuthUrl] = useGoogleWorkspaceAuthorizationUrlLazyQuery()
  const [deleteGWTokenApi] = useDeleteGoogleWorkspaceTokensMutation();

  const handleIntegrationClick = async () => {

    const res = await requestAuthUrl();
    window.open(res.data?.googleWorkspaceAuthorizationUrl, '_newtab');
  }

  const handleDeleteToken = async () => {

    await deleteGWTokenApi({variables: {}});
    await refetch();
  }
  if (data?.googleWorkspaceHasCredentials.status === GwStatus.Empty) {
    return <>
      <Button onClick={handleIntegrationClick} variant={'contained'}>Connect Google Workspace</Button>
    </>
  }
  return <>
    <Box sx={{m:3}}>
      <Typography variant={"body1"} sx={{marginBottom: 3}}>Google Workspace is <b>connected</b> .</Typography>
      <Typography>To disconnect the account, you need to do 2 steps:</Typography>
      <List>
        <ListItem>
          1. Remove MDliaison on the following page: <Link href={"https://myaccount.google.com/connections"}
                                                           target={"_blank"}> Google Workspace Settings</Link>
        </ListItem>
        <ListItem>
          2. Clean up token on our end: <Button color={'error'} onClick={handleDeleteToken}>Delete token</Button>
        </ListItem>
      </List>
    </Box>

  </>;
}