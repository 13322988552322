import {ContractorProfilePayloadInput, useSaveContractorProfileMutation} from "../../../api/sdl";

export default function useSaveProfileApi(contractorId?: string) {
  const [saveProfile, {loading}] = useSaveContractorProfileMutation();

  const save = (payload: ContractorProfilePayloadInput) => {
    return saveProfile({
      variables: {
        contractorProfileId: contractorId,
        payload: payload
      }
    })
  };

  return {save, loading}
}
