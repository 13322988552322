import {useConfirm} from "../../../shared/ConfirmDialogContext";
import React from "react";
import {useDeclinePositionMatchAsCompanyMutation} from "../../../api/sdl";
import {useSnackbar} from "notistack";
import RejectCandidateDialogForm from "../../../company/pages/Candidates/ui/RejectCandidateDialogForm";

export default function useRejectCandidateClick(onCompletedCallback: () => void, positionMatchId?: string) {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const ref = React.useRef<any>();
  const [reject] = useDeclinePositionMatchAsCompanyMutation({
    onCompleted: res => {
      enqueueSnackbar('Position was declined', {variant: 'info'});
      onCompletedCallback();
    },
    variables: {positionMatchId, declineReason: (ref.current as any)?.getReason()}
  })

  const onRejectClick = () => confirm({
    content: () => <RejectCandidateDialogForm ref={ref} />,
    validate: () => (ref.current as any).isValid(),
    width: 500,
    okButtonTitle: 'Reject',
    cancelButtonTitle: 'Close',
  }).then(val => {!!val && reject()});

  return onRejectClick;
}
