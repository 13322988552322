import {ContractorAttribute, SalaryRangeType} from "../../../../../api/sdl";
import {FormValues} from "../lib/types";
import {Editable} from "../../../../../shared";
import React from "react";
import * as _ from "lodash";
import View from "./View";
import Form from "./Form";
import {Card} from "@mui/material";

interface CurrentExperienceProps {
  roles: ContractorAttribute[],
  specialities: ContractorAttribute[],
  salaryRangeMax?: number,
  salaryRangeMin?: number,
  salaryRangeType?: SalaryRangeType,
  cvUrl?: string,
  loading: boolean,
  onSubmit: (values: FormValues) => Promise<any>,
}

export function BaseCurrentExperience(props: CurrentExperienceProps & Editable) {
  const {
    specialities,
    roles,
    editable,
    salaryRangeMax,
    salaryRangeMin,
    onSubmit,
    salaryRangeType,
    loading,
    cvUrl
  } = props
  const [edit, setEdit] = React.useState(false);

  if (edit) {
    return <Card sx={{p: '20px 24px'}}>
      <Form onBackClick={() => setEdit(false)}
            onSubmit={async values => {
              await onSubmit(values)
              setEdit(false)
            }}
            cvUrl={cvUrl}
            loading={loading}
            initialValues={{
              roles: _.map(roles, 'id'),
              specialities: _.map(specialities, 'id'),
              salaryRangeMax: String(salaryRangeMax || ''),
              salaryRangeMin: String(salaryRangeMin || ''),
              salaryRangeType: salaryRangeType || '',
            } as FormValues}
      />
    </Card>
  }

  return <Card sx={{p: '20px 24px'}}>
    <View roles={_.map(roles, 'attribute')}
          callPointSpecialities={_.map(specialities, 'attribute')}
          editable={editable}
          onEdit={() => setEdit(true)}
          salaryRangeMax={salaryRangeMax}
          salaryRangeMin={salaryRangeMin}
    />
  </Card>
}
