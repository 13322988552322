import {useNavigate, useParams} from "react-router-dom";
import {
  Box,  Container, Divider, Typography
} from "@mui/material";
import React from "react";
import Loading from "../../../shared/components/Loading";
import FutureMeetingRowItem from "../../../entity/meeting/ui/FutureMeetingRowItem";
import {PastMeetingRowItem} from "../../../entity";
import {MeetingHeader} from "./ui/MeetingHeader";
import { MeetingPagination } from "./ui/MeetingPagination";
import {useAllMeetingsApi} from "../../../entity/meeting/api/useAllMeetingsApi";
import {useIsAdmin} from "../../../shared";


function CompanyMeetings() {
  const {positionId} = useParams();
  const isAdmin = useIsAdmin();
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState('all');
  const navigate = useNavigate();

  const {noData, loading, futureMeetings, pastMeetings, refetchMeetings, hasPrevious, hasNext} = useAllMeetingsApi(isAdmin, page, positionId);

  return <Box>
    <Container sx={{p: 3}} maxWidth={'lg'}>

      <MeetingHeader onFilterChange={setFilter} showFilter={(!!futureMeetings.length && !!pastMeetings.length)}/>

      <Box mt={2}>
        {noData &&
            <Box data-test='no-interviews' sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '500px'}}>
              <Typography sx={{fontWeight: 600, color: '#2C92D5', mb: 7}}>No interviews scheduled yet!</Typography>
              <Box component={'img'} src={`/img/teenyicons/appointments-solid.svg`} width={157} height={157} />
            </Box>
        }

        <Loading in={loading}>
          {(!!futureMeetings.length && filter !== 'past') && <>
              <Typography variant={'body1'}><b>Upcoming meetings</b></Typography>
              <Typography sx={{color: '#6D6D6D', mt: 1, mb: 3}}>Click the ‘Join meeting’ button below at the time of your interview, or press ‘Cancel’ to reschedule.</Typography>

            {futureMeetings.map((item, i) => (
              <FutureMeetingRowItem key={item.meetingId}
                                    onRescheduleClick={() => (
                                      navigate(`../reschedule/${item.positionMatchId}/meeting/${item.meetingId}`)
                                    )}
                                    onCancelMeeting={() => refetchMeetings()} item={item}  />
            ))}
          </>}
        </Loading>

        {(!!futureMeetings.length && !!pastMeetings.length && filter === 'all') && <Divider sx={{my: 3, mx: 10}} />}

        <Loading in={loading}>
          {!!pastMeetings.length && filter !== 'upcoming' && <>
            <Typography variant={'body1'} sx={{mb: 2}}><b>Past meetings</b></Typography>

            {pastMeetings.map((meeting, i) =>
              <PastMeetingRowItem key={i} hired={meeting.hired} item={meeting} />)
            }

            <MeetingPagination
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                onPreviousClick={() => setPage(page - 1)}
                onNextClick={() => setPage(page + 1)}
            />
          </>}
        </Loading>
      </Box>
    </Container>
  </Box>;
}

export default CompanyMeetings
