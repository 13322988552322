import {Outlet} from "react-router-dom";
import Layout from "../shared/components/Layout";
import {useIsAdmin} from "../shared";
import {Box} from "@mui/material";

interface Props {
  menu: any,
  showMenu: boolean,
}

function LayoutInternal({menu, showMenu}: Props) {
  const isAdmin = useIsAdmin()

  if(!showMenu) {
    return <Outlet />
  } else {
    return <>
      <Layout menu={menu}>
        <Box  sx={{m: isAdmin? 2:0}}>
          <Outlet/>
        </Box>
      </Layout>
    </>
  }

}

export const LayoutWithMenu = LayoutInternal