import {Box, Button, Chip, CircularProgress, Divider, Typography} from "@mui/material";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import React from "react";
import {PositionStatus, ReferenceType,} from "../../../../api/sdl";
import PositionView from "../../position-interface/widget/position-view/PositionView";
import PositionForm from "../../position-interface/widget/position-form/PositionForm";
import {LoadingButton} from "@mui/lab";
import {CommissionEditButton} from "../../../../feature/position-commission-modal";
import {useCompanyApi} from "../../../../entity/company";
import {AdminPositionMatchList, MatchContractorList} from "../../../../feature";
import {useNavigate} from "react-router-dom";
import {ContractorProfileDrawer} from "../contractor-profile-drawer/ContractorProfileDrawer";
import {useSessionStorageState} from "../../../../shared";
import {useAdminPositionLogic} from "./reducer/useAdminPositionLogic";
import {NotesDrawer} from "../../../admin-interface/feature/notes-drawer/NotesDrawer";

interface Props {
  companyId: string,
  positionId: string,
}

export function AdminPosition({companyId, positionId}: Props) {
  const {contractorIdDrawer, position, publishLoading, candidates, unpublishLoading, dispatch} = useAdminPositionLogic({positionId, companyId});
  const [showPositionDetails, setShowPositionDetails] = useSessionStorageState(`hide-position-details-${positionId}`, {value: true});
  const {company} = useCompanyApi(companyId);
  const [showForm, setShowForm] = React.useState(!positionId);
  const navigate = useNavigate();

  const isActive = React.useMemo(() => {
    return position && position.status !== PositionStatus.Archived && position.status !== PositionStatus.Closed
  }, [position])

  if (positionId && !position) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '80vh'}}>
      <CircularProgress size="15" />
    </Box>
  }

  return <div>
    {position && <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
        <Box sx={{flexDirection: "row", display: "flex", gap: 3}}>
            <Typography variant={'h3'} sx={{ml: 2}}>{position?.company.name}: {position?.title}</Typography>
            <Button variant={"outlined"}
                    size={"small"}
                    onClick={() => setShowPositionDetails({value: !showPositionDetails.value})}>{showPositionDetails.value ? "Hide" : "Show"} details</Button>
            <NotesDrawer reference={positionId} referenceType={ReferenceType.Position} />
        </Box>
      <CommissionEditButton positionId={position.id!} commission={position.platformCommission} />

    </Box>}
     <Box ml={2} mt={1}>
      <Breadcrumbs crumbs={[
        {title: 'All companies', link: '../companies'},
        {title: company?.companyProfile?.name + ' positions', link: '../company/' + companyId},
        {title: positionId ? `${position?.title}` : 'Create new'}]} />
    </Box>
    {showPositionDetails.value &&
      <Box sx={{mt: 2, mb: 3, px: 2}}>
        {position && !showForm && <PositionView extraAction={<Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
          <Chip size={'small'} variant={'outlined'} sx={{borderColor: '#3075b8', color: '#3075b8'}} label={position?.status} />
          {(position?.status === PositionStatus.Draft || position?.status === PositionStatus.Archived ) && <LoadingButton variant={'contained'} onClick={() => dispatch({type: "PUBLISH"})} loading={publishLoading}>Publish</LoadingButton>}
          {position?.status === PositionStatus.Open && <LoadingButton variant={'contained'} onClick={() => dispatch({type: "UNPUBLISH"})} loading={unpublishLoading}>Unpublish</LoadingButton>}
          {isActive && <Button variant={'contained'} color={'error'} onClick={() => dispatch({type: "ARCHIVE"})}>Archive</Button>}
        </Box>}
                                    onEditClick={isActive ? () => setShowForm(true) : undefined}
                                    position={position} />}
        {showForm && <Box py={3}>
          <PositionForm id={position?.id} companyId={company?.companyProfile.id} onBackClick={() => setShowForm(false)}
                        companyName={company?.companyProfile?.name }
                        onSubmit={value => {
                          setShowForm(false);
                          if (positionId) {
                            dispatch({type: "POSITION_UPDATED"})
                          } else {
                            window.scrollTo(0, 0)
                            navigate(`../company/${companyId}/position/${value}`, {replace: true})
                          }
                        }} />
        </Box>}
      </Box>
    }

    {positionId && isActive && <>
      <Divider sx={{my: 3}} />
        <AdminPositionMatchList
            candidates={candidates}
            onRemoveCandidate={(positionMatchId, notifyContractor) => {
              dispatch({
                type: "REMOVE_CANDIDATE_REQUESTED",
                payload: {positionMatchId, notifyContractor}
              })
            }}
            onConfirm={(positionMatchId) => {
              dispatch({
                type: "CONFIRM_CANDIDATE_REQUESTED",
                payload: {positionMatchId}
              })
            }}
            onProfileReview={(contractorId) => dispatch({type: "OPEN_PROFILE", payload: {contractorId}})
            }
        />

      <Divider sx={{my: 3}} />

      {contractorIdDrawer &&
          <ContractorProfileDrawer contractorId={contractorIdDrawer} onClose={() => {dispatch({type: "CLOSE_PROFILE_DRAWER"})}} />
      }
     <MatchContractorList
          onInvite={(contractorId) => dispatch({type: 'CANDIDATE_INVITED', payload: {contractorId}})}
          onContractorSelect={(contractorId)=> dispatch({type: 'OPEN_PROFILE', payload: {contractorId}})}
      />
    </>}
  </div>
}

