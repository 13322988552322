import React from "react";
import {AdminPosition} from "../feature/admin-position/AdminPosition";
import {useParams} from "react-router-dom";

export const AdminPositionPage = () => {
  const {companyId, positionId} = useParams();

  return <AdminPosition companyId={companyId!} positionId={positionId!}/>
}

