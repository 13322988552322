import {
  useFutureMeetingsLazyQuery, usePastMeetingsLazyQuery,
} from "../../../api/sdl";
import React from "react";
import {MeetingDetailsItem} from "../lib/types";

export function useAllMeetingsApi(isAdmin: boolean, page: number, positionId?: string) {

  const [futureMeetings, setFutureMeetings] = React.useState<MeetingDetailsItem[]>([]);
  const [pastMeetings, setPastMeetings] = React.useState<MeetingDetailsItem[]>([]);
  const [inc, setInc] = React.useState<number>(0);
  const [hasNext, setHasNext] = React.useState<boolean>();
  const [hasPrevious, setHasPrevious] = React.useState<boolean>();

  const [requestFutureMeetings, {loading: futureLoading, called: futureCalled}] =
    useFutureMeetingsLazyQuery({
        fetchPolicy: 'no-cache',
        variables: {positionId: positionId},
        onCompleted: (data) => setFutureMeetings(data.futureMeetings)
      }
    );

  const [requestPastMeetings, {loading: pastLoading}] = usePastMeetingsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {positionId, page: {page, size: 10}},
    onCompleted: (data) => {
      setPastMeetings(data.pastMeetings.data)
      setHasNext(data.pastMeetings.hasNext)
      setHasPrevious(data.pastMeetings.hasPrevious)
    }
  });

  React.useEffect(() => {
    requestFutureMeetings();
    requestPastMeetings();
  }, [isAdmin, inc]);

  return {
    noData: futureCalled && !futureLoading && !pastLoading && !futureMeetings.length && !pastMeetings.length,
    futureMeetings,
    pastMeetings,
    hasPrevious,
    hasNext,
    loading: futureLoading || pastLoading,
    refetchMeetings: () => setInc((i) => i + 1),
  }
}