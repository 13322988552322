import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {NoteItemData} from "../lib/types";
import {formatDate} from "../../../../../shared";
import {Pencil, Trash} from "phosphor-react";

interface Props {
  item: NoteItemData,
  onDelete: () => void,
  onEdit: () => void,
}
export function NoteItemView({item, onEdit, onDelete}: Props) {

  return <>
    <Box sx={{ border: '1px solid #D5D5D5', borderRadius: "8px", p: 2 }}>
      <Stack spacing={2} direction={"row"} sx={{justifyContent: "space-between"}}>
        <Stack direction="row" spacing={1}>
          <Typography variant={"body2"}>{item.managerName}</Typography>
          <Typography variant={"body2"}> :: </Typography>
          <Typography variant={"body2"}>{formatDate(item.createdAt)}</Typography>
        </Stack>

        <Stack direction={"row"} spacing={2}>
          <IconButton onClick={onEdit}>
            <Pencil size={20} color="#2660A9" weight="light" />
          </IconButton>
          <IconButton onClick={onDelete}>
            <Trash size={20} color="#C61B24" weight="light" />
          </IconButton>
        </Stack>
      </Stack>

      <Typography sx={{lineHeight: 1.5}} dangerouslySetInnerHTML={{__html: item.message || ''}} data-test="note-view" />
    </Box>
  </>
}