import {
  UserProfile,
} from "../../../../api/sdl";
import {Editable} from "../../../../shared";
import React from "react";
import {FormValues} from "./lib/types";
import {BasicContactInformation} from "./ui/BasicContactInformation";
import {ContractorProfile} from "../../../../entity/contractor";

interface Props {
  contractorProfile?: ContractorProfile,
  profile?: UserProfile,
  onFormSubmit: (values: FormValues) => Promise<any>,
  loading?: boolean,
}

export default function ContactInformation(props: Props & Editable) {
  const {profile, contractorProfile, onFormSubmit, loading, ...rest} = props;

  return <BasicContactInformation loading={!!loading}
                                  onSubmit={onFormSubmit}
                                  cities={contractorProfile?.cities}
                                  phone={profile?.phone || ''}
                                  email={profile?.email || ''}
                                  linkedInUrl={contractorProfile?.linkedInUrl || ''}
                                  {...rest} />
}
