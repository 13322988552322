import React, {PropsWithChildren} from "react";
import {Box, CircularProgress} from "@mui/material";

const Loading = (props: PropsWithChildren<{in?: boolean, height?: string | number}>) => {
  return <>
    {!!props.in && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: props.height || 'calc(100vh - 150px)'}}>
      <CircularProgress sx={{width: 25}} size={25} />
    </Box>}
    {!props.in && <>{props.children}</>}
  </>
}

export default Loading;
