import React from "react";
import {Box, Typography} from "@mui/material";

const HiringComplete = () => {
  return <Box style={{height: '100vh', backgroundImage: "url(/img/congratulations.svg)", display: "flex", marginBottom: '-150px'}}>

    <Box sx={{
      flexBasis: {xs: '350px', md: '500px'},
      height: {xs: '350px', md: '500px'},
      width: {xs: '350px', md: '500px'},
      margin: 'auto',
      minHeight: {xs: '350px', md: '500px'},
      background: '#FFF',
      borderRadius: '300px',
      border: '5px solid #F0F0F0',
      display: 'flex',
      justifyContent: 'center', alignItems: 'center', alignContent: 'center',
    }}>
      <Box  data-test='hiring-complete' sx={{ m: {xs: 4, md: 5},
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography variant={"h2"} sx={{fontSize: {md: 36}, pb: 2}} data-text={"congratulations-title"}>Congratulations!</Typography>
        <Typography variant={'body1'}  sx={{fontSize: {md: 16}, pb: 2}}>You have hired your sales team member.</Typography>
        <Typography variant={'body1'}  sx={{fontSize: {md: 16}, pb: 2}}>
          You will receive an email from your account manager with our talent agreement to kick off your contract!
      </Typography>
      </Box>

    </Box>
  </Box>
};

export default HiringComplete;