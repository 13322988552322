import {Autocomplete, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import * as React from "react";
import {ContractorAttributeAutocompleteProps} from "../lib/types";

export function SpecialitiesAutocomplete(props: ContractorAttributeAutocompleteProps<string[], string[]>) {
  const {options, value, onChange, error} = props;

  return <FormControl disabled={!options.length} error={!!error}>
    <FormLabel id="category-select-label" htmlFor="category-select">
      Call point specialities (choose up to five relevant to this job)
    </FormLabel>
    <Autocomplete
      multiple
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      placeholder="Select specialities"
      data-test="specialist"
      limitTags={2}
      value={value}
      getOptionLabel={o => options.find(r => r.id === o)?.attribute || ''}
      renderInput={(params) => <TextField {...params} name={'specialities'} />}
      options={options.map(a => a.id)}
    />
    {(!!error) && <FormHelperText sx={{color: '#D3232F'}}>{error}</FormHelperText>}
  </FormControl>
}

