import React, {FunctionComponent, useState} from "react";
import {
  Button, Typography, Dialog, DialogContent, DialogTitle, DialogActions
} from '@mui/material';
import {Warning} from 'phosphor-react';
import {ButtonOwnProps} from "@mui/material/Button/Button";


interface ConfirmDialogContextData {
  content: string | JSX.Element | FunctionComponent<ConfirmModalFunctionComponentProps>,
  okButtonTitle?: string,
  okButtonColor?: ButtonOwnProps['color'],
  cancelButtonTitle?: string,
  validate?: (value: any) => Promise<boolean>
  title?: string,
  width?: string | number,
}

export const ConfirmDialogContext = React.createContext((data: ConfirmDialogContextData) => new Promise(resolve => {resolve(data)}))

export function ConfirmDialogProvider({ children }: { children: any }) {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<ConfirmDialogContextData>();

  const confirm = (data: ConfirmDialogContextData) => {
    setState(data)
    setOpen(true);
    return new Promise<any>((resolve) => {
      (ref as any).current = resolve;
    })
  }

  const fn = (choice: any) => {
    (ref as any)?.current(choice)
    ref.current = undefined;
  }

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      {state?.content && <ModalWrapper
        {...state}
        open={open}
        onClose={(value?: any) => {
          if (state.validate && value) {
            state.validate(value).then((res) => {
              if (res) {
                setOpen(false);
                fn(value);
              }
            })
          } else {
            setOpen(false);
            fn(value);
          }
        }}
      />}
    </ConfirmDialogContext.Provider>
  )
}

export const withConfirmDialog = (WrappedComponent: any) => (props: any) => {
  return (
    <ConfirmDialogProvider>
      <WrappedComponent {...props} />
    </ConfirmDialogProvider>
  )
}

export function useConfirm() {
  return React.useContext(ConfirmDialogContext)
}

export interface ConfirmModalFunctionComponentProps {
  onValueChange: (value: any) => void
}

interface ConfirmModalProps {
  open: boolean,
  onClose: (value?: boolean) => void,
  okButtonTitle?: string,
  okButtonColor?: ButtonOwnProps['color'],
  content: string | React.ReactNode | FunctionComponent<ConfirmModalFunctionComponentProps>,
  cancelButtonTitle?: string,
  title?: string,
  width?: string | number,
}

function ModalWrapper(props: ConfirmModalProps) {
  const [value, setValue] = useState<any>(true);

  const Content = props.content as any;
  return <Dialog open={props.open} onClose={() => props.onClose(false)}
                 sx={{'& .MuiDialog-paper': {width: props.width}}}
  >
      <DialogTitle sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Warning size={24} color="#A10E25" weight="regular" />
        <Typography fontWeight={600} fontSize={18}>
          {props.title || 'Confirmation'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {typeof props.content === 'string' && <Typography>
          {props.content}
        </Typography>}
        {typeof props.content === 'object' && <>{props.content}</>}
        {typeof props.content === 'function' && <Content onValueChange={setValue} />}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={() => props.onClose(false)}>
          {props.cancelButtonTitle || 'Cancel'}
        </Button>
        <Button variant="contained" color={props.okButtonColor || "error"} onClick={() => {
          props.onClose(value);
          setValue(true)
        }}>
          {props.okButtonTitle || 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
}
