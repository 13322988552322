import {useLocation, useParams} from "react-router-dom";
import {Box, Button, Link, Typography} from "@mui/material";
import {DateTime, Duration} from "luxon";
import React from "react";
import {usePublicMeetingQuery} from "../../api/sdl";
import InfoCard from "../components/InfoCard";
import Loading from "../components/Loading";

export default function MeetingCardPage() {
  const {meetingId} = useParams();
  const location = useLocation();
  const v2 = location.pathname.includes('interview-meeting-v2');

  const {data: meetingData} = usePublicMeetingQuery({
    variables: {meetingId},
    skip: !v2
  });

  const meetingScheduledOn = meetingData?.publicMeeting.meetingScheduledOn;
  const meetingLink = meetingData?.publicMeeting.meetingLink;

  const date = meetingScheduledOn ? DateTime.fromISO(meetingScheduledOn) : DateTime.now();

  return <Loading in={!meetingLink}>
    <Box sx={{display: 'flex', justifyContent: 'center', pt: '25vh'}}>
      {meetingLink && (
        <InfoCard
          sx={{maxWidth: 720}}
          title={<MeetingCardTitle date={date}/>}
          content={<MeetingCardContext link={meetingLink}/>}
        />
      )}
    </Box>
  </Loading>
}
const MeetingCardTitle = ({date}: { date: DateTime }) => {

  return <>
    <Typography variant={'body1'} fontWeight={600}>Date: {date.toFormat('LLLL d')}</Typography>
    <Typography variant={'body1'} fontWeight={600}>Time: {date.toFormat('hh:mm a')} (in {toHuman(date.diff(DateTime.now(), ["years", "months", "days", "hours"]))})</Typography>
  </>
}

const MeetingCardContext = (props: { link: string }) => {
  const onClick = () => {
    document.location.href = props.link
  }

  return <Box>
    <Box sx={{textAlign: 'center'}}>
      <Typography sx={{textAlign: 'left'}}>
        This is a three-way meeting between the account manager, the candidate and the company representative.<br/><br/>
        Please use the button below to join the call or use the following link: <Link href={props.link}>{props.link}</Link>
      </Typography>
    </Box>
    <Box sx={{textAlign: 'right'}}>
      <Button onClick={onClick} sx={{mt: 2}} variant={'contained'} color={'primary'}>Join the meeting</Button>
    </Box>
  </Box>
    ;
}

function toHuman(dur: Duration, smallestUnit = "hours"): string {
  const units: any = ["years", "months", "days", "hours", "minutes", "seconds", "milliseconds",];
  const smallestIdx = units.indexOf(smallestUnit);
  const entries = Object.entries(
    dur.shiftTo(...units).normalize().toObject()
  ).filter(([_unit, amount], idx) => amount > 0 && idx <= smallestIdx);
  const dur2 = Duration.fromObject(
    entries.length === 0 ? {[smallestUnit]: 0} : Object.fromEntries(entries)
  );
  return dur2.toHuman();
}