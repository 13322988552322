import React from "react";
import {Box, IconButton, SxProps} from "@mui/material";
import {CaretLeft, CaretRight} from "phosphor-react";
import CandidateCard from "./CandidateCard";
import {expectedSalaryFromPositionMatch} from "../../../../shared";
import {Candidate} from "../lib/types";

type Props = {
  candidates: Candidate[],
  onCardClick: (index: number) => void,
  active?: string
}

const CandidateCarousel = ({candidates, active, onCardClick}: Props) => {
  const [offset, setOffset] = React.useState(0);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);
  const containerRef = React.useRef();
  const wrapperRef = React.useRef();

  const handleCardClick = (e: any, index: number) => {
    const card = e.target.closest('.MuiCard-root');

    if (containerRef.current && card) {
      const elOffset = card.offsetLeft + Math.floor(card.clientWidth / 2);

      const containerMiddle = Math.floor((containerRef.current as any).clientWidth / 2)

      let result = -elOffset + containerMiddle;

      if (result > 0) {
        result = 0;
      }

      setOffset(result)
    }
    onCardClick(index)
  }

  React.useEffect(() => {
    setWrapperWidth((wrapperRef.current as any)?.clientWidth);
  }, [(wrapperRef.current as any)?.clientWidth])

  const showNextButton = React.useMemo(() => {
    const containerWidth = (containerRef.current as any)?.clientWidth || 0;
    const wrapperWidth = (wrapperRef.current as any)?.clientWidth || 0;

    return wrapperWidth + offset >  containerWidth
  }, [offset, wrapperRef, containerRef, wrapperWidth])

  return <Box ref={containerRef} sx={{...styles.wrapper, '&::before': {width: offset < 0 ? 40 : 0}}}>
    {offset < 0 && <IconButton sx={{position: 'absolute', left: 0, top: 28, zIndex: 20, background: '#fff', borderRadius: '50%'}} onClick={() => setOffset(prev => prev + 300 > 0 ? 0 : prev + 300)}>
      <CaretLeft size={18} color="#2C92D5" weight="regular" />
    </IconButton>}

    {showNextButton && <IconButton sx={{position: 'absolute', right: 0, top: 28, zIndex: 20, background: '#fff', borderRadius: '50%'}} onClick={() => setOffset(offset - 300)}>
      <CaretRight size={18} color="#2C92D5" weight="regular" />
    </IconButton>}

    <Box ref={wrapperRef} sx={{display: 'flex', gap: '20px', position: 'absolute', left: offset, transition: 'all 0.3s'}}>
      {candidates.map((c, i) => (
        <CandidateCard key={i}
                       status={c.status!!}
                       displayName={c.displayName}
                       salary={expectedSalaryFromPositionMatch(c)}
                       location={formatLocation(c)}
                       onClick={(e: any) => handleCardClick(e, i)}
                       active={!!active && active === c.contractorId}/>
      ))}
    </Box>
  </Box>
}

export default CandidateCarousel;

const formatLocation = (c: Candidate) => (
    c.contractorProfile.cities.map( (city) => `${city.city}, ${city.stateShort}`).join("; ")
)

const styles: {[key: string]: SxProps} = {
  wrapper: {
    mt: 4,
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      background: 'linear-gradient(to left, #fff 0%, rgba(252,252,252,0) 100%)',
      content: "''",
      height: 1,
      position: 'absolute',
      width: 40,
      zIndex: 2,
      right: 0,
    },
    '&::before': {
      background: 'linear-gradient(to right, #fff 0%, rgba(252,252,252,0) 100%)',
      content: "''",
      height: 1,
      position: 'absolute',
      zIndex: 2,
    },
    width: 1,
    height: 115
  }
}
