import {
  useFutureMeetingsLazyQuery,
} from "../../../../api/sdl";
import React from "react";
import {MeetingDetailsItem} from "../../../../entity";

export default function useMeetingsByPositionId(positionId?: string) {
  const [getMeetings, data] = useFutureMeetingsLazyQuery();

  React.useEffect(() => {
    if (positionId) {
      getMeetings({variables: {positionId}})
    }
  }, [positionId])

  const meetings: MeetingDetailsItem[] = React.useMemo(() => {
    return data?.data?.futureMeetings || [];
  }, [data])

  return meetings;
}
