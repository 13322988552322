import {useMigrateAutoAttributesMutation, useMigratePositionMatchMutation} from "../../../api/sdl";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {enqueueSnackbar} from "notistack";

export function MigrateAttributesButton() {
    const [migrate, migrateData] = useMigrateAutoAttributesMutation({onCompleted:
          (e) => enqueueSnackbar(e.migrateAutoAttributes, {variant: 'success'})
    });

    return <LoadingButton onClick={() => migrate()} loading={migrateData.loading} variant={"contained"}>
        Migrate attributes
    </LoadingButton>
}