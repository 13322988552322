import {
  AttributeCategory,
  SalaryRangeType, useSaveContractorSalaryExpectationMutation,
  useSaveProfileAttributeMutation
} from "../../../api/sdl";
import {FormValues} from "../ui/current-expirience/lib/types";

export default function useSaveCurrentExperienceApi(contractorId?: string) {
  const [saveSalary, {loading}] = useSaveContractorSalaryExpectationMutation();

  const [saveAttributes, {loading: attributesLoading}] = useSaveProfileAttributeMutation();

  const save = async (values: FormValues) => {
    await saveSalary({
      variables: {
        contractorProfileId: contractorId,
        payload: {
          salaryRangeMax: parseFloat(values.salaryRangeMax),
          salaryRangeMin: parseFloat(values.salaryRangeMin),
          salaryRangeType: convertSalaryRangeType(values.salaryRangeType),
        }
      }
    })
    await saveAttributes({
      variables: {
        contractorProfileId: contractorId,
        payload: [{
          category: AttributeCategory.RoleCategory,
          ids: values.roles
        }, {
          category: AttributeCategory.CallPointSpeciality,
          ids: values.specialities
        }]
      }
    });
  }

  return {save, loading: loading || attributesLoading}
}

function convertSalaryRangeType(value: string) {
  if(value === '') return null;

  return value as SalaryRangeType
}