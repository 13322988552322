import {usePositionMatchQuery} from "../../../api/sdl";
import {useEffect, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {MeetingListItem, PositionMatchApiData} from "../lib/types";

export function usePositionMatchApi({positionMatchId, meetingId}: { positionMatchId: string, meetingId?: string }) {
  /**
   * @deprecated, use positionMatch instead
   */
  const [meetings, setMeetings] = useState<MeetingListItem[]>()
  const [foundMeeting, setFoundMeeting] = useState<MeetingListItem>()
  const [positionMatch, setPositionMatch] = useState<PositionMatchApiData>( )


  useEffect(() => {
    const meeting = meetings?.find((m: any) => m.id === meetingId)

    setFoundMeeting(meeting as MeetingListItem)
  }, [meetingId]);
  const {loading, refetch} = usePositionMatchQuery({
    variables: {positionMatchId},
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const meetings = convertMeetingsFromApiData(data.positionMatch.meetings)


      const meeting = meetings.find((m: any) => m.id === meetingId)

      setFoundMeeting(meeting as MeetingListItem)
      setMeetings(meetings)
      setPositionMatch({...data.positionMatch, meetings: meetings})
    }
  },)

  return {
    meetings, loading, refetch, meeting: foundMeeting, positionMatch
  }
}

export function convertMeetingsFromApiData(meetings: any) {

  return meetings.map( (m: any) => ({
    ...m, meetingScheduledOn: DateTime.fromISO(m.meetingScheduledOn)
  }) as MeetingListItem)
}