import {useSetStripeAccountMutation} from "../../../api/sdl";
import {enqueueSnackbar} from "notistack";
import {useConfirm} from "../../../shared/ConfirmDialogContext";

export default function useSetStripeAccount(onSave: () => void, contractorId?: string) {
  const [setStripeAccount, {loading}] = useSetStripeAccountMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar(' Stripe account was saved successfully', {variant: 'success'});
    },
  });
  const confirm = useConfirm();

  const save = (stripeAccountId: string) => confirm({
    content: 'Are sure you want to set new Stripe account ID?',
    okButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
    width: 500
  }).then(val => val && setStripeAccount({variables: {stripeAccountId, contractorId}}).then(() => {
    onSave();
  }));

  return {save, loading};
}