import {Box, Button} from "@mui/material";
import React from "react";
import {Navigate, useNavigate} from "react-router-dom";
import CreateContractorForm from "../../../shared/components/CreateContractorForm";


export default function CreateContractor() {
  const navigate = useNavigate();
  const [profileId, setProfileId] = React.useState<string>();


  if(profileId) {
    return <Navigate to={`/admin/contractor/${profileId}`} />
  }

  return <Box sx={{position: 'relative', minHeight: '50vh'}}>
    <Button variant={'text'} sx={{mb: 3}} color={'primary'}
            onClick={() => navigate('../contractors')}>Back</Button>
    {!profileId && <CreateContractorForm onCompleted={data => setProfileId(data.contractorProfileId)}/>}
  </Box>
}
