import {Editable} from "../../../../shared";
import React from "react";
import PersonalSummaryView from "./ui/PersonalSummaryView";
import PersonalSummaryForm from "./ui/PersonalSummaryForm";
import useSaveProfileApi from "../../api/use-save-profile-api";
import {useCopyPersonalSummaryFromResumeMutation} from "../../../../api/sdl";

enum DisplayMode {
  View,
  Edit,
  UpdateFormPdf
}

interface Props extends Editable {
  personalSummary?: string | null,
  contractorId?: string,
  updatableFromCv: boolean,
  onUpdate?: () => void,
}

export function PersonalSummary(props: Props) {
  const {personalSummary, contractorId, editable, onUpdate, updatableFromCv} = props;

  const {save, loading} = useSaveProfileApi(contractorId);
  const [copyFromResume] = useCopyPersonalSummaryFromResumeMutation({variables: {contractorId}})

  const [displayMode, setDisplayMode] = React.useState(DisplayMode.View);

  const onSubmit = async (values: {personalSummary: string}) => {
    await save(values);
    setDisplayMode(DisplayMode.View);
    onUpdate && onUpdate();
  }

  const handleCopyFromResume = async () => {
    await copyFromResume();
    !!props.onUpdate && props.onUpdate()
  }

  return <>
    {displayMode === DisplayMode.View && (
      <PersonalSummaryView showUpdateButton={updatableFromCv}
                           personalSummary={personalSummary}
                           onEdit={() => setDisplayMode(DisplayMode.Edit)}
                           editable={editable}
                           onUpdateClick={handleCopyFromResume} />
    )}

    {displayMode === DisplayMode.Edit && (
      <PersonalSummaryForm onSubmit={onSubmit}
                           personalSummary={personalSummary}
                           loading={loading}
                           onBackClick={() => setDisplayMode(DisplayMode.View)} />
    )}

  </>
}
