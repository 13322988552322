import {CompositeAnswerApiData} from "../lib/types";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography} from "@mui/material";
import {ExpandMoreIcon} from "./ExpandMoreIcon";
import {Editable, getFilesUrl} from "../../../../../shared";
import React from "react";

interface Props extends Editable{
  item: CompositeAnswerApiData,
  onRecordClick: () => void,
}

export function VideoAnswer({item, onRecordClick, editable}: Props) {

  const data = item.videoAnswer

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onRecordClick()
  }
  return <Accordion sx={{
    boxShadow: 'none', // Remove elevation (box-shadow)
    border: '1px solid #ECEFEF', // Add custom border
    borderRadius: '4px', // Optional: Customize border radius
  }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <Stack direction={"row"} sx={{width: 1}}>
        <Box component={'img'} src={`/img/teenyicons/play-circle.svg`} width={22} height={22}
             sx={{margin: 1, marginTop: '4px'}}/>
        <Box sx={{width: 1}}>
          <Typography variant={"body1"}>{item.title}</Typography>
        </Box>
        {editable &&
          <Box sx={{m: 1}}>
            <Button onClick={handleClick} variant={'outlined'} size={"small"}>{!!data ? "Replace":"Record"}</Button>
          </Box>
        }

      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Box flex={3}>
        {data && (
          <Box component={'video'}
               controls sx={{width: 1, borderRadius: '16px'}}
               autoPlay={false}
               src={getFilesUrl(data.fileStorage) + "/" + data.url}
               style={{display: 'block'}}/>
        )}
        {!data && (
          <Typography variant={"body1"} textAlign={"center"} >No video has been recorded yet.</Typography>
        )}
      </Box>
    </AccordionDetails>
  </Accordion>
}