import {useContractorAttributePageQuery} from "../../api/sdl";
import React from "react";
import * as _ from "lodash";

export default function useContractorAttributes() {
  const contractorAttributeData = useContractorAttributePageQuery({
    variables: {
      page: {
        size: 10000,
        page: 0
      }
    },
  });

  const attributes = React.useMemo(() => {
    return _.orderBy(contractorAttributeData.data?.contractorAttributePage.data || [], 'category')
  }, [contractorAttributeData.data])

  return {attributes, loading: contractorAttributeData.loading};
}
