import {
  useGenerateBalanceReportMutation,
  useGetTemporaryLinkToReportMutation,
  useStripeBalancedReportsQuery
} from "../../api/sdl";
import {BalanceFormValue, BalanceReportRequestForm} from "./ui/BalanceReportRequestForm";
import {Box, Button, Typography} from "@mui/material";
import {BalanceReportLine} from "./ui/BalanceReportLine";
import React, {useState} from "react";




export function BalanceReportsPage() {
  const {data: reports, refetch} = useStripeBalancedReportsQuery();
  const [showForm, setShowForm] = useState<boolean>(false)

  const [generateLink] = useGetTemporaryLinkToReportMutation();

  const [generateReport] = useGenerateBalanceReportMutation();
  async function handleFormSubmit(values: BalanceFormValue) {

    setShowForm(false)
    await generateReport({variables: {start: values.startDate, end: values.endDate}});
    await refetch()
  }

  function handleDownloadClick(reportId: string) {
    generateLink({variables: {reportId}, onCompleted: data => window.open(data.getTemporaryLinkToReport)})
  }

  function switchView() {
    setShowForm(v => !v)
  }

  return <Box sx={{maxWidth: "1200px", gap: 3, display: 'flex', flexDirection: "column", margin: '30px 30px 100px 60px'}}>
    <Box sx={{display: 'flex', gap: 2, alignItems: "center", justifyContent: "space-between"}}>

      <Typography variant={'h3'} sx={{ml: 2}}>Payment history reports</Typography>
      <Box>
        <Button variant={"contained"} onClick={switchView}>Request new report</Button>
      </Box>
    </Box>
    {!showForm &&
      <Box sx={{gap: 2, display: 'flex', flexDirection: "column"}}>
        {reports?.stripeBalancedReports.map(report =>
          <BalanceReportLine
            key={report.id}
            item={report}
            onClick={() => handleDownloadClick(report.id)}/>
        )}
      </Box>
    }

    {showForm &&
        <>
          {/*<Typography variant={'h3'} sx={{ml: 2}}>Request new report</Typography>*/}
          <BalanceReportRequestForm onSubmit={handleFormSubmit} onCancel={switchView}/>
        </>
    }

  </Box>
}
