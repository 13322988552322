import {
  PositionStatus,
} from "../../../../api/sdl";
import React from "react";
import {MenuItem, Select} from "@mui/material";
import {PositionStatusApiCalls} from "../hooks/usePositionStatusApi";

interface Props {
  positionStatus?: PositionStatus,
  positionId?: string,
  api: PositionStatusApiCalls

}
const PublishMenu = ({positionId, positionStatus, api}: Props) => {
  const [status, setStatus] = React.useState<PositionStatus>(PositionStatus.Draft);

  React.useEffect(() => {
    if (positionStatus) {
      setStatus(positionStatus)
    }
  }, [positionStatus])

  const handleStatusChange = React.useCallback((newStatus: PositionStatus) => {
    if (status !== newStatus) {
      setStatus(newStatus)
      switch (newStatus) {
        case PositionStatus.Open:
          api.publish();
          break;
        case PositionStatus.Draft:
          api.unpublish();
          break;
        case PositionStatus.Archived:
          api.archive();
          break;
      }
    }
  }, [status])

  return <Select data-test='role-status' value={status} onChange={(e) => handleStatusChange(e.target.value as PositionStatus)}>
    <MenuItem value={PositionStatus.Draft}>Draft</MenuItem>
    <MenuItem value={PositionStatus.Open}>Published</MenuItem>
    <MenuItem value={PositionStatus.Archived}>Archived</MenuItem>
  </Select>
}

export default PublishMenu;
