import {Box, Typography} from "@mui/material";
import React, {useMemo} from "react";
import {DateTime} from "luxon";
import {CalendarSlot} from "../../api/sdl";
import CalendarTableHeader from "./ui/CalendarTableHeader";
import {CalendarRow, GridCoordinate} from "./ui/CalendarRow";
import {CalendarUtil} from "./lib/CalendarUtil";


export interface ClickableCalendarTableProps {
  slots: CalendarSlot[],
  startDate: DateTime,
  onClick: (date: DateTime) => void,
  selectedDate?: DateTime,
}
/*
const slots = [
  {
    "date": "2024-04-02T18:00:00Z",
    "status": "AVAILABLE",
    "properties": [],
    "__typename": "CalendarSlot"
  } as CalendarSlot
];*/
export default function ClickableCalendarTable({slots, startDate, onClick, selectedDate}: ClickableCalendarTableProps) {

  const range = CalendarUtil.range(13, 7);

  const availabilityCoords = useMemo(() => {
    return CalendarUtil.availability(startDate, slots)
  }, [startDate, slots]);

  const meetings = useMemo(() => {
    return CalendarUtil.meetings(startDate, slots)
  }, [startDate, slots]);

  const selectedCoord = React.useMemo(() => {
    if(!selectedDate) return undefined;

    return CalendarUtil.currentlySelected(startDate, selectedDate);
  }, [startDate, selectedDate]);

  const onCellClick = (coord: GridCoordinate) => {
    const dateTime = CalendarUtil.dateTimeFromGridCoordinate(startDate, coord)
    if(dateTime) {
      onClick(dateTime!!)
    }
  };

  return <>
    <Box data-test='calendar' sx={{overflowY: 'auto', width: 1}}>
      <Typography sx={{textAlign: 'right', mr: 2, mb: 2}}>Time zone: <b>{DateTime.now().zoneName}</b></Typography>
      <Box component={'table'} cellSpacing={5} sx={{width: 1, '& td': {textAlign: 'center'}}}>
        <CalendarTableHeader startDate={startDate}/>
        <tbody>
          {range.map((c) =>
            <CalendarRow key={c}
                         availabilityCoords={availabilityCoords}
                         selectedCoord={selectedCoord}
                         rowNumber={c}
                         meetings={meetings}
                         onCellClick={onCellClick}/>
          )}
        </tbody>
      </Box>
    </Box>
  </>
}
