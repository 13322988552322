import {DateTime} from "luxon";

export function convertToApi(values: any, locations: any) {
  const data: any = values;
  const {specialities, roles, preferableStartDate, expectedContractLengths, hoursAreRange, salaryIsRange, ...payload} = data;
  payload.attributes = [...specialities, ...roles, ...expectedContractLengths ];
  payload.preferableStartDate = DateTime.fromJSDate(preferableStartDate).toFormat('yyyy-LL-dd');
  payload.locations = locations || [];
  payload.salaryRangeMin = data.salaryRangeMin === "" ? undefined : parseInt(data.salaryRangeMin);
  payload.salaryRangeMax = data.salaryRangeMax === "" ? undefined : parseInt(data.salaryRangeMax);
  payload.hoursPerWeekMax = data.hoursPerWeekMax === "" ? undefined : parseInt(data.hoursPerWeekMax);
  payload.hoursPerWeekMin = data.hoursPerWeekMin === "" ? undefined : parseInt(data.hoursPerWeekMin);
  payload.commissionBonus = data.commissionBonus === "" ? undefined : data.commissionBonus;
  delete payload.hoursAreRange;
  delete payload.salaryIsRange;

  return payload;
}