import {SalaryRangeType} from "../../api/sdl";
import {FormHelperText, MenuItem, Select} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/material/";

type Props = {
  onChange: (val: string) => void,
  value: string,
  error?: string,
  sx?: SxProps,
}

export default function SalaryRangeTypeSelect(props: Props) {
  return <>
    <Select data-test='salary-type' value={props.value}
            sx={props.sx}
            onChange={(e) => {
      props.onChange(e.target.value)
    }}>
      <MenuItem value={''}>-</MenuItem>
      <MenuItem value={SalaryRangeType.Hourly}>Hourly</MenuItem>
      <MenuItem value={SalaryRangeType.Monthly}>Monthly</MenuItem>
      <MenuItem value={SalaryRangeType.Yearly}>Yearly</MenuItem>
    </Select>
    {props.error && <FormHelperText sx={{color: '#D3232F'}}>{props.error}</FormHelperText>}
  </>
}
