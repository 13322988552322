import React, {PropsWithChildren} from "react";
import {Button} from "@mui/material";

const LayoutTypeButton = ({active, onClick, children}: PropsWithChildren<{active: boolean, onClick: () => void}>) => (
  <Button sx={{
    backgroundColor: active ? '#000!important' : '#fff!important',
    borderRadius: '8px',
    height: 43,
    minWidth: 0,
    border: `1px solid ${!active ? 'rgb(205, 215, 225)' : '#000'}`,
  }}
          variant="contained"
          color={'secondary'}
          size={'small'}
          onClick={onClick}>
    {children}
  </Button>
)

export default LayoutTypeButton;
