import {AttributeCategory, PositionOutput} from "../../../api/sdl";
import {LayoutType} from "../../../shared/types";
import {Box, Button, Chip, Typography} from "@mui/material";
import {DateTime} from "luxon";
import {showRange, toCurrency} from "../../../shared/utils";
import * as _ from "lodash";
import React from "react";
import {formatLocation} from "../lib/util";

const PositionItem = ({position, onViewDetailsClick, confirmed, view}: {position: PositionOutput, view: LayoutType, onViewDetailsClick: () => void, confirmed?: boolean}) => {
  return <Box sx={{
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: view === 'grid' ? 'column' : 'row',
    borderRadius: '8px',
    border: '1px solid #E4E4E4',
    p: '24px 32px',
  }}>

    <div>
      <Box sx={{display: 'flex', flexDirection: view === 'grid' ? 'column-reverse' : 'row', alignItems: view === 'grid' ? 'flex-start' : 'center',  gap: view === 'grid' ? 2 : 1}}>
        <Typography sx={{color: '#2C92D5', fontWeight: 600, fontSize: 20, cursor: 'pointer'}} onClick={onViewDetailsClick}>{position.title}</Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
          <Chip size={'small'}
                label={position.attributesExpanded?.find(a => a.category === AttributeCategory.RoleCategory)?.attribute}
                sx={{background: '#fff', border: '1px solid #B0B3B2', color: 'text.secondary'}}
          />
          <Typography variant={'body2'}>Posted on {DateTime.fromISO(position.createdAt).toFormat('LLL dd')}</Typography>
        </Box>
      </Box>
      <Box sx={{display: 'flex', flexWrap:'wrap', flex:'100px', gap: {xs:2 ,md:3}, mt: '10px', mb: view === 'grid' ? 3 : 0}}>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Box component={'img'} src={`/img/teenyicons/pin.svg`}  width={15} height={15} />
          <Typography variant={'body2'}>{position.locations.map(l => formatLocation(l)).join('; ')}</Typography>
        </Box>
{/*        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Box component={'img'} src={`/img/teenyicons/briefcase_gray.svg`}  width={15} height={15} />
          <Typography variant={'body2'}>{position.attributesExpanded.filter(a => a.category === AttributeCategory.EmploymentType).map(a => a.attribute).join(', ')}</Typography>
        </Box>*/}
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Box component={'img'} src={`/img/teenyicons/money.svg`}  width={15} height={15} />
          <Typography variant={'body2'}>{showRange(position?.salaryRangeMin, position?.salaryRangeMax, undefined, undefined, toCurrency)} {!!position?.salaryRangeType && _.startCase(_.toLower(position?.salaryRangeType))}</Typography>
        </Box>
      </Box>
    </div>

    <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between', alignItems: 'center'}}>
      <Typography variant={'body2'}>{position.preferableStartDate && 'Preferable date of start ' + DateTime.fromFormat(position.preferableStartDate, 'yyyy-LL-dd').toFormat('LLL dd, yyyy')}</Typography>
      <Button sx={{minWidth:'118px'}} variant={'contained'} onClick={onViewDetailsClick}>View details</Button>
    </Box>

  </Box>
}

export default PositionItem;