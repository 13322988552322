import {Box, Button, Typography, Menu, MenuItem} from "@mui/material";
import React from "react";

import * as _ from "lodash";
import {CaretDown} from "phosphor-react";
import {
  ContractorStatus,
  useApproveContractorMutation,
  useDeclineContractorMutation
} from "../../api/sdl";
import {ContractorProfile} from "../../entity/contractor";


export default function ContractorApprovement({profile, onChange}: {profile: ContractorProfile, onChange: () => void}) {
  const approveButtonRef = React.useRef(null);
  const declineButtonRef = React.useRef(null);

  const [approve] = useApproveContractorMutation({onCompleted: onChange});
  const [decline] = useDeclineContractorMutation({variables: {id: profile.contractorId}, onCompleted: onChange});

  const [approveOpen, setApproveOpen] = React.useState(false);
  const [declineOpen, setDeclineOpen] = React.useState(false);

  const onApproveSelect = (status?: ContractorStatus) => {
    setApproveOpen(false);
    if (status) {
      approve({variables: {id: profile.contractorId, status}})
    }
  }

  return <Box>
    <Typography variant={'body1'} data-test='current-status' color={getStatusColor(profile.status)}>
      <b>{_.lowerCase(profile.status).toUpperCase()}</b>
    </Typography>

    <Box sx={{display: 'flex', mt: 2, gap: 2, alignItems: 'center'}}>
      <Button color={'error'}
              fullWidth
              variant={'outlined'}
              ref={declineButtonRef}
              endIcon={<CaretDown size={16} color="#D3232F" weight="bold" />}
              onClick={() => setDeclineOpen(!declineOpen)}>Decline</Button>
      <Menu
        anchorEl={declineButtonRef.current}
        open={declineOpen}
        onClose={() => setDeclineOpen(false)}
      >
        <MenuItem onClick={() => {
          onApproveSelect(ContractorStatus.Archived)
          setDeclineOpen(false)
        }}>Archive</MenuItem>
        <MenuItem onClick={() => {
          decline();
          setDeclineOpen(false)
        }}>Decline</MenuItem>
      </Menu>

      <Button color={'success'}
              fullWidth
              variant={'outlined'}
              data-test='approve-button'
              ref={approveButtonRef}
              sx={{color: '#1a7d36'}}
              endIcon={<CaretDown size={16} color="#1a7d36" weight="bold" />}
              onClick={() => setApproveOpen(!approveOpen)}>Approve</Button>
      <Menu
        anchorEl={approveButtonRef.current}
        open={approveOpen}
        onClose={() => onApproveSelect()}
      >
        <MenuItem data-test='pre-approved' onClick={() => onApproveSelect(ContractorStatus.Pending)}>Initial Form</MenuItem>
        <MenuItem data-test='pre-approved' onClick={() => onApproveSelect(ContractorStatus.OnboardingCompleted)}>Application Sent</MenuItem>
        <MenuItem data-test='pre-approved' onClick={() => onApproveSelect(ContractorStatus.PreApprovedInterview)}>Pre Approved</MenuItem>
        <MenuItem data-test='filling-video' onClick={() => onApproveSelect(ContractorStatus.FillingVideo)}>Filling video</MenuItem>
        <MenuItem data-test='filling-assessment' onClick={() => onApproveSelect(ContractorStatus.FillingAssessment)}>Filling assessment</MenuItem>
        <MenuItem data-test='approve' onClick={() => onApproveSelect(ContractorStatus.Approved)}>Approve</MenuItem>
      </Menu>
    </Box>
  </Box>
}


const getStatusColor = (status?: ContractorStatus) => {
  switch (status) {
    case ContractorStatus.Approved: return '#2e7d32';
    case ContractorStatus.Declined: return 'error';
    default: return 'warning'
  }
}
