import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "@apollo/client/link/error";
import config from "./config";
import {keycloak} from "./keycloak";


export const setErrorHandler = (callback: ({ graphQLErrors, networkError }: any) => void) => errorHandler = callback;

let errorHandler: ({ graphQLErrors, networkError }: any) => void;

const errorLink = onError((errors: any) => {
  if (errorHandler) {
    errorHandler(errors);
  }
});

export const getToken = () => {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch(() => {
        reject();
      })
  });
}

const customFetch = async (uri: string, options: any) => {
  const token = await getToken().catch(e => {
    // throw new Error('Update token failed');
  });
  options.headers.Authorization = token ? `Bearer ${token}` : "";
  return fetch(uri, options);
};

const httpLink = createUploadLink({
  uri: `${config.apiUrl}/graphql`,
  fetch: customFetch
});
/*
export const getToken = () => {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch(() => {
        reject();
      })
  });
}

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(e => {
    throw new Error('Update token failed');
  });
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    }
  };
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;
*/

export const uploadClient = new ApolloClient({
  link: from([errorLink, httpLink as any]),
  cache: new InMemoryCache(),
});

/*
export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

*/