import React, {useEffect, useState} from "react";
import {useAdminParseCvMutation} from "../../../api/sdl";
import {Box, Button, LinearProgress} from "@mui/material";
import {useAffindaStatusApi} from "../api/useAffindaStatusApi";

interface Props {
  contractorId?: string,
  onCompleted?: () => void
}

export function ParseResumeButton({contractorId, onCompleted}: Props) {
  const [loading, setLoading] = useState(false);
  const {error: integrationError, loading: affindaLoading, getAffindaStatus} = useAffindaStatusApi(contractorId, onCompleted);
  const [parseCv] = useAdminParseCvMutation({onCompleted: () => getAffindaStatus().finally(() => setLoading(false))});

  useEffect(() => {
    setLoading(affindaLoading);
  }, [affindaLoading]);

  return <Box sx={{alignItems: 'center'}}>
    { !loading ?
      <>
        <Button
          sx={{width:1}}
          onClick={() => {
            setLoading(true);
            parseCv({variables: {
                contractorProfileId: contractorId
              }});
          }}
          variant="outlined"
          color="primary"
        >
          Parse Resume
        </Button>
        {!!integrationError && (
          <Box sx={{ margin: '4px', color: '#D3232F', fontSize: '12px'}}>{integrationError}</Box>
        )}
      </>
      :

      <LinearProgress />
    }
  </Box>
}
