import {GridCoordinate} from "../ui/CalendarRow";
import {DateTime} from "luxon";
import {CalendarSlot, CalendarSlotStatus} from "../../../api/sdl";
import {CalendarCell, CalendarInternalStatus, MeetingSlotCoords} from "./types";

export const CalendarUtil = {
  dateFromGridCoordinate(startDate: DateTime, coord: GridCoordinate) {

    return startDate.plus({day: coord.column, hour: coord.row}).toUTC().toISO() || '';
  },
  dateTimeFromGridCoordinate(startDate: DateTime, coord: GridCoordinate) {

    return startDate.plus({day: coord.column, hour: coord.row});
  },

  gridCoordinateFromCalendarSlot(startDate: DateTime, slot: CalendarSlot) {
    const diff = DateTime.fromISO(slot.date).diff(startDate, ["days", "hours"]).toObject()
    return {row: diff.hours, column: diff.days, slot: slot}
  },

  availability(startDate: DateTime, slots: CalendarSlot[]) {
    return slots
        .map((slot) => CalendarUtil.gridCoordinateFromCalendarSlot(startDate, slot))
        .filter((it) => it.slot.status === CalendarSlotStatus.Available)
        .map((it) => ({row: it.row, column: it.column} as GridCoordinate))
  },

  meetings(startDate: DateTime, slots: CalendarSlot[]) {
    return slots
        .map((slot) => CalendarUtil.gridCoordinateFromCalendarSlot(startDate, slot))
        .filter((it) => it.slot.status === CalendarSlotStatus.Meeting) as MeetingSlotCoords[]
  },

  currentlySelected(startDate: DateTime, selectedDate: DateTime) {
    const diff = selectedDate.diff(startDate, ["days", "hours"]).toObject()
    return {row: diff.hours, column: diff.days, slot: {
        status: CalendarInternalStatus.SELECTED
      }} as MeetingSlotCoords
  },

  exists(selectedCoords: GridCoordinate[], coord: GridCoordinate) {
    return !!selectedCoords.find((s) => s.row === coord.row && s.column === coord.column)
  },

  range(size: number, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
  },

  formatTime(row: number) {
    if (row === 12) return "12:00 PM"
    if (row < 12) return `${row.toString().padStart(2, '0')}:00 AM`
    if (row > 12) return `${(row - 12).toString().padStart(2, '0')}:00 PM`
  }
}