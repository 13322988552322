import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, FormHelperText,
  FormLabel,
  TextField,
  IconButton
} from "@mui/material";
import React from "react";
import {X} from "phosphor-react"
import {useFormik} from "formik";
import * as yup from "yup";
import {GuestPerson} from "../../../api/sdl";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (data: GuestPerson) => void;
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
})

export function AddGuestDialog(props: Props) {

  const formik = useFormik<GuestPerson>({
    initialValues: {name: '', email: ''},
    validationSchema,
    onSubmit: (values) => {
      if (formik.isValid) {
        props.onSave(values);
      }
    },
    validateOnChange: true,
  });

  return <Dialog open={props.open}
                 PaperProps={{
                   component: 'form',
                   onSubmit: formik.handleSubmit,
                 }}
                 data-test={'add-guest-dialog'}
                 onClose={props.onClose} >
    <DialogTitle sx={{display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between'}}>
      Add guest
      <IconButton onClick={props.onClose} >
        <X size={36}/>
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{width: '400px'}}>
      <FormControl sx={{mt: 2, width: 1}}>
        <FormLabel>Full Name</FormLabel>
        <TextField placeholder="Enter name"
                   name="name"
                   value={formik.values.name}
                   onChange={formik.handleChange}
                   error={formik.touched.name && Boolean(formik.errors.name)}
                   data-test={'add-guest-dialog-name'}
        />
        {(formik.touched.name && formik.errors.name) && <FormHelperText data-test="title-error" sx={{color: '#D3232F'}}>{formik.errors.name}</FormHelperText>}
      </FormControl>

      <FormControl sx={{mt: 2, width: 1}}>
        <FormLabel>Email</FormLabel>
        <TextField placeholder="Enter email"
                   name="email"
                   value={formik.values.email}
                   onChange={formik.handleChange}
                   error={formik.touched.email && Boolean(formik.errors.email)}
                   type={'email'}
                   data-test={'add-guest-dialog-email'}
        />
        {(formik.touched.email && formik.errors.email) && <FormHelperText data-test="title-error" sx={{color: '#D3232F'}}>{formik.errors.email}</FormHelperText>}
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button variant="text"
              data-test="add-guest-dialog-cancel"
              color="secondary"
              onClick={props.onClose}>
        Cancel
      </Button>
      <Button data-test="add-guest-dialog-save"
              type={'submit'}
              variant="contained">
        Save
      </Button>
    </DialogActions>
  </Dialog>
}