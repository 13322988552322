import {useNavigate} from "react-router-dom";
import {Box, Button, Container, MenuItem, Select, SxProps, Typography} from "@mui/material";
import React, {useContext} from "react";
import {
  PositionOrder, PositionOutput,
  PositionStatus, useMyPositionsLazyQuery,
} from "../../api/sdl";
import {ProfileContext} from "../../shared/ProfileContext";
import {SquaresFour, PlusCircle, CaretDown} from "phosphor-react";
import Loading from "../../shared/components/Loading";
import * as _ from 'lodash';
import {DateTime} from "luxon";
import {LayoutType} from "../../shared/types";
import {showRange, toCurrency} from "../../shared/utils";
import LayoutTypeButton from "../../shared/components/LayoutTypeButton";
import {KeycloakContext} from "../../keycloak";
import {formatLocation} from "../../entity/position/lib/util";

interface PositionFilter {
  status?: PositionStatus | 'all',
  orderBy?: PositionOrder
}

function Positions() {
  const navigate = useNavigate();
  const [profile] = React.useContext(ProfileContext);
  const [filter, setFilter] = React.useState<PositionFilter>({
    status: 'all',
    orderBy: PositionOrder.Recent
  });
  const [getPositions, {loading, called}] = useMyPositionsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      /*if (!data?.myPositions.data.length) {
        navigate('/position')
      }*/
    }
  });
  const [positions, setPositions] = React.useState<PositionOutput[]>([]);
  const {initialized, keycloak} = useContext(KeycloakContext);
  const [layoutType, setLayoutType] = React.useState<LayoutType>(localStorage.getItem('company_positions_layout_type') as LayoutType || 'grid');

  React.useEffect(() => {
    localStorage.setItem('company_positions_layout_type', layoutType);
  }, [layoutType])


  // React.useEffect(() => {
  //   if (initialized && !keycloak?.authenticated) {
  //     navigate('/')
  //   }
  // }, [keycloak?.authenticated, initialized])

  React.useEffect(() => {
    if (profile?.company?.id) {
      setPositions([]);
      getPositions({
        variables: {
          filter: {
            orderBy: filter.orderBy,
            status: filter.status === 'all' ? undefined : filter.status as PositionStatus
          },
          page: {page: 0, size: 1000}
        }
      }).then(res => {
        setPositions(res.data?.myPositions.data || [])
      })
    }
  }, [profile, filter]);

  return <Box>
    <Container sx={{my: 10}} maxWidth={'lg'}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 8}}>
        <Box sx={{display: {xs: 'none', md: 'flex'}, gap: 1.5}}>

          <LayoutTypeButton active={layoutType === 'grid'} onClick={() => setLayoutType('grid')}>
            <SquaresFour size={16} color={layoutType !== 'grid' ? '#838383' : "#FFF"} weight="regular" />
          </LayoutTypeButton>

          <LayoutTypeButton active={layoutType !== 'grid'} onClick={() => setLayoutType('list')}>
            {layoutType === 'grid' && <Box component={'img'} src={`/img/teenyicons/list-layout.svg`}  width={15} height={15} />}
            {layoutType !== 'grid' && <Box component={'img'} src={`/img/teenyicons/list-layout-white.svg`}  width={15} height={15} />}
          </LayoutTypeButton>

          <Select sx={{ml: 2.5}} value={filter.status} onChange={(e) => setFilter(prev => ({...prev, status: e.target.value as PositionStatus}))}>
            <MenuItem value={'all'}>All roles</MenuItem>
            <MenuItem value={PositionStatus.Open}>Published</MenuItem>
            <MenuItem value={PositionStatus.Draft}>Draft</MenuItem>
            <MenuItem value={PositionStatus.Archived}>Archived</MenuItem>
          </Select>
          <Select value={filter.orderBy} onChange={(e) => setFilter(prev => ({...prev, orderBy: e.target.value as PositionOrder}))}>
            <MenuItem value={PositionOrder.Recent}>Most recent</MenuItem>
            <MenuItem value={PositionOrder.Oldest}>Oldest</MenuItem>
          </Select>
        </Box>
        <Button startIcon={<PlusCircle size={20} color="#fff" weight="regular" />} data-test='create-role' variant={'contained'} onClick={() => navigate('../position')}>Create new role</Button>
      </Box>
      <Loading in={loading || !called}>
        {(!loading && !positions.length) && <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400}}>
            <Box sx={{textAlign: 'center'}}>
                <Typography>To get started, create your first role.</Typography>
                <Box sx={{m: 2}}>

                  <Button startIcon={<PlusCircle size={20} color="#fff" weight="regular"/>}
                          data-test='create-role-center'
                          variant={'contained'}
                          onClick={() => navigate('../position')}>Create new role</Button>
                </Box>
            </Box>
        </Box>}
        {layoutType === 'grid' && <PositionGrid positions={positions} />}
        {layoutType !== 'grid' && <PositionTable positions={positions} />}
      </Loading>
    </Container>
  </Box>;
}

export default Positions

const PositionGrid = ({positions}: {positions: PositionOutput[]}) => {
  const navigate = useNavigate();

  return <Box data-test='positions' sx={{
    display: 'grid',
    gap: 3,
    gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}
  }}>
    {!!positions.length && positions.map(p => <Box key={p.id}
                                                   sx={{
                                                     background: '#FFFFFF',
                                                     borderRadius: '8px',
                                                     border: '1px solid #E4E4E4',
                                                     cursor: 'pointer',
                                                     p: '24px 32px',
                                                     '&:hover': {
                                                       border: '2px solid #37C9EF',
                                                       p: '23px 31px',
                                                     },
                                                   }}
                                                   onClick={() => navigate('/company/position/' + p.id)}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: .5, mb: '12px'}}>
        <Box sx={{background: p.status === PositionStatus.Open ? '#65C870' : '#D1D1D1', borderRadius: '3px', width: 6, height: 6}} />
        <Typography variant={'body2'}>{_.upperFirst(_.lowerCase(p.status).replace('open', 'published'))}{p.status === PositionStatus.Open && ', ' + DateTime.fromISO(p.publishedAt).toFormat('LLL d')}</Typography>
      </Box>
      <Typography sx={{color: '#2C92D5', fontWeight: 600, fontSize: 20}}>{p.title}</Typography>
      <Box sx={{display: 'flex', gap: 3, mt: '10px', mb: 3}}>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Box component={'img'} src={`/img/teenyicons/pin.svg`}  width={15} height={15} />
          <Typography variant={'body2'}>{p?.locations.map(l => formatLocation(l)).join('; ')}</Typography>
        </Box>
{/*        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Box component={'img'} src={`/img/teenyicons/briefcase_gray.svg`}  width={15} height={15} />
          <Typography variant={'body2'}>{p?.attributesExpanded.filter(a => a.category === AttributeCategory.EmploymentType).map(a => a.attribute).join(', ')}</Typography>
        </Box>*/}
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Box component={'img'} src={`/img/teenyicons/money.svg`}  width={15} height={15} />
          <Typography variant={'body2'}>{showRange(p?.salaryRangeMin, p?.salaryRangeMax, undefined, undefined, toCurrency)} {!!p?.salaryRangeType && _.startCase(_.toLower(p?.salaryRangeType))}</Typography>
        </Box>
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'space-between', p: '14px 16px', borderRadius: '8px', background: '#F6F6F6', flex: 1}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <Typography  sx={{mb: .2}}><b>{p?.numberOfCandidates}</b> candidates</Typography>
          <Typography  sx={{mb: .2}}><b>{p?.candidatesForReview}</b> for review</Typography>
        </Box>
        {!!p?.numberOfCandidates && <Typography  onClick={() => navigate('../candidates/' + p.id)} sx={{fontWeight: 600, color: '#3075B8', cursor: 'pointer', textDecoration: 'underline'}}>View candidates</Typography>}
      </Box>

    </Box>)}
  </Box>
}

const PositionTable = ({positions}: {positions: PositionOutput[]}) => {
  const navigate = useNavigate();

  return <>
    {!!positions.length && <Box data-test='positions' sx={tableStyles}>
        <Box>
            <div>
                <Typography variant={'body2'} sx={{fontWeight: 600}}>ROLE</Typography>
            </div>
            <div>
                <Typography variant={'body2'} sx={{fontWeight: 600}}>LOCATION</Typography>
            </div>
            <div>
                <Typography variant={'body2'} sx={{fontWeight: 600}}>JOB TYPE</Typography>
            </div>
            <div>
                <Typography variant={'body2'} sx={{fontWeight: 600}}>SALARY RANGE</Typography>
            </div>
            <div>
                <Typography variant={'body2'} sx={{fontWeight: 600}}>CANDIDATES</Typography>
            </div>
        </Box>
      {positions.map((p, i) => <Box key={p.id} onClick={() => navigate('../position/' + p.id)}>
        <div>
          <Typography sx={{color: '#2C92D5', fontWeight: 600, fontSize: 20}}>{p.title}</Typography>
          <Box sx={{display: 'flex', alignItems: 'center', gap: .5, mt: '9px'}}>
            <Box sx={{background: p.status === PositionStatus.Open ? '#65C870' : '#D1D1D1', borderRadius: '3px', width: 6, height: 6}} />
            <Typography variant={'body2'}>{_.upperFirst(_.lowerCase(p.status).replace('open', 'published'))}{p.status === PositionStatus.Open && ', ' + DateTime.fromISO(p.publishedAt).toFormat('LLL d')}</Typography>
          </Box>
        </div>
        <div>
          {p.locations.map(l => formatLocation(l)).map(v => <Typography  sx={{color: '#393939'}}>{v}</Typography>)}
        </div>
        <div></div>
        <div><Typography  sx={{color: '#393939'}}>{showRange(p?.salaryRangeMin, p?.salaryRangeMax, undefined, undefined, toCurrency)} {!!p?.salaryRangeType && _.startCase(_.toLower(p?.salaryRangeType))}</Typography></div>
        <div>
          <Typography  sx={{color: '#2C92D5', fontWeight: 600, textDecoration: 'underline', textAlign: 'center'}}>{p?.numberOfCandidates}</Typography>
        </div>
      </Box>)}
    </Box>}
  </>
}

const tableStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1.5,
  '& > div': {
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 1,
    },
    '& > div:nth-child(2)': {
      minWidth: 230
    },
    '& > div:nth-child(3)': {
      minWidth: 170
    },
    '& > div:nth-child(4)': {
      minWidth: 170
    },
    '& > div:nth-child(5)': {
      minWidth: 80
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',

      justifyContent: 'center',
    }
  },
  '& > div:not(:first-child)': {
    background: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #E4E4E4',
    minHeight: 90,
    cursor: 'pointer',
    p: '1px',
    '&:hover': {
      border: '2px solid #37C9EF',
      p: 0,
    },
    '& > div:nth-child(1)': {
      pl: '20px',
    },

  }

}
