import {Box, Button, Skeleton, Stack, Typography} from "@mui/material";
import React from "react";
import {useAvailabilityRequestStatusApi} from "./api/useAvailabilityRequestStatusApi";

interface Props {
  positionMatchId: string
}

export function AvailabilityRequestButton(props: Props) {

  const {requestSlots, availabilityStatus, loading, refetch} = useAvailabilityRequestStatusApi(props.positionMatchId)

  const handleClick = async () => {
    await requestSlots();
    await refetch();
  }

  if(loading) {
    return <Box sx={{width: '100%'}}><AvailabilitySkeleton /></Box>
  }

  return <Box sx={{display: "flex", justifyContent: "center", m: 1, flexDirection: "column", gap:2, textAlign: "center"}}>
    {!availabilityStatus &&
        <>
            <Typography variant={"body1"} sx={{textAlign: "center"}}>You can request more slots from contractor</Typography>
            <Button onClick={handleClick} variant={"contained"} size={"small"}>Request more time slots</Button>
        </>
    }

    {!!availabilityStatus?.status && <>
        <Typography variant={"body1"} sx={{textAlign: "center"}}>You have now requested more options from the candidate. We will notify you as soon as they are added.</Typography>
    </>}
  </Box>
}

function AvailabilitySkeleton() {
  return <Stack spacing={1}>
    <Skeleton variant="text" width={'100%'}  />
    <Skeleton variant="rounded" width={'100%'} height={50} />
    <Skeleton variant="rounded" width={'100%'}  height={30} />
  </Stack>
}