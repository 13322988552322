import {getFilesUrl} from "../../../shared";
import {Button} from "@mui/material";
import React from "react";
import {FileStorage} from "../../../api/sdl";

interface Props {
  resumeUrl?: string,
  fileStorage?: FileStorage
}

export default function DownloadResumeButton({resumeUrl, fileStorage}: Props) {
  return <Button
    sx={{width: 1, height: 1}}
    onClick={() => {
      const url = getFilesUrl(fileStorage) + '/' + resumeUrl;
      window.open(url);
    }}
    variant="outlined"
    color="primary"
  >
    My Current Resume
  </Button>
}
