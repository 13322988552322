import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    TextField,
    Typography
} from "@mui/material";
import {useUploadAwardMutation} from "../../../api/sdl";
import {uploadClient} from "../../../UploadApolloClient";
import {useFormik} from "formik";
import * as yup from "yup";
import Loading from "../../../shared/components/Loading";

interface Props {
    onUpdate: () => void,
    onCancel: () => void,
}

const schemaFields: any = {
    title: yup
        .string()
        .required('Category is required'),
    file: yup
        .mixed()
        .required('Upload a PDF file')

}


export function AwardForm({onUpdate, onCancel}: Props) {
    const [uploadAward, {loading}] = useUploadAwardMutation({client: uploadClient});
    const [file, setFile] = React.useState<any>()

    const formik = useFormik({
        initialValues: {
            title: '',
            file: undefined,
        },
        validationSchema: yup.object(schemaFields),
        onSubmit: (values) => {

            uploadAward({
                variables: {
                    payload: {
                        file: file,
                        title: values.title,
                    }
                }
            })
                .then(res => onUpdate())
        },
        validateOnChange: true
    });

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileValue = (e?.target?.files as any)[0]
        setFile(fileValue)
        formik.setFieldValue('file', fileValue)
    }

    return <Loading in={loading} height={'300px'}>
        <form onSubmit={formik.handleSubmit}>

            <Box sx={{width: 1, gap: 2, display: 'flex', flexDirection: 'column'}}>

                <FormControl sx={{width: 1}}>
                    <FormLabel>Award or Recommendation title:</FormLabel>
                    <TextField placeholder="e.g. Recommendation letter from Humana New York"
                               name="title"
                               data-test='title-field'
                               sx={{flex: 1}}
                               value={formik.values.title}
                               onChange={formik.handleChange}
                               error={formik.touched.title && Boolean(formik.errors.title)}
                    />
                    {(formik.touched.title && formik.errors.title) &&
                        <FormHelperText data-test='title-error'
                                        sx={{color: '#D3232F'}}>{formik.errors.title}</FormHelperText>}
                </FormControl>


                <Box sx={{}}>
                    <input accept="application/pdf"
                           onChange={onChange}
                           style={{display: 'none'}}
                           id={'upload-award'}
                           type="file"/>

                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'space-between'}}>
                        {!!file && <Box sx={{paddingRight: 1}}><Typography>{file.name}</Typography></Box>}
                        <Button component={'label'}
                                htmlFor={'upload-award'}
                                color="primary"
                                variant="outlined">
                            {file ? "Replace file" : "Upload file"}
                        </Button>
                    </Box>

                    {(formik.touched.file && formik.errors.file) &&
                        <FormHelperText data-test='file-error'
                                        sx={{color: '#D3232F'}}>{formik.errors.file}</FormHelperText>}

                </Box>


                    <Box sx={{justifyContent:"space-between", width: 1, display: 'flex'}}>
                        <Button onClick={onCancel} variant={"outlined"}>Cancel</Button>
                        <Button onClick={() => formik.submitForm()} variant={"contained"}>Save</Button>
                    </Box>
            </Box>
        </form>
    </Loading>
}