import {Input} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React from "react";

interface Props {
  onSaveClick: (value: string) => void,
  loading: boolean,
}

export default function AccountInput({onSaveClick, loading}: Props) {
  const [stripeAccountId, setStripeAccountId] = React.useState('');

  const onSave = () => {
    onSaveClick(stripeAccountId);
    setStripeAccountId('');
  }

  return <>
    <Input placeholder="Enter stripe account ID"
           sx={{my: 1}}
           value={stripeAccountId}
           onChange={e => setStripeAccountId(e.target.value as string)}
    />
    <LoadingButton loading={loading}
                   onClick={onSave}
                   sx={{mt: 1}} disabled={!stripeAccountId}>Save</LoadingButton>
  </>
}