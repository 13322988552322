import {useMigratePositionMatchMutation} from "../../../api/sdl";
import {LoadingButton} from "@mui/lab";
import React from "react";

export function MigratePositionMatchButton() {
    const [migrate, migrateData] = useMigratePositionMatchMutation();

    return <LoadingButton onClick={() => migrate()} loading={migrateData.loading} variant={"contained"}>
        Migrate Meetings
    </LoadingButton>
}