import {useNavigate, useParams} from "react-router-dom";
import {
  Box, Typography, Card, Divider, Stack,
} from "@mui/material";
import * as React from "react";
import ContractPayments from "./ui/ContractPayments";
import ContractDetails from "./ui/ContractDetails";
import useContractById from "./hooks/useContractById";
import RemoveContractButton from "./features/RemoveContractButton";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import Loading from "../../../../shared/components/Loading";
import {toCurrency} from "../../../../shared/utils";
import EditContractForm from "./ui/EditContractForm";
import CreateContractFormDataProvided from "./features/CreateContractFormDataProvided";
import {WorkContractStatus} from "../../../../api/sdl";


export default function AdminContract() {
  const {id} = useParams();
  const navigate = useNavigate();

  const contract = useContractById(id!!);

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>Contract Setup</Typography>
    </Box>

    <Box ml={2} mt={1} mb={2}>
      <Breadcrumbs crumbs={[{title: 'Contracts', link: '../contracts'}, {title: id ? 'Setup' : 'Create'}]} />
    </Box>

    {!id && <Card sx={{p: 2, maxWidth: 550}}>
      <CreateContractFormDataProvided onSubmit={contractId => navigate('../contract/' + contractId)} />
    </Card>}

    {!!id && <Loading in={!contract}>
      <ContractDetails contract={contract}/>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <RemoveContractButton contractId={id!!} onRemoveCallback={() => navigate('../contracts', {replace: true})} />
      </Box>
      {contract?.status === WorkContractStatus.Setup && <EditContractForm contract={contract!}/>}
      <Box sx={{display: 'flex', gap: 3, mt: 3, ml: 2}}>
        <Card sx={{p: 0}}>
          <ContractPayments contractId={id!!} />
        </Card>
        <Box sx={{background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px', height: '260px', width: 0.2}}>
          <Stack spacing={2}>
            <Typography sx={{ml: 2, fontSize: 12, lineHeight: 2, fontWeight: 600}}>SUMMARY</Typography>
            <TableRow title={'Fee:'} value={contract?.pendingFee ?? 0}/>
            <Divider />
            <TableRow title={'Payout:'} value={contract?.pendingPayouts ?? 0}/>
            <Divider />
            <TableRow title={'Pending:'} value={contract?.pendingInvoices ?? 0}/>
          </Stack>
        </Box>
      </Box>
    </Loading>}
  </div>
}

const TableRow = ({title, value}: { title: string, value: number}) => {
  return <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
    <Box sx={{display: 'table-cell', verticalAlign: 'top', py: 1}}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <Typography sx={{color: 'text.secondary', whiteSpace: 'nowrap'}} >{title}</Typography>
      </Box>
    </Box>
    <Box sx={{display: 'table-cell', pl: 2, verticalAlign: 'top', py: 1,}}>
      <Typography sx={{fontWeight: 600}}>{toCurrency(value, 2, 2)}</Typography>
    </Box>
  </Box>
}
