import {Box, Typography} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";
import {CalendarSlot} from "../../api/sdl";
import CalendarTableHeader from "./ui/CalendarTableHeader";
import {CalendarRow, GridCoordinate} from "./ui/CalendarRow";
import {CalendarUtil} from "./lib/CalendarUtil";
import _ from "lodash";
import {MeetingSlotCoords} from "./lib/types";

export interface CalendarChangeValue {
  // period: {
  //   start: string,
  //   end: string
  // },
  // slots: {date: string, hour: number}[]
  slots: string[]
}
interface CalendarTableSlots {
  slots: CalendarSlot[],
  startDate: DateTime,
  onChange: (value: CalendarChangeValue) => void
}
/*
const slots = [
  {
    "date": "2024-04-02T18:00:00Z",
    "status": "AVAILABLE",
    "properties": [],
    "__typename": "CalendarSlot"
  } as CalendarSlot
];*/
export default function FullCalendarTable({slots, startDate, onChange}: CalendarTableSlots) {

  const range = CalendarUtil.range(13, 7);
  const [dirty, setDirty] = React.useState(false);
  const [enabled, setEnabled] = React.useState<boolean>();

  const [meetings, setMeetings] = React.useState<MeetingSlotCoords[]>([]);

  const [selectedCoords, setSelectedCoords] = React.useState<GridCoordinate[]>(
    CalendarUtil.availability(startDate, slots)
  );

  const debugMe = (x: any, method: string) => {
    // console.log(method, x);
  };

  React.useEffect(() => {
    debugMe(slots, 'initialSlots');
  }, [slots]);

  React.useEffect(() => {

    if(!enabled && dirty) {
      commitChanges(selectedCoords);
    }
  }, [enabled, selectedCoords]);

  React.useEffect(() => {
    setEnabled(false);
    setSelectedCoords(CalendarUtil.availability(startDate, slots))
  }, [slots, startDate]);

  React.useEffect(() => {
    setMeetings(CalendarUtil.meetings(startDate, slots))
  }, [slots, startDate]);


  const onMouseUp = (coord: GridCoordinate) => {
    debugMe(coord, 'onMouseUp');
    setEnabled(false);
    setDirty(true);
  };

  const commitChanges = (_selectedCoords: GridCoordinate[]) => {
    const selectedDates = _selectedCoords.map((coord) => CalendarUtil.dateFromGridCoordinate(startDate, coord))

    debugMe(selectedDates, 'onChange');

    onChange({
      slots: selectedDates
    });
  };


  const onMouseDown = (coord: GridCoordinate) => {
    if(ignoreMeetingSlot(coord)) return;

    debugMe(coord, 'onMouseDown');
    setEnabled(true);
    addCoord(coord);
  };

  const onMouseOver = (coord: GridCoordinate) => {
    if(ignoreMeetingSlot(coord)) return;

    if(enabled) {
      addCoord(coord)
      debugMe(coord, 'onMouseOver');
    }
  };

  const terminateSelection = () => {
    setEnabled(false)
  }

  const onCellClick = (coord: GridCoordinate) => {

    debugMe(coord, 'onCellClick')

    if(ignoreMeetingSlot(coord)) return;

    if(!enabled && CalendarUtil.exists(selectedCoords, coord)) {
      removeCoord(coord)
    }
  }

  function ignoreMeetingSlot(coord: GridCoordinate) {
    if(CalendarUtil.exists(meetings, coord)) {
      debugMe(coord, 'ignore meeting slot')
      return true;
    } else {
      return false;
    }
  }

  function addCoord(coord: GridCoordinate) {
    if(ignoreMeetingSlot(coord)) return;

    if(!CalendarUtil.exists(selectedCoords, coord)) {
      setSelectedCoords((previousValue) => {
        return [...previousValue, coord]
      });
    }
  }

  function removeCoord(coord: GridCoordinate) {
    if(ignoreMeetingSlot(coord)) return;

    if(CalendarUtil.exists(selectedCoords, coord)) {
      setSelectedCoords((previousValue) => {
        let value = [...previousValue];

        const index = previousValue.findIndex((c) => _.isEqual(c, coord))

        if(index !== -1) {
          value.splice(index, 1)
          return value
        } else {
          return selectedCoords
        }
      });
    }
  }

  return <>
    <Box data-test='calendar' sx={{overflowY: 'auto', maxWidth: '80vw'}}>
      <Typography sx={{textAlign: 'right'}}>Time zone: <b>{DateTime.now().zoneName}</b></Typography>
      <Box component={'table'} cellSpacing={5} sx={{cursor: 'pointer', width: 1, '& td': {textAlign: 'center'}}} onMouseLeave={terminateSelection} onMouseUp={terminateSelection}>
        <CalendarTableHeader startDate={startDate}/>
        <tbody>
          {range.map((c) =>
            <CalendarRow key={c}
                         availabilityCoords={selectedCoords}
                         rowNumber={c}
                         meetings={meetings}
                         onCellClick={onCellClick}
                         onMouseDown={onMouseDown}
                         onMouseUp={onMouseUp}
                         onMouseOver={onMouseOver} />
          )}
        </tbody>
      </Box>
    </Box>
  </>
}
