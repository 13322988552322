import React from 'react';
import {useSaveGoogleWorkspaceCodeMutation} from "../../api/sdl";
import {useLocation, useNavigate} from "react-router-dom";

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export function GoogleWorkspaceCodeReader() {

  const params = useQueryParams();
  const navigate = useNavigate();
  const [saveCode, {loading}] = useSaveGoogleWorkspaceCodeMutation();

  React.useEffect(() => {

    (async () => {

      await saveCode({variables: {code: params.get("code")!}});
      navigate("../service");
    })();
  }, []);

  return <>
  </>
}