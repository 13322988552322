import {Box, Button, FormControl, FormHelperText, FormLabel, TextField, Typography} from "@mui/material";
import React from "react";
import {Minus, Plus} from "phosphor-react";

interface Props {
  rangeIsExpanded: boolean,
  formik: any,
  onRangeToggleClicked: () => void,
}
export function WorkingHoursField({rangeIsExpanded, formik, onRangeToggleClicked}: Props) {

  return <Box sx={{display: 'flex', gap: {xs: 1, md: 3}, width: 1, flexDirection: {xs: "column", md: "row"},}}>

    <FormControl sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: '250px'}}>
      <FormLabel>Working hours (per week):</FormLabel>
      <TextField placeholder="From"
                 data-test='hours-per-week-min'
                 name="hoursPerWeekMin"
                 type={'number'}
                 sx={{width: 1}}
                 onKeyDown={e => ['-', '+'].includes(e.key) && e.preventDefault()}
                 value={formik.values.hoursPerWeekMin}
                 onChange={formik.handleChange}
                 error={formik.touched.hoursPerWeekMin && Boolean(formik.errors.hoursPerWeekMin)}
      />
      {(formik.touched.hoursPerWeekMin && formik.errors.hoursPerWeekMin) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.hoursPerWeekMin}</FormHelperText>}
    </FormControl>

    {rangeIsExpanded &&
      <Box sx={{flexDirection: "row", display: "flex",}}>
        <Box sx={{marginTop: "20px", marginRight: 2, alignContent: "center"}}>
            <Typography> to </Typography>
        </Box>
        <FormControl sx={{width: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', minWidth: '250px', marginTop: '24px'}}>
          <TextField placeholder="To"
                     data-test='hours-per-week-max'
                     name="hoursPerWeekMax"
                     type={'number'}
                     onKeyDown={e => ['-', '+'].includes(e.key) && e.preventDefault()}
                     value={formik.values.hoursPerWeekMax}
                     onChange={formik.handleChange}
                     error={formik.touched.hoursPerWeekMax && Boolean(formik.errors.hoursPerWeekMax)}
          />
          {(formik.touched.hoursPerWeekMax && formik.errors.hoursPerWeekMax) && <FormHelperText sx={{color: '#D3232F'}} data-test={"hours-max-error"}>{formik.errors.hoursPerWeekMax}</FormHelperText>}
        </FormControl>
      </Box>
    }

    <Box sx={{minWidth: '170px', textAlign: "left", marginTop: '24px'}}>
      <Button startIcon={
        rangeIsExpanded ? <Minus size={18} color="#128eaf" weight="regular" /> : <Plus size={18} color="#128eaf" weight="regular" />

      }
              variant="text"
              disabled={false}
              data-test="toggle-hours-range"
              onClick={onRangeToggleClicked}
      >
        {rangeIsExpanded ? "Remove range": "Add range"}
      </Button>
    </Box>
  </Box>;
}
