import {
  Box, Link, Typography
} from "@mui/material";
import React from "react";
import {withOnboardingLayout} from "./OnboardingLayout";

function OnboardingCompleted() {

  return <Box sx={{mb: 10}}>

    <Typography data-test='completed'
                variant={'h3'}
                sx={{mb: 3, fontWeight: '500!important'}}>Thank you for completing your initial application</Typography>
    <Typography sx={{my: 3}}>
        <Typography fontWeight={"bold"}>What happens next?</Typography><br />
      Our matching team will review your application in the next few days. If you are a fit, we will reach out to schedule an interview.<br /><br />
      See our full application process below
    </Typography>
    <Box component={'img'} src={'/img/timeline.png'} sx={{width: 1}} />
    <Box sx={{textAlign:"center"}}>
      <Typography variant={"body1"}>Want to learn more about MDliaison? Check out our <Link href={"https://mdliaison.com/medical-sales-industry/"} target={"_blank"}>blog</Link>.</Typography>
    </Box>
  </Box>
}

export default withOnboardingLayout(OnboardingCompleted)
/**
 // {!showCompleted && <>
    //   <Typography data-test='step-title' variant={'h2'} sx={{mb: 3, fontSize: {xs: '24px', md: '2.25rem'}}}>Employment History</Typography>
    //   <Typography sx={{mb: 5}}>
    //     Please tell us about the last 4 companies you have worked for. This helps better match you to future opportunities.
    //   </Typography>
    //   <HistoryList profile={profile?.contractorProfile || undefined} onSave={refresh} onContinue={() => setShowCompleted(true)} />
    // </>}
 */