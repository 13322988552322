import {Box, Card, Typography,} from "@mui/material";
import * as React from "react";
import {
  ContractorPaymentOutput,
  useContractorInvoicesQuery,
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../../shared/components/DataGrid";
import {DateTime} from "luxon";
import useSessionStorageState from "../../../shared/hooks/useSessionStorageState";
import { toCurrency, toFirstCase } from "../../../shared";
import {Link} from "react-router-dom";

const columns: DataGrigColumn<Partial<ContractorPaymentOutput>>[]  = [
  {
    title: 'Date',
    value: o => <Typography variant={'body2'}>
      {DateTime.fromISO(o.invoiceDate).toFormat('LL/dd/yyyy')}
    </Typography>
  }, {
    title: 'Company',
    value: o => o.company?.name || ''
  }, {
    title: 'Description',
    value: o => o.description || ''
  }, {
    title: 'Type',
    value: o => toFirstCase(o.type),
  }, {
    title: 'Payout Amount',
    value: o => toCurrency(o.payoutAmount, 2, 2)
  }, {
    title: 'Status',
    value: o => toFirstCase(o.status),
  }]


export function ContractorPayouts() {
  const [rowsPerPage, setRowsPerPage] = usePageSize();
  const [page, setPage] = usePage();
  const {loading, data, error} = useContractorInvoicesQuery({fetchPolicy: 'no-cache'})

  const rows: Partial<ContractorPaymentOutput>[] = React.useMemo(() => {
    return data?.contractorInvoices as Partial<ContractorPaymentOutput>[] || [];
  }, [data])

  return <div>
    <Box sx={{py: 4, px: 2}}>
      <Box sx={{display: 'flex', gap: 2, alignItems: "center"}}>
        <Typography variant={'h3'} sx={{ml: 2}}>Payments</Typography>
        <Box>
          <Typography component={Link} to={"../balance-reports"}>Download payment history</Typography>
        </Box>
      </Box>

      <Card sx={{mt: 3, p: 0}}>
        <DataGrid columns={columns}
                  rows={rows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  error={error?.message}
                  onNextClick={() => setPage(p => p + 1)}
                  onPrevClick={() => setPage(p => p - 1)}
                  loading={loading}
        />
      </Card>
    </Box>
  </div>
}


function usePageSize() {
  return useSessionStorageState<number>(
    'contractorInvoiceListPageSize',
    20
  )
}

function usePage() {
  return useSessionStorageState<number>(
    'contractorInvoiceListPage',
    0
  )
}
