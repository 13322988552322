import {useAvailabilityRequestStatusQuery, useRequestMoreAvailabilitySlotsMutation} from "../../../../../api/sdl";

export function useAvailabilityRequestStatusApi(positionMatchId: string) {
  const [requestSlots, {loading: requestLoading}] = useRequestMoreAvailabilitySlotsMutation({variables: {positionMatchId}})

  const {data, loading, refetch} = useAvailabilityRequestStatusQuery({
    variables: {positionMatchId},
    fetchPolicy: 'no-cache'
  });

  return {
    requestSlots,
    availabilityStatus: data?.availabilityRequestStatus,
    loading: loading || requestLoading,
    refetch
  }
}