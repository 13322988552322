import {Box, Typography, SxProps} from "@mui/material";
import React from "react";

const Param = ({title, content, sx}: {title: string | JSX.Element, content?: string | JSX.Element | null, sx?: SxProps}) => {
  return <Box sx={sx}>
    {typeof title === 'string' && <Typography sx={{color: '#8A8C8D'}}>{title}</Typography>}
    {typeof title !== 'string' && <>{title}</>}
    {!content && <Typography variant={'body2'}>-</Typography>}
    {!!content && <>
      {typeof content === 'string' && <Typography variant={'body2'}>{content}</Typography>}
      {typeof content !== 'string' && <>{content}</>}
    </>}
  </Box>
}

export default Param;
