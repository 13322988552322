import {DateTime} from "luxon";
import {
  useCalendarSlotsForPositionMatchQuery,
} from "../../../api/sdl";

export function usePositionMatchCalendarApi(startDate: DateTime, positionMatchId: string) {
  const {data, loading, refetch} = useCalendarSlotsForPositionMatchQuery({variables: {
      positionMatchId,
      start: startDate.toFormat('yyyy-LL-dd'),
      end: startDate.plus({days: 5}).toFormat('yyyy-LL-dd'),
    },
    fetchPolicy: 'no-cache'
  });

  return {
    slots: data?.calendarSlotsForPositionMatch || [],
    loading: loading,
    refetch
  }
}