import {Box, Button, Dialog, DialogContent, FormControl, Stack, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {CrossIconButton} from "../../../cross-icon-button";
import {useSaveTextAnswerMutation} from "../../../../api/sdl";
import {Editable, useIsMobile} from "../../../../shared";

interface Props extends Editable {
  open: boolean,
  onClose: () => void,
  title: string,
  answer: string,
  onUpdate: (value: string) => void,
  questionId: string,
}

export function TextAnswerModal(props: Props) {

  const isMobile = useIsMobile()
  const [save] = useSaveTextAnswerMutation()
  const [answer, setAnswer] = useState(props.answer)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value)
  }

  const handleSave = async () => {

    await save({variables: {contractorId: null, payload: {interviewQuestionId: props.questionId, answerText: answer}}})
    props.onUpdate(answer)
  }

  return <Dialog open={props.open} onClose={props.onClose} maxWidth={'xl'} fullScreen={isMobile}>
    <DialogContent sx={{
      width: {xs: '100%', md: '600px'},
    }}>
      <Box sx={{display: 'flex', gap: 2, alignItems: 'flex-start', justifyContent: 'space-between'}}>
        <Typography  sx={{textAlign: 'left', p: '10px'}}><b>{props.title}</b></Typography>
        <CrossIconButton onClose={props.onClose} />
      </Box>

      <Stack spacing={2}>

        <FormControl sx={{ width: 1 }}>
          <TextField variant={"outlined"} name={"answer"} value={answer} multiline fullWidth onChange={handleChange}/>
        </FormControl>

        <Box sx={{justifyContent: "flex-end", display: "flex"}}>
          <Button onClick={handleSave} variant={'contained'}>Save answer</Button>
        </Box>
      </Stack>

    </DialogContent>

  </Dialog>
}