import {Box, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";

interface CommissionFormProps {
    initialValue: number,
}
export const CommissionForm = React.forwardRef((props: CommissionFormProps, ref: any) => {

    const formik = useFormik({
        initialValues: {platformCommission: props.initialValue,},
        validationSchema: yup.object({
            platformCommission: yup
                .string()
                .required('This is required'),
        }),
        onSubmit: (values) => {
        },
        validateOnChange: true
    });

    React.useImperativeHandle(ref, () => ({
        isValid() {
            return formik.submitForm().then(() => formik.validateForm()).then((res: any) => {
                return Object.keys(res).length === 0;
            });
        },
        getValues() {
            return formik.values
        }
    }));

    return <>
        <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{width: 1}}>

                <Box sx={{display: 'flex', flexDirection: 'row', gap: 3, flex: 1, maxWidth: 150}}>
                    <FormControl sx={{width: 1}}>
                        <FormLabel>Platform Commission</FormLabel>
                        <TextField
                            name="platformCommission"
                            type={"number"}
                            value={formik.values.platformCommission}
                            onChange={formik.handleChange}
                            error={formik.touched.platformCommission && Boolean(formik.errors.platformCommission)}
                        />
                        {(formik.touched.platformCommission && formik.errors.platformCommission) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.platformCommission}</FormHelperText>}

                    </FormControl>
                </Box>

            </FormControl>
        </form>
    </>
})