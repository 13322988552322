import * as React from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useFormik} from "formik";
import * as yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = CKEditor as any;

interface Props {
  personalSummary?: string | null,
  onSubmit: (value: {personalSummary: string}) => void,
  loading: boolean,
  onBackClick: () => void,
}

export default function PersonalSummaryForm(props: Props) {

  const formik = useFormik({
    initialValues: {
      personalSummary: props.personalSummary || '',
    },
    validationSchema: yup.object({
      personalSummary: yup.string()
    }),
    onSubmit: (values) => {
      formik.isValid && props.onSubmit({
        personalSummary: values.personalSummary,
      })
    },
    validateOnChange: true
  });

  return <Box component={'form'} flex={4} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
      <FormControl>
        <FormLabel>Professional statement</FormLabel>
        <Editor
          editor={ ClassicEditor }
          config={ {
            toolbar: ["undo", "redo", "|", "heading", "|", "bold", "italic", "|", "link", "|", "bulletedList", "numberedList", "outdent", "indent"]
          }}
          data={formik.values.personalSummary}
          onReady={ (editor: any) => {
            // You can store the "editor" and use when it is needed.
          } }
          onChange={ ( event: any, editor: any ) => {
            const data = editor.getData();
            formik.setFieldValue('personalSummary', data)
          } }
        />
        {(formik.touched.personalSummary && formik.errors.personalSummary) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.personalSummary}</FormHelperText>}
      </FormControl>

      <Box sx={{mt: 3, display: 'flex', gap: 3}}>
        <Button variant={'outlined'} color={'secondary'} onClick={props.onBackClick}>Back</Button>
        <LoadingButton loading={props.loading} variant={'contained'} type="submit" data-test="personal-summary-save-button">Save</LoadingButton>
      </Box>
    </Box>
  </Box>
}
