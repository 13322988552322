import React, {PropsWithChildren} from "react";
import {RescheduleMeetingClickHandlerContext} from "../../../entity";
import {useNavigate} from "react-router-dom";

export const RescheduleMeetingClickHandlerContextProvider = (props: PropsWithChildren<any>) => {
  const navigate = useNavigate();
  return <RescheduleMeetingClickHandlerContext.Provider value={{
    handler: (positionMatchId: string, meetingId: string) => {
      navigate(`../reschedule/${positionMatchId}/meeting/${meetingId}`)
    }
  }}>
    {props.children}
  </RescheduleMeetingClickHandlerContext.Provider>
}