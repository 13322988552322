import {
  PositionByIdQuery, useAddCandidateMutation,
  useArchivePositionMutation, useCandidatesByPositionLazyQuery, useConfirmContractorApplicationMutation,
  usePositionByIdLazyQuery, usePublishPositionMutation,
  useRemoveCandidateMutation, useUnPublishPositionMutation
} from "../../../../../api/sdl";
import {useSnackbar} from "notistack";
import {useEffect, useReducer} from "react";
import {CandidateMatchApiData} from "../../../../../feature";
import {useConfirm} from "../../../../../shared/ConfirmDialogContext";
import {useNavigate} from "react-router-dom";

type PositionApiData = PositionByIdQuery["positionById"]

// Define types for state and actions
interface State {
  position?: PositionByIdQuery["positionById"];
  initialized: boolean;
  publishLoading: boolean,
  unpublishLoading: boolean,
  candidates: CandidateMatchApiData[],
  contractorIdDrawer: string | null,
}

export type PositionAction =
  | { type: 'DATA_INIT' }
  | { type: 'POSITION_LOADED', payload: PositionApiData }
  | { type: 'CANDIDATES_LOADED', payload: CandidateMatchApiData[] }
  | { type: 'PUBLISH' }
  | { type: 'UNPUBLISH' }
  | { type: 'ARCHIVE' }
  | { type: 'REMOVE_CANDIDATE_REQUESTED', payload: { positionMatchId: string, notifyContractor: boolean } }
  | { type: 'CONFIRM_CANDIDATE_REQUESTED', payload: { positionMatchId: string } }
  | { type: 'REMOVE_CANDIDATE_LOADED' }
  | { type: 'CANDIDATE_INVITED', payload: {contractorId: string} }
  | { type: 'INVITE_COMPLETED' }
  | { type: 'CLOSE_PROFILE_DRAWER' }
  | { type: 'OPEN_PROFILE', payload: {contractorId: string} }
  | { type: 'POSITION_UPDATED'}
  | { type: 'DUMMY'}
;

// interface HookShape{
//   position: PositionByIdQuery["positionById"],
//   candidates: CandidateMatchApiData[],
//   removeContractor: (contractorId: string) => void,
//   addContractor: (contractorId: string) => void,
//   publish: () => void,
//   unpublish: () => void,
//   archive: () => void,
// }

interface Props {
  positionId: string,
  companyId: string,
}

export function useAdminPositionLogic({positionId, companyId}: Props) {

  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const confirm = useConfirm();


  const [getPosition, {loading}] = usePositionByIdLazyQuery({
      fetchPolicy: 'no-cache',
      variables: {id: positionId},
      onCompleted: (data) => {
        dispatch({
          type: "POSITION_LOADED",
          payload: data?.positionById
        })
      }
    },
  );

  const [getCandidates] = useCandidatesByPositionLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {positionId},
    onCompleted: (data) => {
      dispatch({
        type: 'CANDIDATES_LOADED',
        payload: data.candidatesByPosition
      })
    }
  })

  const [removeCandidate] = useRemoveCandidateMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      dispatch({
        type: "REMOVE_CANDIDATE_LOADED"
      });
      enqueueSnackbar('Candidate was removed successfully', {variant: 'success'});
    },
  });

  const [addCandidate] = useAddCandidateMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      dispatch({
        type: "INVITE_COMPLETED"
      })
      enqueueSnackbar('Candidate was added successfully', {variant: 'success'});
    },
  });

  const handleInvite = (id: string) => {
    // addCandidate({
    //   variables: {
    //     payload: {
    //       contractorId: id,
    //       positionId: positionId
    //     }
    //   }
    // })
  }

  const [publish, {loading: publishLoading}] = usePublishPositionMutation({variables: {positionId}, onCompleted: () => getPosition()});
  const [unpublish, {loading: unpublishLoading}] = useUnPublishPositionMutation({variables: {positionId}, onCompleted: () => getPosition()})

  const [archive] = useArchivePositionMutation({
    variables: {positionId},
    onCompleted: () => navigate('../company/' + companyId),
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
  });

  const handleArchiveClick = () => confirm({
    content: 'Are sure you want to archive this position?',
    okButtonTitle: 'Yes - I want to archive this position',
    cancelButtonTitle: 'No - return to position'
  }).then(val => val && archive());

  function reducer(state: State, action: PositionAction) {

    console.log('action', action);
    switch (action.type) {
      case "DATA_INIT":

        return state;

      case "POSITION_LOADED":
        return {...state, position: action.payload, initialized: true}

      case "CANDIDATES_LOADED":
        return {...state, candidates: action.payload}

      case "CANDIDATE_INVITED":
        addCandidate({variables: {payload: {contractorId: action.payload.contractorId, positionId}}})
        return state;
      case "INVITE_COMPLETED":
      case "REMOVE_CANDIDATE_LOADED":
        getCandidates();
        return state;

      case "CONFIRM_CANDIDATE_REQUESTED":

        confirmContractor({variables: {positionMatchId: action.payload.positionMatchId}});
        return state;

      case "OPEN_PROFILE":
        return {...state, contractorIdDrawer: action.payload.contractorId};
      case "CLOSE_PROFILE_DRAWER":
        return {...state, contractorIdDrawer: null};
      case "REMOVE_CANDIDATE_REQUESTED":
        removeCandidate({variables: {id: action.payload.positionMatchId, notifyContractor: action.payload.notifyContractor}})
        return state;

      case "PUBLISH":
        publish();
        return state;
      case "ARCHIVE":
        handleArchiveClick();
        return state;
      case "POSITION_UPDATED":
        getPosition();
        return state;
      case "UNPUBLISH":
        unpublish();
        return state;

      default:
        alert(`${action.type} is not implemented.`)
        return state;
    }

  }

  const [confirmContractor] = useConfirmContractorApplicationMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      getCandidates();
      enqueueSnackbar('Candidate was confirmed successfully', {variant: 'success'});
    },
  })

  const [state, dispatch] = useReducer(reducer,
    {position: undefined, publishLoading: false, unpublishLoading: false, initialized: false, candidates: [], contractorIdDrawer: null});

  useEffect(() => {
    getPosition();
    getCandidates();
  }, []);

  return {
    ...state,
    dispatch
  }
}