import React from 'react';
import {Typography} from "@mui/material";
import {DateTime} from "luxon";

export default function CalendarTableHeader({startDate}: {startDate: DateTime}) {

  return <thead>
  <tr>
    {Array(5).fill(0).map((v, i) => v + i).map((v: number) => <th key={v} style={{width: '10px'}}>
      <Typography  sx={{whiteSpace: 'nowrap', fontSize: 'larger'}}>
        <b>{startDate.plus({day: v}).toLocaleString({month: 'short', day: '2-digit', weekday: 'short'})}</b>
      </Typography>
    </th>)}
  </tr>
  </thead>
}