import React, {PropsWithChildren} from "react";
import {Box, IconButton, List, ListItem, ListItemButton} from "@mui/material";
import * as _ from 'lodash';
import {useIsMobile} from "../hooks/useIsMobile";
import {useNavigate} from "react-router-dom";

const Layout = (props: PropsWithChildren<{}> & {menu: IMenu | (() => Promise<IMenu>) })  => {

  return <Box sx={{ display: 'flex', minHeight: '100vh' }} data-test="app-menu">

    <Menu menu={props.menu}/>
    <Box flex={1} sx={{mt: {xs: '40px', md: 0}, ml: {md: '272px'}, mb: '150px', width: 1}}>
      {props.children}
    </Box>
  </Box>
}

export default Layout;

const DRAWER_WIDTH = 272;

export const withLayout = (WrappedComponent: any, menu: IMenu | (() => Promise<IMenu>)) => (props: any) => {
  return <Layout menu={menu}>
    <WrappedComponent {...props}/>
  </Layout>
}

export interface IMenu {top: IMenuItem[], bottom?: IMenuItem[]}

const Menu = (props: {menu: IMenu | (() => Promise<IMenu>) }) => {
  const [menu, setMenu] = React.useState<IMenu>();
  const [showDrawer, setShowDrawer] = React.useState(false);

  React.useEffect(() => {
    async function f() {
      if (_.isFunction(props.menu)) {
        setMenu(await props.menu())
      } else {
        setMenu(props.menu)
      }
    }
    f();
  }, [props.menu])



  return <>
    <Box sx={{display: {xs: 'none', md: 'initial'}}}>
      <MenuPaper menu={menu}/>
    </Box>

    <Box sx={{height: 60, position: 'fixed', width: 1, borderBottom: '1px solid #DDE1E3', backgroundColor: '#FCFCFC', zIndex: 100,  display: {xs: 'flex', md: 'none'}, justifyContent: 'center', alignItems: 'center'}}>
      <IconButton
          onClick={() => setShowDrawer(true)}
          sx={{position: 'absolute', left: 10, top: 10}}
      >
        <img src={'/img/teenyicons/menu.svg'} width={25} height={25} />
      </IconButton>
      <img src={'/img/MDliaison_logo.png'} width={151} height={32} />
    </Box>
    {showDrawer && <Box sx={{position: 'fixed', width: 1, height: 1, background: 'rgba(0,0,0,0.27)', zIndex: 1000}} onClick={() => setShowDrawer(false)}>
      <MenuPaper menu={menu}/>
      <IconButton
          onClick={() => setShowDrawer(true)}
          sx={{position: 'absolute', left: DRAWER_WIDTH - 45, top: 10, zIndex: 1001}}
      >
        <img src={'/img/teenyicons/x.svg'} width={25} height={25} />
      </IconButton>
    </Box>}

  </>
}


const MenuPaper = ({menu}: {menu?: IMenu}) => {
  const isMobile = useIsMobile();

  return <Box sx={{
    top: 0,
    bottom: 0,
    position: 'fixed',
    width: DRAWER_WIDTH,
    p: {xs: '0 56px 0 16px', md: 3},
    backgroundColor: 'white',
    borderRight: '1px solid #E1E1E1',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto'
  }}>
    <div>
      <Box component={'img'} sx={{display: {xs: 'none', md: 'inherit'}}} src={'/img/MDliaison_logo.png'} width={151} height={32} />
      <List sx={{mt: {md: '32px'}}}>
        {menu && menu.top.map(item => <MenuItem key={item.title} {...item} />)}
      </List>
    </div>
    {menu && menu.bottom && <div>
        <List>
          {isMobile && <MenuItem title={'Support'} icon={'question'} onClick={() => document.location.href = 'https://mdliaison.atlassian.net/servicedesk/customer/portal/1'} />}
          {menu.bottom.map(item => <MenuItem key={item.title} {...item} />)}
        </List>
    </div>}
  </Box>
}


interface IMenuItem {
  title: string,
  icon?: string,
  onClick?: () => void,
  route?: string,
  notifications?: number,
}

const MenuItem = (props: IMenuItem) => {
  const navigate = useNavigate();

  /**
   * @TODO Rewrite to NavLink with active status
   */
  // const active = React.useMemo(() => {
  //   return props.location.pathname === props.route
  // }, [props.location.pathname])

  const active = false;
  return <ListItemButton sx={{'&:hover': {backgroundColor: '#F0F8FD'}, backgroundColor: active ? '#F0F8FD' : 'white', borderRadius: '8px', cursor: 'pointer', mb: 1.5}}
                         onClick={() => props.route ? navigate(props.route) : props.onClick && props.onClick()}>
    {props.icon && <Box component={'img'} src={`/img/teenyicons/${props.icon}${active ? '-blue' : ''}.svg`}  width={15} height={15} />}
    <ListItem sx={{cursor: 'pointer', fontWeight: active ? 600 : 400, color: active ? '#3075B8' : '#000000'}}>{props.title}</ListItem>
    {/*!!props.notifications && <Box sx={{background: '#3075b8', px: '6px', borderRadius: '4px'}}><Typography sx={{fontSize: 12, lineHeight: '20px', fontWeight: 700, color: 'white'}} >{props.notifications}</Typography></Box>*/}
    {!!props.notifications && <Box sx={{background: '#FF0000', height: '6px', width: '6px', borderRadius: '4px', ml: .5}}></Box>}
  </ListItemButton>
}
