import {useMigrateStripeAccountsMutation,} from "../../../api/sdl";
import {LoadingButton} from "@mui/lab";
import React from "react";

export function MigrateStripeAccounts() {


  const [migrate, migrateData] = useMigrateStripeAccountsMutation();

  return <LoadingButton onClick={() => migrate()} loading={migrateData.loading} variant={"contained"}>
    Migrate Stripe Accounts
  </LoadingButton>
}