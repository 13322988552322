import {Box, Button, Typography, CircularProgress} from "@mui/material";
import React, {useContext} from "react";
import {
  Navigate,
  Route, Routes,
} from 'react-router-dom';

import {SignIn, SignOut} from 'phosphor-react';
import {BrowserRouter} from "react-router-dom";
import AttributeList from "./pages/attributes/AttributeList";
import AttributeForm from "./pages/attributes/AttributeForm";
import CompanyList from "./pages/companies/CompanyList";
import PositionListByCompany from "../feature/position-list-by-company/PositionListByCompany";
import {AdminPositionPage} from "../domain/matching_process";
import PrivateRoute from "../domain/routing/PrivateRoute";
import Layout from "../shared/components/Layout";
import {client} from "../ApolloClient";
import {gql} from "graphql-tag";
import CreateContractor from "./pages/contractors/CreateContractor";
import EmailList from "./pages/emails/EmailList";
import EmailForm from "./pages/emails/EmailForm";
import {keycloak, KeycloakContext} from "../keycloak";
import Manager from "./pages/companies/Manager";
import InvoiceList from "./pages/invoices/InvoiceList";
import ExternalContractorList from "./pages/external/ExternalContractorList";
import AdminContractList from "./pages/contracts/AdminContractList";
import useIsAdmin from "../shared/hooks/useIsAdmin";
import AdminContract from "./pages/contracts/AdminContract";
import {hasKeycloakRole} from "../shared/utils";
import ServicePage from "./pages/service/ServicePage";
import CompanyMeetings from "../company/pages/company-meetings";
import {GoogleWorkspaceCodeReader} from "../feature/google-workspace-code-reader";
import Notifications from "src/feature/notifications";
import {LastScheduledEmails} from "../feature/service";
import {PositionListActive} from "../feature/position-list-active/PositionListActive";
import {ContractorsPage} from "./pages/contractors/ContractorsPage";
import {ReschedulePage} from "../domain/matching_process/page/ReschedulePage";

function AdminApp() {
  const {initialized, keycloak} = useContext(KeycloakContext);
  const isAdmin = useIsAdmin();

  const authorized = React.useMemo(() => {
    return initialized && keycloak?.authenticated && isAdmin
  }, [initialized, keycloak?.authenticated, isAdmin])

  if (!initialized) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
      <CircularProgress size="15" />
    </Box>
  }

  if (initialized && !authorized) {
    return <Box sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
      <Typography variant={'h2'} color={'error'}>You are not authorized</Typography>
      <Typography >It looks like your session has expired.
        We have disabled your access for security reasons.</Typography>

      <Box sx={{flexDirection: "row", gap: 2, p: 4, width: '400px', justifyContent: "space-between", display: 'flex'}}>
        <Button
          variant={'outlined'} color={'primary'}
          onClick={() => {
            return keycloak.login({redirectUri: document.location.href})}
        }
          startIcon={<SignIn size={24} color="#37c9ef" weight="regular" />}
        >Login Again</Button>

        <Button variant={'outlined'} color={'primary'}
                onClick={() => keycloak.logout({redirectUri: document.location.origin})}
                startIcon={<SignOut size={24} color="#37c9ef" weight="regular" />}>Logout</Button>
      </Box>

    </Box>
  }

  return <></>
  // return (
  //   <BrowserRouter basename={'/admin'}>
  //     <Layout menu={menu}>
  //       <Box flex={1} sx={{px: 2, py: 4}}>
  //
  //         <Switch>
  //           <PrivateRoute exact path={'/google-workspace/'} component={GoogleWorkspaceCodeReader} />
  //           <PrivateRoute exact path={'/contracts'} component={AdminContractList} />
  //           <PrivateRoute exact path={'/contract/:id?'} component={AdminContract} />
  //           {/*<PrivateRoute exact path={'/calendar'} component={CalendarPage} />*/}
  //           {/*<PrivateRoute exact path={'/schedule'} component={CalendarPage} />*/}
  //           <PrivateRoute exact path={'/reschedule/:id'} component={ReschedulePage} />
  //           <PrivateRoute exact path={'/companies'} component={CompanyList} />
  //           <PrivateRoute exact path={'/invoices'} component={InvoiceList} />
  //           <PrivateRoute exact path={'/company/:id'} component={PositionListByCompany} />
  //           <PrivateRoute exact path={'/company/:id/manager'} component={Manager} />
  //           <PrivateRoute exact path={'/company/:companyId/position/:positionId?/:contractorId?'} component={AdminPositionPage} />
  //           <PrivateRoute exact path={'/emails'} component={EmailList} />
  //           <PrivateRoute exact path={'/meetings'} component={CompanyMeetings} />
  //           <PrivateRoute path={'/email/:id?'} component={EmailForm} />
  //           <PrivateRoute exact path={'/contractors'} component={ContractorsPage} />
  //           <PrivateRoute exact path={'/contractor/:id'} component={ContractorDetails} />
  //           <PrivateRoute exact path={'/contractor'} component={CreateContractor} />
  //           <PrivateRoute exact path={'/attributes'} component={AttributeList} />
  //           <PrivateRoute path={'/attribute/:id?'} component={AttributeForm} />
  //           <PrivateRoute exact path={'/notifications'} component={Notifications} />
  //           <PrivateRoute exact path={'/external'} component={ExternalContractorList} />
  //           <PrivateRoute exact path={'/service'} component={ServicePage} />
  //           <PrivateRoute exact path={'/service/scheduled-emails'} component={LastScheduledEmails} />
  //           <PrivateRoute exact path={'/positions'} component={PositionListActive} />
  //           <Route>
  //             <Redirect to={'/contractors'} />
  //           </Route>
  //         </Switch>
  //       </Box>
  //     </Layout>
  //   </BrowserRouter>
  // );
}

export default AdminApp;

export const adminMenu = async () => {
  const notifications = client.query({
    query: gql`{myNotificationCounter}`,
    fetchPolicy: 'no-cache'
  })
  const result = {
    top: [
      {
        title: 'Notifications',
        route: '/admin/notifications',
        icon: 'bell',
        notifications: await notifications.then(r => r.data.myNotificationCounter),
      },
      {
        title: 'Contractors',
        route: '/admin/contractors',
        icon: 'users',
      },
      {
        title: 'Companies',
        route: '/admin/companies',
        icon: 'briefcase',
      },
      {
        title: 'Positions',
        route: '/admin/positions',
        icon: 'users',
      },
      {
        title: 'Contracts',
        route: '/admin/contracts',
        icon: 'briefcase',
      },

      {
        title: 'Invoices',
        route: '/admin/invoices',
        icon: 'briefcase',
      },
      {
        title: 'My meetings',
        route: '/admin/meetings',
        icon: 'appointments',
      },
      {
        title: 'External contractors',
        route: '/admin/external',
        icon: 'users',
      },
    ],
    bottom: [
      {
        title: 'Attributes',
        route: '/admin/attributes',
        icon: 'cog',
      },
      {
        title: 'Email templates',
        route: '/admin/emails',
        icon: 'envelope',
      },
      {
        title: 'Logout',
        icon: 'user-circle',
        onClick: () => keycloak.logout({redirectUri: document.location.origin})
      }
    ]
  }

  if (hasKeycloakRole('ROLE_TECH_ADMIN')) {
    result.bottom.unshift({
      title: 'Service',
      route: '/admin/service',
      icon: 'cog',
    })
  }

  return result;
}
