import {
  ContractorSearchPayloadInput,
  ContractorStatus
} from "../../../api/sdl";
import {Box, IconButton, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import React from "react";
import {useDebouncedCallback} from "../../../shared/hooks/useDebouncedCallback";
import {XCircle} from "phosphor-react";
import AttributesFilter from "../../../shared/components/AttributesFilter";
import StatesFilter from "../../../shared/components/StatesFilter";

const ContractorFilter = ({filter, ...props}: {filter: ContractorSearchPayloadInput, onChange: (filter: ContractorSearchPayloadInput) => void, hideStatus?: boolean}) => {
  const [term, setTerm] = React.useState(filter.term);

  React.useEffect(() => {
    setTerm(filter.term)
  }, [filter.term])

  const onChange = useDebouncedCallback((filter: ContractorSearchPayloadInput) => {
    props.onChange(filter)
  }, 500)

  React.useEffect(() => {
    if (term !== filter.term) {
      onChange({...filter, term})
    }
  }, [term, filter])

  return <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', flex: 1, justifyContent: 'flex-end', gap: 2}}>
    <AttributesFilter ids={filter.attributes || []} onChange={attributes => props.onChange({...filter, attributes})} />

    <TextField placeholder="Search"
           size={'small'}
           sx={{width: {xs: 1, md: 0.2}}}
           value={term}
           data-test='search'
           onChange={e => setTerm(e.target.value)}
           InputProps={{
             endAdornment: term && <InputAdornment position="end">
               <IconButton
                   size="small"
                   color="secondary"
                   data-test={"clear-contractor-filter-icon"}
                   onMouseDown={(event) => {
                     event.stopPropagation();
                   }}
                   onClick={() => {
                     setTerm('');
                   }}
               >
                 <XCircle size={20} color="#000000" weight="light" />
               </IconButton>
             </InputAdornment>
           }}

    />
    <StatesFilter value={filter.state || undefined} onChange={state => props.onChange({...filter, state})} />

    {!props.hideStatus && <Select size={'small'}
            placeholder={'Status'}
            data-test='status'
            value={filter.status || 'all'}
            onChange={(e) => (
              props.onChange({...filter, status: e.target.value === 'all' ? undefined : e.target.value as ContractorStatus})
            )}
    >
      <MenuItem value={'all'}>All statuses</MenuItem>
        <MenuItem value={ContractorStatus.Pending}>Pending</MenuItem>
        <MenuItem value={ContractorStatus.OnboardingCompleted}>Onboarding Completed</MenuItem>
        <MenuItem value={ContractorStatus.PreApprovedInterview}>Pre Approved</MenuItem>
        <MenuItem value={ContractorStatus.FillingVideo}>Filling video</MenuItem>
        <MenuItem value={ContractorStatus.FillingAssessment}>Filling assessment</MenuItem>
        <MenuItem value={ContractorStatus.Approved} sx={{color: 'green'}}>Approved</MenuItem>
        <MenuItem value={ContractorStatus.Archived} sx={{color: 'red'}}>Archived</MenuItem>
        <MenuItem value={ContractorStatus.Declined} sx={{color: 'red'}}>Declined</MenuItem>
    </Select>}
  </Box>
}

export default ContractorFilter;
