import {Box, Button, MenuItem, Select, Typography} from "@mui/material";
import {durationToString} from "../lib/utils";
import React, {useState} from "react";
import {MeetingDuration} from "../../../api/sdl";

interface Props {
  duration: MeetingDuration,
  onChange: (value: MeetingDuration) => void
}
export function ChangeMeetingDuration(props: Props) {
  const [formMode, setFormMode] = useState(false);

  const handleChangeClick = () => {
    if(!formMode) {
      setFormMode(true)
    }
  }
  const handleSelectChange = (event: any) => {
    props.onChange(event.target.value as MeetingDuration)
    setFormMode(false)
  };

  if(formMode) {
    return <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Select value={props.duration} onChange={handleSelectChange} size={"small"} fullWidth={true} data-test="meeting-duration-selector">
        <MenuItem value={MeetingDuration.Minutes_30}>30 Minutes</MenuItem>
        <MenuItem value={MeetingDuration.Minutes_45}>45 Minutes</MenuItem>
        <MenuItem value={MeetingDuration.Minutes_60}>1 Hour</MenuItem>
      </Select>
    </Box>
  }

  return <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
    <Typography sx={{color: '#949494', fontSize: '14px',}}>
      Duration: {durationToString(props.duration)}
    </Typography>
    <Button onClick={handleChangeClick} variant={"text"} size={"small"} data-test={"change-duration"}>Change</Button>
  </Box>

}