import {Box, styled, Typography} from "@mui/material";
import {DateTime} from "luxon";
import React from "react";
import {FutureMeetingsQuery} from "../../../api/sdl";
import {useIsAdmin} from "../../../shared";
import RouterLink from "../../../feature/router-link";
import {candidateLink} from "../lib/utils";

export function MeetingDetails({item}: {item: FutureMeetingsQuery["futureMeetings"][0]} ) {
  const isAdmin = useIsAdmin();

  return <Box flex={1}>
    <HeaderBox>
      <Typography  sx={{fontWeight: 600}}>{item.positionTitle}</Typography>
      {isAdmin &&
          <>at <RouterLink to={`../company/${item.companyId}` }>{item.companyName}</RouterLink></>
      }
      <Dot />
      <Typography  sx={{fontWeight: 600}}>{DateTime.fromISO(item.date).toFormat('LLL d, h:mm a')} - {DateTime.fromISO(item.date).plus({hour: 1}).toFormat('h:mm a')}</Typography>
    </HeaderBox>

    <RouterLink to={candidateLink(isAdmin, item)} data-test={"contractor-name"}>
      {item.contractorName ? `${item.contractorName} (Candidate #${item.candidateNumber})` : `Candidate #${item.candidateNumber}`}
    </RouterLink>
  </Box>
}

const HeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: 3,
});

const Dot = styled(Box)({
  background: '#2C92D5',
  borderRadius: '24px',
  width: 4,
  height: 4,
});
