import {Box, Skeleton, Stack} from "@mui/material";
import React from "react";

export function CalendarSkeleton() {
  const height= 39;
  return <Stack spacing={2}>
    <Box sx={{justifyContent: "flex-end", display: 'flex'}}>
      <Skeleton variant="rounded" height={'18px'} width={'220px'} />
    </Box>
    <Box sx={{display: 'flex', alignContent: "center", gap: 2}}>
      <Skeleton variant="rounded" height={height} width={'100%'} />
      <Skeleton variant="rounded" height={height} width={'100%'}/>
      <Skeleton variant="rounded" height={height} width={'100%'} />
      <Skeleton variant="rounded" height={height} width={'100%'} />
      <Skeleton variant="rounded" height={height} width={'100%'} />
    </Box>
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
    <Skeleton variant="rounded" height={height} />
  </Stack>
}