import {CandidateMatchApiData} from "./lib/types";
import {CandidateMatchCard} from "./ui/CandidateMatchCard";
import {Box} from "@mui/material";

interface Props {
  candidates: CandidateMatchApiData[],
  onRemoveCandidate: (positionMatchId: string, notifyContractor: boolean) => void,
  onConfirm: (positionMatchId: string) => void,
  onProfileReview: (contractorId: string) => void,
}

export function AdminPositionMatchList({candidates, onRemoveCandidate, onConfirm, onProfileReview}: Props) {

  return <Box sx={{gap: 3, display: "flex", flexDirection: "column"}}>
    {candidates.map(it =>
      <CandidateMatchCard candidateMatch={it}
                          onRemoveClick={() => onRemoveCandidate(it.id!!, false)}
                          onRemoveWithNotificationClick={() => onRemoveCandidate(it.id!!, true)}
                          onConfirm={()=> onConfirm(it.id!!)}
                          onProfileReview={() => onProfileReview(it.contractorId!!)}
                          key={it.id}/>
    )}
  </Box>
}