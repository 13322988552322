import {Box, Stack} from "@mui/material";
import React from "react";

export function ErrorBoundary() {
  return <>
    <Stack sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      marginTop: "-200px"
    }} spacing={2}>
      <Box component={'img'} src={`/img/broken_robot_round_head_pictogram.svg`} width={250} height={250}/>
      <h1>Something went wrong</h1>
      <p>Please, try again later.</p>
    </Stack>

  </>
}