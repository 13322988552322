import {Box, Divider, Stack, Typography,  } from "@mui/material";
import {WorkContractOutput, WorkContractStatus,} from "../../../../../api/sdl";
import React from "react";
import { toCurrency } from "../../../../../shared/utils";

const AdminContractListItem = ({item, onClick}: {item: WorkContractOutput, onClick?: () => void}) => {

    return <Box data-test='contract' sx={{display: 'flex', cursor: onClick ? 'pointer' : undefined, alignItems: 'center', gap: 3, background: 'white', border: '1px solid #E4E4E4', borderRadius: '10px', p: '16px 18px', mb: '20px'}}
    onClick={onClick}
    >
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, background: '#E4E4E7', borderRadius: '24px', width: 48, height: 48}}>
            <Box component={'img'} src={`/img/teenyicons/appointments.svg`} width={22} height={22} />
        </Box>
        <Box flex={1}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', mb: '6px'}}>
                <Typography  sx={{fontWeight: 400}}>{item.companyName}</Typography>
                <Typography  sx={{fontWeight: 400}}>{item.contractorName}</Typography>
                <Typography color={contractStatusColor(item.status)} sx={{fontWeight: 600, ml: 'auto'}}>{item.status}</Typography>
            </Box>
            <Typography  sx={{fontWeight: 600}}>Summary</Typography>
            <Box sx={{display: "flex", gap: 2}}>
                <Typography  sx={{fontWeight: 400}}>Fee: {toCurrency(item.pendingFee, 2, 2)}</Typography>
                <Typography  sx={{fontWeight: 400}}>Payout: {toCurrency(item.pendingPayouts, 2, 2)}</Typography>
                <Typography  sx={{fontWeight: 400}}>Pending: {toCurrency(item.pendingInvoices, 2, 2)}</Typography>
            </Box>
            <Box sx={{py: 1}}>
                <Typography  sx={{fontWeight: 400}}><b>Start on:</b> {item.anticipatedStartDate}</Typography>
            </Box>
        </Box>
    </Box>
}

export default AdminContractListItem


const contractStatusColor = (status?: WorkContractStatus) => {
    switch (status) {
      case WorkContractStatus.Active: return '#1ec129';
      case WorkContractStatus.Closed: return '#d32f2f';
      default: return '#dfa21e'
    }
  }
