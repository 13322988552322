import React, {useMemo, useState} from 'react';
import {Box, Button} from "@mui/material";
import {CityFormValue} from "./lib/types";
import {CitySelectorItem} from "./ui/CitySelectorItem";


interface CitySelectorProps{
  value: CityFormValue[],
  onChange: (value: string[]) => void,
}
const CitySelector = (props: CitySelectorProps) => {

  const initialValue = useMemo(() => {
    if(props.value.length === 0) {
      return [null]
    } else {
      return props.value.map((it) => ({
        id: it.id,
        stateShort: it.stateShort,
      })) as CityFormValue[]
    }
  }, [props.value]);

  const [result, setResult] = useState<(CityFormValue | null)[]>(initialValue);

  const removeCity = (index: number) => {

    setResult((previous) => {
      let value = [...previous]
      value.splice(index, 1);

      return value
    })
  }

  const addCity = () => {
    setResult((previous) => {
      return [...previous, null]
    });
  }

  const updateCity = (index: number, value: CityFormValue) => {

    let output = [...result]
    output[index] = value
    setResult(output)
  }

  React.useEffect(() => {
    const value = [...result]

    const output = value.filter((it) => it != null && it.id !== "").map((it) => it?.id) as string[]

    props.onChange(output)
  }, [result]);

  const getCity = (id?: string | null) => {

    if(!id) {
      return undefined;
    }

    const filtered = props.value.find((it) => it.id === id)
    if(!!filtered?.id && !!filtered?.stateShort) {

      return {
        id: filtered!!.id,
        stateShort: filtered!!.stateShort,
      } as CityFormValue
    } else {
      return undefined
    }
  };
  return <>
    <Box>
      {result.map((cityValue, index) =>
      <Box sx={{display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: 2}} data-test={`city-row-${index}`} key={index}>
        <CitySelectorItem onChange={(v) => {updateCity(index, v)}} value={getCity(cityValue?.id)} />
        <Box sx={{alignContent: "center", marginTop: 2}}>
          {index !== 0 &&
            <Button
              data-test={`remove-city-${index}`}
              onClick={() => {removeCity(index)}}>Remove</Button>
          }
        </Box>
      </Box>
      )}
      <Box>
        <Button
          data-test={"add-city"}
          onClick={() => addCity()}>Add another location</Button>
      </Box>

    </Box>

  </>
}
export {CitySelector}
