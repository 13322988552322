import React from 'react';
import {MeetingCard, MeetingList} from "../entity";
import {Box} from "@mui/material";
import {MeetingDuration} from "../api/sdl";
import {DateTime} from "luxon";
import {MeetingListItem} from "../domain/matching_process/lib/types";

export function MeetingListStory() {
  const meetings = [
    {
      "meetingScheduledOn": DateTime.fromISO("2024-05-15T07:00:00Z"),
      "zoomMeetingLink": "N/A",
      "id": "6d101f81-c553-4d01-9a5c-b66692bf1c94",
      "duration": MeetingDuration.Minutes_30.toString(),
      "guests": [
        {
          "name": "test",
          "email": "front444@yopmail.com",
        },
        {
          "name": "Vertical test",
          "email": "ascend-qamp@yopmail.com",
        }
      ],
    },
    {
      "meetingScheduledOn": DateTime.fromISO("2024-05-15T09:00:00Z"),
      "zoomMeetingLink": "N/A",
      "id": "732f6486-64d3-4fd6-83a0-9bff2b9a5bb8",
      "guests": [],
      "duration": MeetingDuration.Minutes_30.toString(),
    },
    {
      "meetingScheduledOn": DateTime.fromISO("2024-05-15T10:00:00Z"),
      "zoomMeetingLink": "N/A",
      "id": "df7210b9-7a24-46d9-8e54-3746457d06e6",
      "guests": [
        {
          "name": "Test template",
          "email": "ascend-qamp@yopmail.com",
        }
      ],
      "duration": MeetingDuration.Minutes_30.toString(),
    },
    {
      "meetingScheduledOn": DateTime.fromISO("2024-05-23T14:00:00Z"),
      "zoomMeetingLink": "N/A",
      "id": "90e5f051-321d-4b64-a37a-02cb6e627580",
      "guests": [],
      "duration": MeetingDuration.Minutes_30.toString(),
    },
    {
      "meetingScheduledOn": DateTime.fromISO("2024-05-23T09:00:00Z"),
      "zoomMeetingLink": "N/A",
      "id": "a28f721f-095d-4bb0-bd13-514256d57a00",
      "guests": [
        {
          "name": "Cherese",
          "email": "cherese@mdliaison.com",
        }
      ],
      "duration": MeetingDuration.Minutes_30.toString(),
    },
    {
      "meetingScheduledOn": "2024-05-26T11:00:00Z",
      "zoomMeetingLink": "N/A",
      "id": "d4c1a485-7812-493b-b1f1-62e2304ac058",
      "guests": [
        {
          "name": "Some man",
          "email": "testuser@email.com",
        },
        {
          "name": "another",
          "email": "asidorov01@gmail.com",
        }
      ],
      "duration": MeetingDuration.Minutes_30.toString(),
    }
  ] as MeetingListItem[]


  return <div>
    <Box sx={{display: 'flex', gap: 10}}>
      <Box sx={{width: 300}}>
        <MeetingList meetings={meetings}
                     positionMatchId={'f9c3da7d-bed6-4df4-9f0e-be7a94a6a687'}
                     onCancelMeeting={() => {}}
                     onScheduleClick={() => {}}
                     title={'Some'}/>
      </Box>


      <Box sx={{width: 600}}>
        <MeetingCard meeting={meetings[0] as any}  onGuestRemoveClick={() => {}}  />
        <MeetingList meetings={meetings}
                     positionMatchId={'f9c3da7d-bed6-4df4-9f0e-be7a94a6a687'}
                     onCancelMeeting={() => {}}
                     onScheduleClick={() => {}}
                     title={'Some long title'}/>
      </Box>
    </Box>


    <hr/>

    <MeetingList meetings={meetings}
                 onCancelMeeting={() => {}}
                 onScheduleClick={() => {}}
                 positionMatchId={'f9c3da7d-bed6-4df4-9f0e-be7a94a6a687'}
                 title={'Some'}/>
  </div>
}