import React, {useContext, useMemo} from "react";
import {ProfileContext} from "../../shared/ProfileContext";
import {ContractorStatus} from "../../api/sdl";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {LayoutWithMenu} from "../../layout";
import contractorMenu from "../../layout/contractorMenu";

enum Mode {
  ONBOARDING = "ONBOARDING", APPROVED= "APPROVED"
}

export function ContractorRoute() {

  const [profile] = useContext(ProfileContext)
  const location = useLocation()

  const mode = useMemo(() => {
    if([
      ContractorStatus.Approved,
      ContractorStatus.FillingAssessment,
      ContractorStatus.PreApprovedInterview,
      ContractorStatus.FillingVideo].includes(profile!.contractorProfile!.status!)) {
      return Mode.APPROVED
    } else {
      return Mode.ONBOARDING
    }
  }, [profile!.contractorProfile!.status!]);

  const verified = useMemo(() => {

    const onboardingRoutes = [
      '/contractor/onboarding-completed',
      '/contractor/personal-info'
    ];

    if(onboardingRoutes.includes(location.pathname) && mode === Mode.ONBOARDING ) {return true}
    if(!onboardingRoutes.includes(location.pathname) && mode === Mode.APPROVED ) {return true}

    if(onboardingRoutes.includes(location.pathname) && mode === Mode.APPROVED ) {return false}
    if(!onboardingRoutes.includes(location.pathname) && mode === Mode.ONBOARDING ) {return false}

    return false;
  }, [mode, location.pathname]);

  //Fall back if something goes wrong.
  if(!verified) {
    if(mode === Mode.APPROVED) {
      return <Navigate to={'/contractor/notifications'}  />
    } else {
      return <Navigate to={'/contractor/personal-info'} />
    }
  }

  return <LayoutWithMenu menu={contractorMenu} showMenu={mode === Mode.APPROVED} />
}

