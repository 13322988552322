import {
  CompanyNameListQuery, ContractorSearchTableQuery,
  JointProfileOutput,
  useCompanyNameListQuery, useContractorSearchTableLazyQuery,
  useContractorSearchTableQuery
} from "../../../../../api/sdl";
import {useMemo} from "react";
import {useDebouncedEffect} from "../../../../../shared/hooks/useDebouncedEffect";

interface ReturnType {
  contractors: ArrayElement<ContractorSearchTableQuery['contractorSearch']['data']>[],
  loading: boolean
}

export default function useContractors(term = ''): ReturnType {
  const [getContractors, contractorData] = useContractorSearchTableLazyQuery();

  useDebouncedEffect(() => {
    getContractors({
      variables: {
        payload: { attributes: [], term },
        page: { size: 50, page: 0 }
      },
    })
  }, 500, [term])

  const contractors = useMemo(() => {
    return contractorData.data?.contractorSearch.data || []
  }, [contractorData.data]);

  return {contractors, loading: contractorData.loading};
}
