import {
  Box,
  Container, Typography
} from "@mui/material";
import React from "react";
import {
  ContractorStatus,
  PositionFilterForContractorInput,
  PositionOrder, PositionOutput, usePositionSearchLazyQuery
} from "../../api/sdl";
import {LayoutType} from "../../shared/types";
import ContractorPositionList from "../components/ContractorPositionList";
import ContractorPositionFilter from "../components/ContractorPositionFilter";
import {ProfileContext} from "../../shared/ProfileContext";



const ContractorPositions = () => {
  const [layoutType, setLayoutType] = React.useState<LayoutType>(localStorage.getItem('contractor_positions_layout_type') as LayoutType || 'grid');
  const [filter, setFilter] = React.useState<PositionFilterForContractorInput>(() => {
    const stored = localStorage.getItem('contractor_positions_filter');
    if (stored) {
      return JSON.parse(stored)
    } else {
      return {
        orderBy: PositionOrder.Recent,
      }
    }
  });
  const [openPositions, setOpenPositions] = React.useState<PositionOutput[]>([]);
  const [positions, setPositions] = React.useState<PositionOutput[]>([]);

  const [profile] = React.useContext(ProfileContext);

  const [getOpenPositions, {loading}] = usePositionSearchLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {page: {size: 200, page: 0}, filter},
  });

  React.useEffect(() => {
    setOpenPositions([]);
    const {locations, attributes,  ...rest} = filter;
    getOpenPositions({
      variables: {
        filter: {...rest, locations: locations?.length ? locations : null, attributes: attributes?.length ? attributes : null},
        page: {page: 0, size: 1000}
      }}).then(res => {
      setOpenPositions(res.data?.positionSearch.data.filter(p => !p.contractorAlreadyApplied) || [])
      setPositions(res.data?.positionSearch.data.filter(p => !!p.contractorAlreadyApplied) || [])
    })
  }, [filter])


  React.useEffect(() => {
    localStorage.setItem('contractor_positions_layout_type', layoutType);
  }, [layoutType])

  React.useEffect(() => {
    localStorage.setItem('contractor_positions_filter', JSON.stringify(filter));
  }, [filter])

  return <Box>
    <Container sx={{my: {xs: 8, md: 10}}} maxWidth={'xl'}>

      {profile?.contractorProfile?.status === ContractorStatus.Approved && <>
          <ContractorPositionFilter filter={filter}
                                    onFilterChange={setFilter}
                                    loading={loading}
                                    layoutType={layoutType}
                                    onLayoutTypeChange={setLayoutType}
          />

          <Box sx={{display:{xs:'flex', md:'none'}, height:'42px', paddingBottom:'64px'}}>
            <Typography variant={'h2'} sx={{marginBlock:'auto'}}>Roles</Typography>
          </Box>
          <ContractorPositionList positions={positions}
                                  allPositions={openPositions}
                                  loading={loading}
                                  searchLoading={loading}
                                  view={layoutType}/>
      </>}
      {profile?.contractorProfile?.status !== ContractorStatus.Approved && (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 400}}>
          <Typography>Role openings are available after, for fully approved candidates.</Typography>
        </Box>
      )}
    </Container>
  </Box>;
}

export default ContractorPositions;


