import React from "react";
import {Box, Button, Card, FormControl, FormHelperText, FormLabel, Paper, TextField, Typography} from "@mui/material";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import {useFormik} from "formik";
import {DateTime} from "luxon";

export type BalanceFormValue = {
  startDate: string,
  endDate: string,
}
const schemaFields: any = {

  startDate: yup
    .date()
    .typeError('Date is required')
    .required('Date is required'),
  endDate: yup
    .date()
    .typeError('Date is required')
    .required('Date is required'),
}

interface Props {
  onSubmit: (value: BalanceFormValue) => void,
  onCancel: () => void,
}

export function BalanceReportRequestForm(props: Props) {

  const initialValues = {
    startDate: undefined,
    endDate: undefined,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        props.onSubmit({
          startDate: DateTime.fromJSDate(values.startDate!).toFormat('yyyy-LL-dd'),
          endDate: DateTime.fromJSDate(values.endDate!).toFormat('yyyy-LL-dd'),
        } as BalanceFormValue)
      }
    },
    validateOnChange: true
  });

  return <>
    <Paper sx={{maxWidth: '450px'}}>
      <Box ml={2} m={4} component={'form'} onSubmit={formik.handleSubmit} sx={{gap: 2, display: "flex", flexDirection: "column"}}>
        <Box sx={{display: 'flex', gap: 2, alignItems: "flex-end", justifyContent: "space-between"}}>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <DatePicker selected={formik.values.startDate}
                        autoComplete="off"
                        customInput={<TextField placeholder="Select the start date"
                                                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        />}
                        maxDate={new Date()}
                        onChange={(date) => formik.setFieldValue('startDate', date)}/>
            {(formik.touched.startDate && formik.errors.startDate) &&
                <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.startDate}</FormHelperText>}
          </FormControl>

          <FormControl>
            <FormLabel>End Date</FormLabel>
            <DatePicker selected={formik.values.endDate}
                        autoComplete="off"
                        customInput={<TextField placeholder="Select the end date"
                                                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        />}
                        maxDate={new Date()}
                        onChange={(date: Date) => formik.setFieldValue('endDate', date)}/>
            {(formik.touched.endDate && formik.errors.endDate) &&
                <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.endDate}</FormHelperText>}
          </FormControl>


        </Box>
        <Box sx={{display: "flex", gap: 2 , justifyContent: "space-between"}}>
          <Button onClick={props.onCancel}>Cancel</Button>
          <Button type={"submit"} variant={'contained'}>Request report</Button>
        </Box>
        <Box>
          <Typography variant={"body2"}>Once you hit <strong>Request report</strong> you will be notified via email when the report is ready for download</Typography>
        </Box>
      </Box>
    </Paper>

  </>
}