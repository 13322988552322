import { createTheme } from '@mui/material';


const Theme = createTheme({
  /*colorSchemes: {
    light: {
      palette: {
        text: {
          primary: '#000',
          secondary: '#5D5E5F',
          tertiary: '#3075B8'
        },
        primary: {
          50: '#68D5F2',
          100: '#9ae2f0',
          200: '#39C8EE',
          300: '#23C1EB',
          400: '#15B4DF',
          500: '#2C92D5',
          600: '#3075B8',
          700: '#107B98',
          800: '#0E6880',
          900: '#0C5569',
        }
      }
    }
  },*/
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
          textTransform: 'none',
          borderRadius: '6px',
          fontSize: 14,
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          padding: '4px 12px',
        }
      },
      variants: [
        {
          props: {variant: 'text'},
          style: {
            textDecoration: 'underline',
            color: '#3075B8',
            '&:hover': {
              textDecoration: 'none',
            }
          }
        },
        {
          props: {variant: 'text', color: 'primary'},
          style: {
            color: '#3075B8',
            '&:hover': {
              color: '#185EA5'
            }
          }
        },
        {
          props: {variant: 'text', color: 'secondary'},
          style: {
            color: '#393939',
          }
        },
        {
          props: {variant: 'contained', color: 'primary'},
          style: {
            backgroundColor: '#2c92d5',
            '&:hover': {
              backgroundColor: '#185EA5'
            }
          }
        },
        {
          props: {variant: 'contained', color: 'error'},
          style: {
            backgroundColor: '#C41C1C',
            '&:hover': {
              backgroundColor: '#A51818'
            }
          }
        },
        {
          props: {variant: 'contained', color: 'secondary'},
          style: {
            backgroundColor: '#393939',
            '&:hover': {
              backgroundColor: '#393939'
            }
          }
        },
        {
          props: {variant: 'outlined', color: 'primary'},
          style: {
            color: '#2c92d5',
            backgroundColor: '#fff',
            borderColor: '#97C3F0',
            '&:hover': {
              backgroundColor: '#E3EFFB'
            }
          }
        },
        {
          props: {variant: 'outlined', color: 'secondary'},
          style: {
            color: 'rgb(50, 56, 62)',
            backgroundColor: '#fff',
            borderColor: '#CDD7E1',
            '&:hover': {
              backgroundColor: '#F0F4F8',
              borderColor: '#CDD7E1',
            }
          }
        }
      ]
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          marginBottom: 6,
          color: '#000',
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginLeft:0,
          "&.Mui-error": {
            color: "#d3232f"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'rgb(251, 252, 254)',
          '& .MuiOutlinedInput-input': {
            padding: '0 12px',
            minHeight: 34,
            background: 'rgb(251, 252, 254)',
            borderRadius: '6px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(205, 215, 225)',
          },
          '&:hover:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(205, 215, 225)!important',
          },
          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2c92d5!important',
          },
          '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d32f2f',
          },
          '&.Mui-error .MuiOutlinedInput-input::placeholder': {
            color: 'red'
          },
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiChip-root': {
            backgroundColor: '#3075b8',
            padding: 0,
            '& *': {
              color: '#fff'
            }
          },
          '& .MuiOutlinedInput-root': {
            padding: '0 65px 0 12px',
            '& .MuiAutocomplete-input': {
              padding: 'initial',
            },
          }
        },
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]:before': {
            display: 'none'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 34,
          display: 'flex',
          alignItems: 'center',
        },
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgb(205, 215, 225)',
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: '#37c9ef',
          borderBottom: '1px solid'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        lineHeight: 1.5,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#fff',
          // fontSize: 10,
          // fontWeight: 500,
          // height: 20,
        },
        deleteIcon: {
          fontSize: 20,
          margin: '0 0 0 -6px',
          color: '#fff',
          '&:hover': {
            color: 'rgb(205, 215, 225)',
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#2c92d5',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#2c92d5',
          }
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#fff',
          border: '1px solid #CDD7E1',
          boxShadow: 'none',
        }
      }
    },
    MuiAlert: {
      variants: [
        {
          props: {variant: 'outlined', color: 'info'},
          style: {
            color: '#2c92d5',
          }
        }
      ]
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          fontWeight: 600,
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Plus Jakarta Sans', 'Roboto', 'Helvetica Neue', 'sans-serif'
    ].join(','),
  }
});


Theme.typography.h1 = {
  fontSize: 36,
  lineHeight: '54px',
  fontWeight: 400,
  color: '#000',
  [Theme.breakpoints.up('sm')]: {
    fontSize: 36,
    lineHeight: '54px',
  },
}

Theme.typography.h2 = {
  fontSize: 30,
  lineHeight: 1.5,
  color: '#000',
  fontWeight: 700,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 30,
  },
}

Theme.typography.h3 = {
  fontSize: 24,
  lineHeight: '36px',
  fontWeight: 600,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 24,
    lineHeight: '36px',
  },
}

Theme.typography.h4 = {
  fontSize: 18,
  lineHeight: '22px',
  fontWeight: 600,
}

Theme.typography.body1 = {
  fontSize: 14,
  lineHeight: 1.5,
}

Theme.typography.body2 = {
  fontSize: 12,
  lineHeight: 1.5,
  fontWeight: 300,
}

export default Theme;
