import {
  Box,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import {DataGrigColumn} from "../../shared/components/DataGrid";
import ContractorFilter from "./ui/ContractorFilter";
import {ContractorSearchPayloadInput, JointProfileOutput} from "../../api/sdl";
import {DateTime} from "luxon";
import {PlusCircle} from "phosphor-react";
import {ContractorSearchableList} from "./ui/ContractorSearchableList";
import useSessionStorageState from "../../shared/hooks/useSessionStorageState";
import {useNavigate} from "react-router-dom";

const columns: DataGrigColumn<Partial<JointProfileOutput>>[] = [{
  title: 'Created at',
  value: o => DateTime.fromISO(o.contractorProfile?.createdAt).toFormat('LL/dd/yyyy hh:mm a'),
  sortable: true,
}, {
  title: 'Name',
  value: o => o.profile?.firstName + ' ' + o.profile?.lastName
}, {
  title: 'Email',
  value: o => o.profile?.email || '',
}, {
  title: 'Status',
  value: o => o.contractorProfile?.status || '',
}, {
  title: 'Locations',
  value: o =>
    o.contractorProfile?.cities.map((it) => `${it.city}, ${it.stateShort}`).join("; ") || ''
  ,
}, {
  title: 'CV',
  value: o => o.contractorProfile?.hasParsedCv ? 'Y' : '',
},]

export function FullContractorList() {

  const navigate = useNavigate();
  const [filter, setFilter] = useSessionStorageState<ContractorSearchPayloadInput>(
    'contractorListFilter',
    {attributes: [], state: null, term: ''}
  );

  return <div>
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      alignItems: 'center',
      flex: 1,
      justifyContent: 'space-between',
      gap: 2
    }}>
      <Box sx={{display: 'flex'}}>
        <Typography variant={'h3'} sx={{ml: 2}}>Contractors</Typography>
        <IconButton sx={{borderRadius: '50%'}} size={'small'} onClick={() => navigate('../contractor')}>
          <PlusCircle size={24} color={"#000000"}/>
        </IconButton>
      </Box>
      <ContractorFilter filter={filter} onChange={setFilter}/>
    </Box>
    <ContractorSearchableList columns={columns}
                              filter={filter}
                              onRowClick={row => navigate('../contractor/' + row.contractorProfile?.contractorId)}/>
  </div>
}
