import {Box, MenuItem, Select} from "@mui/material";
import {AttributeCategory} from "../../api/sdl";
import * as _ from "lodash";
import React, {useEffect} from "react";

interface Props {
  value: string,
  onChange: (value: AttributeCategory) => void,
  defaultValue?: string,
}

/**
 // formik.setFieldValue('category', e.target.value)
 *
 * @param props
 * @constructor
 */
export function AttributeCategorySelector(props: Props) {
  useEffect(() => {

    console.log(props.value)
  }, [props.value])
  return <>
      <Select
        sx={{width: '250px'}}
        value={props.value}
        defaultValue={""}
        onChange={(e) => {
          props.onChange(e.target.value as AttributeCategory)
        }}
      >
        {props.defaultValue &&
            <MenuItem key="" value="">{props.defaultValue}</MenuItem>
        }
        {Object.keys(AttributeCategory).map(k => ({label: _.startCase(k), value: (AttributeCategory as any)[k]}))
          .map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
      </Select>
  </>
}