import {useIsAdmin} from "../../../shared";
import React from "react";
import {Box} from "@mui/material";
import {useUpdateAutoAttributesMutation} from "../../../api/sdl";
import {LoadingButton} from "@mui/lab";

interface Props {
  contractorId: string,
  onUpdate: () => void,

}
export function EmploymentHistoryEmploymentHistoryAutoAttributionButton({contractorId, onUpdate}: Props) {
  const isAdmin = useIsAdmin();

  const [request, {loading, called}] = useUpdateAutoAttributesMutation()

  const handleClick = async () => {
    await request({variables: {contractorId}})
    onUpdate()
  }
  if(!isAdmin) {
    return null
  }

  if(isAdmin) {
    return <Box>
      <LoadingButton loading={loading} size={"small"} variant={"outlined"} onClick={handleClick}>Update AI Attributes</LoadingButton>
    </Box>
  }

  return null;
}