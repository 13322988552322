import React, {useMemo} from "react";
import {ProfileContext} from "../../../shared/ProfileContext";
import ContractorPositionWidget from "../feature/contractor-position/ContractorPositionWidget";
import {PositionMatchContextProvider} from "../context/PositionMatchContext";
import {useParams} from "react-router-dom";


const ContractorPositionDetailsPageInternal = () => {

  const { positionId } = useParams();
  const [profile] = React.useContext(ProfileContext);

  const contractorId = useMemo(() => {
    return profile?.contractorProfile?.contractorId
  }, [profile]);

  return <PositionMatchContextProvider positionId={positionId!} contractorId={contractorId} >
    <ContractorPositionWidget/>
  </PositionMatchContextProvider>
}

export const ContractorPositionDetailsPage = ContractorPositionDetailsPageInternal
