import { HarvestProjectDto, useHarvestProjectsLazyQuery} from "../../api/sdl";
import {CircularProgress, FormControl, FormHelperText, FormLabel, MenuItem, Select} from "@mui/material";
import React, {useMemo} from "react";

interface HarvestProjectSelectProps {
  value: string | null,
  clientId?: string | null,
  onChange: (value: string | null) => void,
  error?: string,
  disabled?: boolean,
}

export default function HarvestProjectSelect(props: HarvestProjectSelectProps) {
  const {projects, loading} = useHarvestProjects(props.clientId)

  return <FormControl sx={{width: 1}} disabled={!projects.length} error={!!props.error}>
    <FormLabel >
      Harvest Project
    </FormLabel>
    <Select size={'small'}
            data-test='project'
            value={props.value}
            startAdornment={loading && <CircularProgress size="15" />}
            disabled={loading || props.disabled}
            onChange={(e) => props.onChange(e.target.value)}
    >
      {projects.map(v => (
        <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
      ))}
    </Select>
    {props.error && <FormHelperText sx={{color: '#D3232F'}}>{props.error}</FormHelperText>}
  </FormControl>

}


export const useHarvestProjects = (clientId?: string | null) => {
  const [projects, setProjects] = React.useState<HarvestProjectDto[]>([]);

  const [getProjects, { loading, called}] = useHarvestProjectsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: data => setProjects(data.harvestProjects || [])
  });

  React.useEffect(() => {
    setProjects([]);
    if (clientId) {
      getProjects({variables: {clientId: clientId}})
    }
  }, [clientId]);

  return {projects, loading: loading || !called}
}
