import { Box, Typography } from "@mui/material"

const TableRow = ({image, title, content}: {image: string, title: string, content: JSX.Element}) => {
    return <Box sx={{display: 'table-row'}}>
        <Box sx={{display: 'table-cell', verticalAlign: 'top', py: 1}}>
                <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
                <Box component={'img'} src={image}  width={16} height={16} sx={{marginTop: '4px'}}/>
                <Box sx={{ width: '100px'}}>
                  <Typography sx={{color: 'text.secondary'}} >{title}</Typography>

                </Box>
            </Box>
        </Box>
        <Box sx={{display: 'table-cell', pl: 2, verticalAlign: 'top', py: 1}}>
            {content}
        </Box>
    </Box>
}
export default TableRow
