import React from "react";
import {AttributeCategory, ContractorAttribute, useContractorAttributesByCategoryQuery} from "../../../api/sdl";

interface Props {
  roles: ContractorAttribute[],
  specialities: ContractorAttribute[],
  employmentTypes: ContractorAttribute[],
}

export const ContractorAttributesContext = React.createContext<Props>({
  roles: [],
  specialities: [],
  employmentTypes: [],
})

export function withContractorAttributes<P>(WrappedComponent: React.FC<P>) {
  return (props: P) => {
    const rolesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.RoleCategory}});
    const roles = React.useMemo(() => {
      return rolesData.data?.contractorAttributesByCategory || [];
    }, [rolesData.data]);

    const specialitiesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.CallPointSpeciality}});
    const specialities = React.useMemo(() => {
      return specialitiesData.data?.contractorAttributesByCategory || [];
    }, [specialitiesData.data]);

    const employmentTypesData = useContractorAttributesByCategoryQuery({variables: {category: AttributeCategory.EmploymentType}});
    const employmentTypes = React.useMemo(() => {
      return employmentTypesData.data?.contractorAttributesByCategory || [];
    }, [employmentTypesData.data]);

    return (
      <ContractorAttributesContext.Provider value={{
        roles,
        specialities,
        employmentTypes,
      }}>
        <WrappedComponent {...props} />
      </ContractorAttributesContext.Provider>
    )
  }
}
