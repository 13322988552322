import {Box, Card, List, ListItem, ListItemIcon, Typography} from "@mui/material";
import Loading from "../../shared/components/Loading";
import React from "react";
import useStripeAccount from "./api/use-stripe-account";
import AccountInput from "./ui/AccountInput";
import useSetStripeAccount from "./api/use-set-stripe-account";
import {AdminStripeAccountQuery} from "../../api/sdl";

interface Props {
  stripeAccount?: AdminStripeAccountQuery['adminStripeAccount'],
  loading: boolean,
  saveLoading: boolean,
  onSaveClick: (value: string) => void,
}

export function StripeAccountBase(props: Props) {
  const {saveLoading, onSaveClick, loading, stripeAccount} = props;

  return <Card sx={{p: '20px 24px'}}>
    <Typography sx={{color: '#8A8C8D', fontWeight: 500}}>Stripe status</Typography>
    <Loading in={loading} height={'100px'}>

      <AccountInput onSaveClick={onSaveClick} loading={saveLoading} />

      <List>
        <ListItem>
          <ListItemIcon>
            <Box component={'img'} src={`/img/teenyicons/${!!stripeAccount?.integrationCompleted ? 'auto-check' : 'x'}.svg`} width={16} height={16} />
          </ListItemIcon>
          Direct Deposit Initiated
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Box component={'img'} src={`/img/teenyicons/${!!stripeAccount?.payoutsEnabled ? 'auto-check' : 'x'}.svg`} width={16} height={16} />
          </ListItemIcon>
          Payouts in your account are enabled
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Box component={'img'} src={`/img/teenyicons/${!!stripeAccount?.fastPayoutsEnabled ? 'auto-check' : 'x'}.svg`} width={16} height={16} />
          </ListItemIcon>
          Fast payouts are enabled
        </ListItem>
      </List>
    </Loading>
  </Card>
}

export function StripeAccount({contractorId}: {contractorId?: string}) {
  const {stripeAccount, loading, refetch} = useStripeAccount(contractorId);
  const {save, loading: saveLoading} = useSetStripeAccount(refetch, contractorId);

  return <StripeAccountBase loading={loading} saveLoading={saveLoading} onSaveClick={save} stripeAccount={stripeAccount} />
}