const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  uploadUrl: process.env.REACT_APP_UPLOAD_URL,
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  filesUrl: process.env.REACT_APP_FILES_URL,
  s3FilesUrl: process.env.REACT_APP_S3_FILES_URL,
  isProd: process.env.REACT_APP_IS_PROD === 'true',
}

export default config;
