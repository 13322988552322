import {PositionStatus} from "../../../api/sdl";
import {FilterOption} from "./types";

export function filterToStatus(filter: FilterOption) {
  switch(filter) {
    case FilterOption.ALL:
      return [PositionStatus.Open, PositionStatus.Draft];
    case FilterOption.OPEN_ONLY:
      return [PositionStatus.Open];
    default:
      return [PositionStatus.Open];
  }
}