import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useContext} from "react";
import {KeycloakContext} from "../keycloak";
import {DefaultRedirects} from "../domain/routing/DefaultRedirects";

export function AppRoot() {

  const {authenticated, keycloak} = useContext(KeycloakContext)
  const location = useLocation()

  if(location.pathname === "/") {
    if(authenticated === true) {
      return <DefaultRedirects keycloak={keycloak} />
    } else if(authenticated === false) {

      return <Navigate to={"/contractor-onboarding"} />
    }
  }

  return <>
    <Outlet/>
  </>
}