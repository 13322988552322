import {Box, Link} from "@mui/material";
import config from "../../../config";
import React from "react";
import {AwardData} from "../lib/types";

export function AwardListView({awards}: {awards: AwardData[]}) {
    return <Box component={"ul"} sx={{padding: '0 0 0 16px', m: 0}}>
        {awards && awards.map((a) =>
            <Box component={"li"} key={a.id} sx={{my: 1}}>
                <Link href={`${config.s3FilesUrl}/${a.url}`}>{a.title}</Link>
            </Box>
        )}
    </Box>
}