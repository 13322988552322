import React from "react";
import {useSnackbar} from "notistack";
import {useConfirm} from "../../../shared/ConfirmDialogContext";
import {useDefineCommissionMutation} from "../../../api/sdl";
import {CommissionForm} from "../ui/CommissionForm";
import {CommissionEditButtonProps} from "../ui/CommissionEditButton";

export function useCommissionClick(onCompletedCallback: (commission: number) => void) {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const ref = React.useRef<any>();

  const [send] = useDefineCommissionMutation({
    onCompleted: res => {
      enqueueSnackbar('Commission had saved', {variant: 'info'});
      onCompletedCallback(res.defineCommission);
    }
  })

  const onSave = (args: CommissionEditButtonProps) => confirm({
    content: () => <CommissionForm ref={ref} initialValue={args.commission} />,
    validate: () => (ref.current as any).isValid(),
    width: 500,
    title: 'Change position commission',
    okButtonTitle: 'Save',
    cancelButtonTitle: 'Close',
  }).then(val => {!!val && send({
    variables: {positionId: args.positionId, platformCommission: (ref.current as any)?.getValues().platformCommission},
  })});

  return onSave;
}


