import React from "react";
import {useNavigate} from "react-router-dom";
import {Button, Menu, MenuItem} from "@mui/material";
import {closeSnackbar, useSnackbar} from "notistack";
import {useConfirm} from "../../../../shared/ConfirmDialogContext";
import {useDeletePositionMutation, useDuplicatePositionMutation} from "../../../../api/sdl";

const ActionsMenu = ({positionId}: {positionId?: string}) => {
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const action = (newPositionId: string) => (snackbarId: string) => (
    <>
      <Button variant={'text'} sx={{color: '#fff', '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.2)!important'}}}
              onClick={() => {
                closeSnackbar(snackbarId);
                navigate('../position/' + newPositionId);
              }}>
        OPEN
      </Button>
    </>
  );

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [duplicatePosition] = useDuplicatePositionMutation({
    variables: {positionId},
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Position was duplicated successfully', {variant: 'success', action: action(data.duplicatePosition)});
    },
  });
  const [deletePosition] = useDeletePositionMutation({
    variables: {positionId},
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Position was deleted successfully', {variant: 'info'});
      navigate('../positions');
    },
  });

  const onDeleteClick = () => confirm({
    content: 'Are sure you want to delete this position?',
    okButtonTitle: 'Yes - I want to delete this position',
    cancelButtonTitle: 'No - return to position'
  }).then(val => val && deletePosition());

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        sx={{minWidth: 0}}
        aria-controls={'basic-menu'}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(!open);
        }}
      >
        ...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        aria-labelledby="basic-demo-button"
      >
        <MenuItem onClick={() => {
          handleClose();
          duplicatePosition();
        }}>Duplicate</MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          onDeleteClick()
        }}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

export default ActionsMenu;
