import {
  Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle,
  Divider,
  Tab,
  tabClasses,
  Tabs,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Copy, Repeat} from "phosphor-react";
import config from "../../config";
import {ImprovementDialogFormData} from "./lib/types";
import {ImprovementDialogForm} from "./ui/ImprovementDialogForm";
import {CrossIconButton} from "../cross-icon-button";
import {CKEditor} from "@ckeditor/ckeditor5-react";

interface ImproveRoleDescriptionModalProps {
  onClose: (result?: string) => void,
  open: boolean,
  message?: string,
  companyName?: string,
  title: string,
}
const Editor = CKEditor as any;

export default function ImproveRoleDescriptionModal(props: ImproveRoleDescriptionModalProps) {
  const [result, setResult] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ImprovementDialogFormData>()
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setData(prev => ({
      years: prev?.years || '',
      companyName: props.companyName || '',
      message: props.message || '',
      title: props.title || '',
    }))
  }, [props.companyName, props.message]);

  useEffect(() => {
    if (props.open) {
      setTabIndex(0);
      setLoading(false);
      setResult(undefined);
    }
  }, [props.open]);

  const generate = (values?: ImprovementDialogFormData) => {
    if (!values) {
      values = data;
    }
    setResult(undefined)
    setTabIndex(0)
    setLoading(true)
    fetch(
      config.apiUrl + '/openai/generate-position-description',
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(res => res.json()).then(res => {
      setResult(res.result)
      setTabIndex(1);
    })
      .finally(() => setLoading(false))
  }

  const submit = (values: ImprovementDialogFormData) => {
    setData(values)
    generate(values)
  }

  return <Dialog open={props.open} onClose={() => props.onClose} maxWidth={'md'}>
    <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography>Improve Role Description</Typography>
      <CrossIconButton onClose={() => props.onClose()}/>
    </DialogTitle>
    <Divider sx={{my: 1}} />
    <DialogContent>
      <Tabs value={tabIndex}
            onChange={(e, val) => setTabIndex(val as number)}
             sx={{
               overflow: 'hidden',
               width: '100%',
               alignSelf: 'flex-start',
               [`& .${tabClasses.root}`]: {
                 bgcolor: 'transparent',
                 boxShadow: 'none',
                 '&:hover': {
                   backgroundColor: 'transparent',
                 },
                 [`&.${tabClasses.selected}`]: {
                   color: 'primary.plainColor',
                   fontWeight: 'lg',
                   '&:before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     zIndex: 1,
                     bottom: '-1px',
                     left: 'var(--ListItem-paddingLeft)',
                     right: 'var(--ListItem-paddingRight)',
                     height: '3px',
                     borderTopLeftRadius: '3px',
                     borderTopRightRadius: '3px',
                     backgroundColor: 'primary.500',
                   },
                 },
               },
             }}
        >
          <Tab value={0} label={'Request'} />
          <Tab value={1} disabled={!result} label={'Result'} />
      </Tabs>
      <Box p={2} sx={{position: 'relative', overflow: 'auto'}}>
        {loading && <Box sx={{
          zIndex: 3,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: 'rgba(255,255,255,0.8)'
        }}>
          <CircularProgress size="15"/>
        </Box>}
        {tabIndex === 0 && <ImprovementDialogForm initial={data} onSubmit={submit} editor={Editor}/>}
        {tabIndex === 1 && <div>
            <div dangerouslySetInnerHTML={{__html: result || ''}} />
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                <Button startIcon={<Repeat size={24} color="#6FB6FF" weight="regular" />}
                        variant={'outlined'}
                        onClick={() => generate()}
                >
                    Regenerate
                </Button>
                <Button startIcon={<Copy size={24} color="#fff" weight="regular" />}
                        onClick={() => props.onClose(result)}
                >
                    Copy to description
                </Button>
            </Box>
        </div>}
      </Box>
    </DialogContent>
  </Dialog>
}
