import {Autocomplete, Chip, TextField} from "@mui/material";
import * as _ from "lodash";
import {getCategoryColor} from "../utils";
import {XCircle} from "phosphor-react";
import React, {useMemo} from "react";
import useContractorAttributes from "../hooks/useContractorAttributes";

interface Props {
  ids: string[],
  onChange: (ids: string[]) => void,
}

const AttributesFilter = ({ids, onChange}: Props) => {
  const {loading, attributes} = useContractorAttributes();

  const value = useMemo(() => {
    return attributes.filter(a => ids.includes(a.id))
  }, [ids, attributes]);

  return <Autocomplete
    multiple
    sx={{minWidth: 252, maxWidth: 252}}
    loading={loading}
    options={attributes}
    limitTags={1}
    disableCloseOnSelect
    renderInput={(params) => <TextField placeholder="Types" {...params} />}
    getOptionLabel={(option) => option.attribute}
    groupBy={(option) => _.lowerCase(option.category)}
    value={value}
    onChange={(event, newValue) => onChange(newValue.map(a => a.id))}
    renderTags={(tags, getTagProps) =>
      tags.map((item, index) => (
        <Chip
          size={'small'}
          variant="outlined"
          color={getCategoryColor(item.category)}
          deleteIcon={<XCircle size={18} color="#fff" weight="fill" />}
          {...getTagProps({ index })}
          label={item.attribute}
        />
      ))
    }
  />
}

export default AttributesFilter
