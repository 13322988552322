import * as React from 'react';
import {SxProps} from "@mui/material/";
import {Tab, Tabs} from "@mui/material";

interface BottomNavItem {
  label: string,
  icon: JSX.Element,
  active: boolean,
  disabled?: boolean,
}

interface BottomNavProps {
  items: BottomNavItem[],
  index: number,
  onChange: (value: number) => void
}

export default function BottomNav({index, onChange, items}: BottomNavProps) {

  return (<Tabs
      sx={{position: 'fixed', bottom: 0, left: 0, width: 1, borderTop: '1px solid #DDE1E3', background: 'white'}}
      value={index}
      data-test='mobile-nav'
      onChange={(event, value) => onChange(value as number)}
    >
      {items.map((item, i) => <Tab sx={{
                                     ...tabStyle,
                                     color: item.active ? '#3075B8' : '#5D5E5F',
                                     fontSize: item.active ? 14 : 12,
                                     fontWeight: item.active ? 500 : 400,
                                   }}
                                   key={item.label + i}
                                   disabled={item.disabled}
                                   icon={item.icon}
                                   label={item.label} />)}
    </Tabs>
  );
}

const tabStyle: SxProps = {
  transition: 'all 0.3s',
  boxShadow: 'none',
  flex: 1,
  padding: '0 0 9px',
  textAlign: 'center',
  whiteSpace: 'pre-line',
}
