import * as yup from "yup";

export const schemaFields: any = {
  companyName: yup.string().required('Company name is required'),
  currentlyIsWorking: yup.boolean(),
  description: yup.string().required('Description is required'),
  endDateMonth: yup.string().test(
    'required',
    'This is required',
    function(item){
      return this.parent.currentlyIsWorking || item
    }
  ),
  endDateYear: yup.string()
    .test(
      'negativeDifference',
      'Should not be before start date',
      function(item){
        return (this.parent.endDateYear >= this.parent.startDateYear || this.parent.currentlyIsWorking)
      }
    )
    .test(
      'excessValue',
      'Should not be earlier than the current year',
      function(item){
        var date = new Date()
        return (this.parent.endDateYear <= date.getFullYear() || this.parent.currentlyIsWorking)
      }
    ),
  location: yup.string(),
    // @TODO fix conditional verification .required('Location is required'),
  startDateMonth: yup.string().required('This is required'),
  startDateYear: yup.string()
    .required('This is required')
    .test(
      'negativeDifference',
      'Should not be after end date',
      function(item){
        return (this.parent.endDateYear >= this.parent.startDateYear || this.parent.currentlyIsWorking)
      },
    )
    .test(
      'excessValue',
      'Should not be earlier than the current year',
      function(item){
        var date = new Date()
        return (this.parent.startDateYear <= date.getFullYear())
      }
    ),
  title: yup.string().required('Title is required'),
  id: yup.string(),
  specialities: yup
    .array()
    .min(1, 'You must choose at least one')
    .max(5, 'You must choose no more than five'),
  employmentTypes: yup
    .array(),
}
