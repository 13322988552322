import React from "react";
import {Box} from "@mui/material";

interface RoundedIconProps {
  icon: React.ReactElement<{width: number, height: number}>,
  background?: string,
  size?: number,
  iconSize?: number,
}

export const RoundedIcon = ({icon, background = '#E4E4E7', size = 48, iconSize = 24}: RoundedIconProps) => {
  return  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: size,
    width: size,
    height: size,
    background,
    borderRadius: '50%',
    '& > *': {
      width: iconSize,
      height: iconSize
    }
  }}>
    {icon}
  </Box>
}