import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";

const RejectCandidateDialogForm = React.forwardRef((props: {}, ref: any) => {
  const formik = useFormik({
    initialValues: { reason: '',},
    validationSchema: yup.object({reason: yup
        .string()
        .required('This is required'),}),
    onSubmit: (values) => {},
    validateOnChange: true
  });

  React.useImperativeHandle(ref, () => ({
    isValid() {
      return formik.submitForm().then(() => formik.validateForm()).then((res: any) => {
        return Object.keys(res).length === 0;
      });
    },
    getReason() {
      return formik.values.reason
    }
  }));

  return <form onSubmit={formik.handleSubmit}>
    <FormControl sx={{width: 1}}>
      <FormLabel>Why are you rejecting this candidate?*</FormLabel>
      <TextField placeholder=""
                 name="reason"
                 multiline
                 rows={5}
                 sx={{'& textarea': {overflowY: 'auto'}}}
                 value={formik.values.reason}
                 onChange={formik.handleChange}
                 error={formik.touched.reason && Boolean(formik.errors.reason)}
      />
      {(formik.touched.reason && formik.errors.reason) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.reason}</FormHelperText>}
    </FormControl>
  </form>
})

export default RejectCandidateDialogForm;
