import React from "react";
import {DateTime} from "luxon";
import {CalendarSlot, useMyCalendarLazyQuery} from "../../../api/sdl";

export function useContractorCalendar({startDate}: {startDate: DateTime}) {
  const [slots, setSlots] = React.useState<CalendarSlot[]>();
  const [available, setAvailable] = React.useState<boolean>();

  const [getCalendar, {data, loading}] = useMyCalendarLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    getCalendar({variables: {
      start: startDate.toFormat('yyyy-LL-dd'),
      end: startDate.plus({days: 30}).toFormat('yyyy-LL-dd'),
    }})
    .then(res => {
      const preCheck = res.data?.myCalendar?.length || 0
      setAvailable(preCheck > 0)
    });
  }, [startDate]);

  React.useEffect(() => {
    (async () => {
      if(available) {
        await getCalendar({variables: {
            start: startDate.toFormat('yyyy-LL-dd'),
            end: startDate.plus({days: 6}).toFormat('yyyy-LL-dd'),
          }}).then(res => {
          setSlots(res.data?.myCalendar || []);
        })
      } else {
        setSlots([]);
      }
    }) ();
  }, [startDate, available])


  return {
    slots,
    available,
    loading: loading || available === undefined
  }
}