import {Navigate} from "react-router-dom";

interface Props {
  keycloak: {
    hasRealmRole: (role: string) => boolean
  }
}

export function DefaultRedirects({keycloak}: Props) {
  if (keycloak.hasRealmRole("ROLE_ADMIN")) {
    return <Navigate to={"/admin/notifications"} />
  }

  if (keycloak.hasRealmRole("ROLE_COMPANY")) {
    return <Navigate to={"/company/positions"} />
  }

  if (keycloak.hasRealmRole("ROLE_CONTRACTOR")) {
    return <Navigate to={"/contractor"} />
  }
  return null
}