import {useCheckContractorSlotsLazyQuery} from "../../api/sdl";
import React from "react";
import {DateTime} from "luxon";

export default function useCalendarAvailability({contractorId, companyId, inc}: {contractorId: string, companyId: string, inc: number}) {

  const [checkSchedule] = useCheckContractorSlotsLazyQuery({fetchPolicy: 'no-cache'});
  const [meetingSlotsAreAvailable, setMeetingSlotsAreAvailable] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    if (contractorId && companyId) {
      (async function() {
        const currentWeek = await checkSchedule({variables: {
            contractorId
          }});

        if(currentWeek.data?.checkContractorSlotSufficiency != null) {
          setMeetingSlotsAreAvailable(false)
        } else {
          setMeetingSlotsAreAvailable(true)
        }

      })()
    }
  }, [contractorId, companyId, inc]);

  return {meetingSlotsAreAvailable};
}
