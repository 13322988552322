import React from "react";
import {
  PositionOutput,
  useConfirmPositionAsContractorMutation, useDeclinePositionAsContractorMutation
} from "../../api/sdl";
import {AcceptingForm} from "./ui/AcceptingForm";
import {useSnackbar} from "notistack";
import {useConfirm} from "../../shared/ConfirmDialogContext";
import {useNavigate} from "react-router-dom";

interface PositionApplicationFeatureTempProps {
  position: PositionOutput,
  meetingSlotsAreAvailable?: boolean,
  positionMatchId?: string,
}

/**
 * @TODO complete refactoring
 */
function CandidatePositionApplication({
                                          position,
                                          meetingSlotsAreAvailable,
                                          positionMatchId
                                        }: PositionApplicationFeatureTempProps) {
  const {enqueueSnackbar} = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [reject] = useDeclinePositionAsContractorMutation({
    variables: {positionMatchId: positionMatchId},
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: () => {
      enqueueSnackbar('Position declined successfully', {variant: 'success'})
      navigate('../positions')
    }
  });

  const [confirmPosition, {loading}] = useConfirmPositionAsContractorMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: () => {
      enqueueSnackbar('Position was confirmed successfully', {variant: 'success'})
      navigate('../positions')
    }
  });

  const onRejectClick = () => confirm({
    content: 'Do sure you want to decline this position?',
    okButtonTitle: 'Yes - I want to decline this position',
    cancelButtonTitle: 'No - return to position',
    width: 500
  }).then(val => val && reject());

  return <>
    <AcceptingForm loading={loading}
                   disabled={meetingSlotsAreAvailable === false}
                   onDecline={!!positionMatchId ? onRejectClick: undefined}
                   salaryRangeMin={position.salaryRangeMin}
                   salaryRangeMax={position.salaryRangeMax}
                   salaryRangeType={position.salaryRangeType}
                   onSubmit={contractorExpectedRate => {
                     confirmPosition({
                       variables: {
                         contractorExpectedRate,
                         positionId: position.id,
                       }
                     })
                   }}
    />
  </>

}

export {CandidatePositionApplication}