import * as React from 'react';
import BottomNav from "./BottomNav";
import {useMemo} from "react";

export default function PositionApproveBottomNav({index, onChange}: {
  index: number,
  onChange: (value: number) => void
}) {

  const items = useMemo(() => {
    return [
      {
        active: index === 0,
        icon: <img src={`/img/teenyicons/clipboard${index === 0 ? '-blue' : ''}.svg`} width={20} height={20}/>,
        label: 'Summary'
      },
      {
        active: index === 1,
        icon: <img src={`/img/teenyicons/text-document${index === 1 ? '-blue' : ''}.svg`} width={20} height={20}/>,
        label: 'Details'
      },
      {
        active: index === 2,
        icon: <img src={`/img/teenyicons/calendar${index === 2 ? '-blue' : ''}.svg`} width={20} height={20}/>,
        label: 'Calendar'
      },
    ]
  }, [index]);

  return <BottomNav items={items}
                  index={index}
                  onChange={onChange}/>
}
