import {Box, Button} from "@mui/material";
import {
  useSaveCalendarAvailabilityMutation,
} from "../../../api/sdl";
import React from "react";
import {DateTime} from "luxon";
import {CaretLeft, CaretRight} from "phosphor-react";
import {useContractorCalendar} from "../../../entity/calendar/api/useContractorCalendar";
import FullCalendarTable, {CalendarChangeValue} from "../../../feature/calendar/FullCalendarTable";
import {useDebouncedEffect} from "../../hooks/useDebouncedEffect";
import {useSnackbar} from "notistack";
import {CalendarSkeleton} from "../../../domain/matching_process";

export interface EditableUserCalendarProps {
  contractorId?: string,
  onCalendarUpdate?: () => void,
}

export default function EditableUserCalendar({contractorId, onCalendarUpdate}: EditableUserCalendarProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = React.useState(DateTime.now().startOf('week'));
  const [saveAvailability] = useSaveCalendarAvailabilityMutation();
  const {slots, available, loading} = useContractorCalendar({startDate});

  const [preSavedSlots, setPreSavedSlots] = React.useState<string[]>();

  const saveCalendarChange = () => {

    if(preSavedSlots) {
      return saveAvailability({variables: {
          contractorProfile: contractorId,
          payload: {
            startDate: startDate.toISODate(),
            endDate: startDate.plus({days: 5} ).toISODate(),
            availabilityTimes: preSavedSlots,
          }}}).then(() => {
            enqueueSnackbar('Calendar saved', {variant: 'success'})
            if(!!onCalendarUpdate) onCalendarUpdate();
      });
    }
  };

  useDebouncedEffect(() => {
      return saveCalendarChange();
  }, 2000, [preSavedSlots]);

  const handleCalendarChange = (update: CalendarChangeValue) => {
      setPreSavedSlots(update.slots);
  };

  return <Box>

    {loading && <CalendarSkeleton/>}

    {(!loading && slots !== undefined) && <FullCalendarTable slots={slots} startDate={startDate} onChange={handleCalendarChange} />}
{/*    {(!loading && !slots?.length && available === false && !isAdmin) &&
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '400px', gap: 2}}>
          <Typography>In order for a company to schedule an interview with you, you need to choose the times you are available.</Typography>
        </Box>
    }*/}

    {/*{(!loading && !slots?.length && !!available) && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '400px', gap: 2}}>*/}
    {/*  <Typography>There are no available slots in this week</Typography>*/}
    {/*</Box>}*/}

    <Box sx={{display: 'flex', gap: 5, justifyContent: 'center', mt: 4}}>
      <Button sx={{fontWeight: 800}}
              variant={'text'}
              size={'small'}
              data-test='prev-week'
              onClick={() => setStartDate(startDate.minus({week: 1}))}
              startIcon={<CaretLeft size={16} color="#000" />}>
        Previous Week
      </Button>
      <Button sx={{fontWeight: 800}}
              variant={'text'}
              size={'small'}
              data-test='next-week'
              onClick={() => setStartDate(startDate.plus({week: 1}))}
              endIcon={<CaretRight size={16} color="#000" />}>
        Next Week
      </Button>
    </Box>

  </Box>
}

// const convertToSlot = (date: string): CalendarSlot => ({date, status: CalendarSlotStatus.Available, properties: []});
