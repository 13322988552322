import * as React from 'react';
import * as _ from 'lodash';

export function useDebouncedCallback(cb: any, delay: number) {
  const options = {
    leading: false,
    trailing: true
  };
  const inputsRef = React.useRef(cb);
  const isMounted = useIsMounted();
  React.useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  return React.useCallback(
    _.debounce(
      (...args) => {
        if (inputsRef.current.delay === delay && isMounted())
          inputsRef.current.cb(...args);
      },
      delay,
      options
    ),
    [delay, _.debounce]
  );
}

function useIsMounted() {
  const isMountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return () => isMountedRef.current;
}
