import {useMigrateTypeformAnswersMutation} from "../../../api/sdl";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import React from "react";

export function MigrateTypeformAnswers() {
  const [migrate, migrateData] = useMigrateTypeformAnswersMutation({onCompleted:
      (e) => enqueueSnackbar("Done", {variant: 'success'})
  });

  return <LoadingButton onClick={() => migrate()} loading={migrateData.loading} variant={"contained"}>
    Import typeform answers
  </LoadingButton>
}