import {MenuItem, Select} from "@mui/material";
import React from "react";
import {FilterOption} from "../lib/types";

export function PositionStatusFilterSelector({value, onChange}: {value: FilterOption, onChange: (value: FilterOption) => void }) {
  return <>
    <Select size={'small'}
            data-test='client'
            value={value}

            onChange={(e) => {
              onChange(e.target.value as FilterOption)
            }}
    >
      <MenuItem value={FilterOption.ALL}>All</MenuItem>
      <MenuItem value={FilterOption.OPEN_ONLY}>Open only</MenuItem>
    </Select>
  </>
}
