import {Box, Button} from "@mui/material";
import {CaretLeft, CaretRight} from "phosphor-react";
import React from "react";

interface MeetingPaginationProps {
  hasPrevious?: boolean,
  hasNext?: boolean,
  onPreviousClick: () => void,
  onNextClick: () => void,
}
const MeetingPagination = (props: MeetingPaginationProps) => {
  return <Box sx={{display: 'flex', gap: 5, width: 1, justifyContent: 'center'}}>
    {props.hasPrevious && <Button sx={{fontWeight: 800}} variant={'text'} size={'small'} onClick={props.onPreviousClick} startIcon={<CaretLeft size={16} color="#000" />}>
        Previous Page
    </Button>}
    {props.hasNext && <Button sx={{fontWeight: 800}} variant={'text'} size={'small'} onClick={props.onNextClick} endIcon={<CaretRight size={16} color="#000" />}>
        Next Page
    </Button>}
  </Box>
}

export {MeetingPagination};