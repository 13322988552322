import {Box, Button, Drawer, Paper} from "@mui/material";
import {useState} from "react";
import {useIsAdmin} from "../../../../shared";
import {CrossIconButton} from "../../../../feature";
import {NotesEditor} from "./ui/NotesEditor";
import {ReferenceType} from "../../../../api/sdl";

interface Props {
  reference: string,
  referenceType: ReferenceType,
}

export function NotesDrawer(props: Props) {
  const isAdmin = useIsAdmin()
  const [isOpen, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  if(!isAdmin) {return null;}

  return <>
    <Button onClick={() => setOpen(!isOpen)}>
      Notes
    </Button>
    <Drawer
    open={isOpen}
    anchor={'right'}
    onClose={onClose}>
    <Paper
      sx={{
        borderRadius: 'md',
        p: 2,
        display: 'flex',

        flexDirection: 'column',
        gap: 2,
        width: '500px',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Box sx={{textAlign: "right", position: "absolute", right: "10px"}}>
        <CrossIconButton onClose={() => {setOpen(false)}} />
      </Box>
      <Box sx={{marginTop: 2}}>
        <NotesEditor {...props}/>
      </Box>
    </Paper>
  </Drawer></>
}