import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
  Button, TextField
} from "@mui/material";
import React from "react";
import {
  AttributeCategory,
  useContractorAttributeByIdLazyQuery,
  useSaveAttributeMutation
} from "../../../api/sdl";
import {useFormik} from "formik";
import * as yup from "yup";
import {useSnackbar} from "notistack";
import {AttributeCategorySelector} from "../../../feature/attribute-category-selector";
import {useNavigate, useParams} from "react-router-dom";

const schemaFields: any = {
  category: yup
    .string()
    .required('Category is required'),
  attribute: yup
    .string()
    .required('Attribute is required'),

}

export default function AttributeForm() {
  let { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [create, {loading}] = useSaveAttributeMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Attributes was saved successfully', {variant: 'success'})
      navigate('/attributes')
    }
  });
  const [getAttribute] = useContractorAttributeByIdLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    if (id) {
      getAttribute({variables: {id}}).then(response => {
        if (response.data?.contractorAttributeById) {
          formik.setValues({
            category: response.data.contractorAttributeById.category,
            attribute: response.data.contractorAttributeById.attribute
          })
        }
      })
    }
  }, [id])

  const formik = useFormik({
    initialValues: {
      category: '',
      attribute: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        create({
          variables: {
            id,
            payload: {
              category: values.category as AttributeCategory,
              attribute: values.attribute,
            }
          }
        })
      }
    },
    validateOnChange: true
  });

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 4}}>
      <Typography variant={'h3'} sx={{ml: 2}}>{id ? 'Edit' : 'Create'} attribute</Typography>
      <Button variant={'outlined'} color={'primary'}
              onClick={() => navigate('../attributes')}>Back</Button>
    </Box>
    <Card sx={{mt: 3, p: 3}}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1, maxWidth: 400}}>
          <FormControl error={formik.touched.category && !!formik.errors.category}>
            <FormLabel id="category-select-label" htmlFor="category-select">
              Category
            </FormLabel>
            <AttributeCategorySelector value={formik.values.category} onChange={ (value) => {
              formik.setFieldValue('category', value)
            }}/>
            {(formik.touched.category && formik.errors.category) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.category}</FormHelperText>}
          </FormControl>
          <FormControl>
            <FormLabel>Attribute</FormLabel>
            <TextField placeholder="Attribute"
                   name="attribute"
                   value={formik.values.attribute}
                   onChange={formik.handleChange}
                   error={formik.touched.attribute && Boolean(formik.errors.attribute)}
            />
            {(formik.touched.attribute && formik.errors.attribute) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.attribute}</FormHelperText>}
          </FormControl>
          <div>
            <Button disabled={loading} variant={'contained'} color={'primary'} type={'submit'}>{id ? 'Save' : 'Create'}</Button>
          </div>
        </Box>
      </form>
    </Card>
  </div>
}
