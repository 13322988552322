import {useNavigate} from "react-router-dom";
import {
  Container
} from "@mui/material";
import React, {useEffect} from "react";
import {useSnackbar} from "notistack";
import {ContractorCard} from "../../feature/contractor-card";
import {useContractorOwnProfileApi} from "../../domain/contractor-profile/api/useContractorOwnProfileApi";

const ProfessionalProfile = () => {

  const {data, refetch: refresh} = useContractorOwnProfileApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refresh()
  }, [refresh]);

  if(!data?.myProfile) {
    return null
  }

  return <Container sx={{p: {md: 3}}} maxWidth={'xl'}>
    {data && <ContractorCard profile={data.myProfile.contractorProfile || undefined}
                                userProfile={data.myProfile.profile || undefined}
                                editable
                                onUpdate={() => {
                                  enqueueSnackbar('Profile was saved successfully', {variant: 'success'})
                                  refresh()
                                }}
                                onEdit={() => {}}
                                onVideoEditClick={() => navigate('../video')}
                                showSideStripe={false}
                                educations={data.educations}
                                compositeAnswers={data.compositeAnswers} //TODO handle this in My Profile
    />}
  </Container>;
}

export default ProfessionalProfile
