import {Box, Chip, SxProps} from "@mui/material";
import React from "react";

const AttributesChips = ({attributes, color, sx}: {attributes?: string[], color: 'primary' | 'info', sx?: SxProps}) => <Box sx={sx}>
  {attributes?.map(attribute => (
    <Chip size={'small'}
          sx={{
            background: color === 'info' ? 'white' : '#3075b8',
            color: color === 'info' ? '#5D5E5F' : undefined,
            border: color === 'info' ? '1px solid #B0B3B2' : undefined,
            mr: 1,
            mb: 1
          }}
          label={attribute}
          key={attribute} />
  ))}
</Box>

export default AttributesChips;
