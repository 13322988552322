import React from "react";
import {Box, MenuItem, Select, Typography} from "@mui/material";

interface MeetingHeaderProps {
  showFilter: boolean,
  onFilterChange: (filter: string) => void,
}

export const MeetingHeader = (props: MeetingHeaderProps) => {
  const [filter, setFilter] = React.useState('all');

  const onChange = (val: string) => {
    setFilter(val);
    props.onFilterChange(val);
  }

  return <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between'}}>
    <Typography variant={'h2'} sx={{mb: 1}}>Interviews</Typography>
    {props.showFilter && <Select value={filter} onChange={(e) => onChange(e.target.value)}>
        <MenuItem value={'all'}>All interviews</MenuItem>
        <MenuItem value={'upcoming'}>Upcoming</MenuItem>
        <MenuItem value={'past'}>Past</MenuItem>
    </Select>}
  </Box>
}