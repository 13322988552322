import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import SalaryRangeTypeSelect from "../../../../../../shared/components/SalaryRangeTypeSelect";
import React from "react";
import {Minus, Plus} from "phosphor-react";

interface Props {
  formik: any,
  rangeIsExpanded: boolean,
  onRangeToggleClicked: () => void,
}
export function SalaryField({formik, rangeIsExpanded, onRangeToggleClicked}: Props) {

  return <FormControl error={!!formik.touched.salaryRangeType && !!formik.errors.salaryRangeType}
                      sx={{
                        width: 1,
                        flexDirection: {xs: "column", md: "row"},
                        display: 'flex',
                        gap: {xs: 1, md: 3},
                        alignItems: 'flex-start'
                      }}>
    <Box sx={{width: 1, maxWidth: '250px'}}>
      <FormLabel>Salary:</FormLabel>
      <SalaryRangeTypeSelect value={formik.values.salaryRangeType}
                             data-test='salary-type'
                             sx={{width: 1}}
                             onChange={value => {
                               formik.setFieldValue('salaryRangeType', value)
                             }}
                             error={formik.touched.salaryRangeType && formik.errors.salaryRangeType ? formik.errors.salaryRangeType : ''}
      />
    </Box>
    <FormControl sx={{width: 1, marginTop: '20px', maxWidth: '250px'}}>
      <TextField placeholder="From"
                   data-test='salary-min'
                   name="salaryRangeMin"
                   InputProps={{
                     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                   }}
                   type={'number'}
                   value={formik.values.salaryRangeMin}
                   onChange={formik.handleChange}
                   error={formik.touched.salaryRangeMin && Boolean(formik.errors.salaryRangeMin)}
      />
      {(formik.touched.salaryRangeMin && formik.errors.salaryRangeMin) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.salaryRangeMin}</FormHelperText>}
    </FormControl>

    {rangeIsExpanded &&
        <Box sx={{flexDirection: "row", display: "flex",}}>
            <Box sx={{marginTop: "20px", marginRight: 2, alignContent: "center"}}>
                <Typography> to </Typography>
            </Box>
            <FormControl sx={{width: 1, marginTop: '20px', maxWidth: '250px'}}>

                <TextField placeholder="To"
                           data-test='salary-max'
                           name="salaryRangeMax"
                           InputProps={{
                             startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           type={'number'}
                           value={formik.values.salaryRangeMax}
                           onChange={formik.handleChange}
                           error={formik.touched.salaryRangeMax && Boolean(formik.errors.salaryRangeMax)}
                />
              {(formik.touched.salaryRangeMax && formik.errors.salaryRangeMax) &&
                  <FormHelperText sx={{color: '#D3232F'}}
                                  data-test="salary-max-error">{formik.errors.salaryRangeMax}</FormHelperText>}
            </FormControl>
        </Box>
    }

    <Box sx={{minWidth: '170px', textAlign: "left", marginTop: '20px'}}>
      <Button startIcon={
        rangeIsExpanded ? <Minus size={18} color="#128eaf" weight="regular" /> : <Plus size={18} color="#128eaf" weight="regular" />

      }
              variant="text"
              disabled={false}
              data-test="toggle-salary-range"
              onClick={onRangeToggleClicked}
      >
        {rangeIsExpanded ? "Remove range": "Add range"}
      </Button>
    </Box>

  </FormControl>
}