import {useFormik} from "formik";
import * as yup from "yup";
import React from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel, Input, Stack, TextField} from "@mui/material";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {ImprovementDialogFormData} from "../lib/types";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const schemaFields: any = {
  years: yup
    .string()
    .required('This is required'),
  message: yup
    .string()
    .required('This is required'),
  companyName: yup
    .string()
    .required('This is required'),
}


interface Props {
  initial?: ImprovementDialogFormData,
  onSubmit: (data: ImprovementDialogFormData) => void,
  editor: any
}
const Editor = CKEditor as any;

export const ImprovementDialogForm = ({initial, onSubmit}: Props) => {
  const formik = useFormik({
    initialValues: {
      years: '',
      message: '',
      companyName: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        onSubmit(values)
      }
    },
    validateOnChange: true
  });

  React.useEffect(() => {
    if (initial) {
      formik.setValues({
        years: initial.years || '',
        message: initial.message || '',
        companyName: initial.companyName || '',
      })
    }
  }, [initial]);

  return <form onSubmit={formik.handleSubmit}>
    <Stack spacing={2}>
      <FormControl>
        <FormLabel>Company name</FormLabel>
        <TextField placeholder="Company name"
               name="companyName"
               data-test="companyName"
               value={formik.values.companyName}
               onChange={formik.handleChange}
               error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        />
        {(formik.touched.companyName && formik.errors.companyName) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.companyName}</FormHelperText>}
      </FormControl>
      <FormControl>
        <FormLabel>Years of experience</FormLabel>
        <TextField placeholder="Years"
               name="years"
               data-test="years"
               autoFocus
               value={formik.values.years}
               onChange={formik.handleChange}
               error={formik.touched.years && Boolean(formik.errors.years)}
        />
        {(formik.touched.years && formik.errors.years) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.years}</FormHelperText>}
      </FormControl>
      <FormControl>
        <FormLabel>Position description</FormLabel>
        <Editor
          editor={ ClassicEditor }
          data={formik.values.message}
          data-test="message"
          config={ {
            toolbar: ["undo", "redo", "|", "heading", "|", "bold", "italic", "|", "link", "|", "bulletedList", "numberedList", "outdent", "indent"]
          }}
          onReady={ (editor: any) => {
            (window as any)['CKEDITOR'] = editor;
            // You can store the "editor" and use when it is needed.
            console.log( 'Editor is ready to use!', (window as any)['CKEDITOR'] );
          } }
          onChange={ ( event: any, editor: any ) => {
            const data = editor.getData();
            formik.setFieldValue('message', data)
          } }
        />
        {(formik.touched.message && formik.errors.message) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.message}</FormHelperText>}
      </FormControl>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button type={'submit'} variant={"contained"}>Generate content</Button>
      </Box>
    </Stack>
  </form>
}
