import {Maybe} from "../../../../../api/sdl";
import React from "react";
import {Autocomplete, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import * as _ from "lodash";

interface Props {
  error?: string,
  value: Maybe<string>,
  onChange: (value: Maybe<string>) => void,
  companies: {id: string, name: string}[],
}

const CompanySelect = (props: Props) => {
  return <FormControl disabled={!props.companies.length} error={!!props.error}>
    <FormLabel >
      Company
    </FormLabel>
    <Autocomplete
      placeholder="Select company"
      filterOptions={(options, state) => (
        options.filter(o => (
          _.lowerCase(state.getOptionLabel(o)).includes(_.lowerCase(state.inputValue))
        ))
      )}
      options={props.companies.map(a => a.id)}
      value={props.value}
      onChange={(event, newValue) => {
        props.onChange(newValue);
      }}
      getOptionKey={o => o}
      getOptionLabel={id => props.companies.find(c => c.id === id)?.name || ''}
      sx={{ width: '500px' }}
      renderInput={(params) => <TextField {...params} />}
    />
    {props.error && <FormHelperText sx={{color: '#D3232F'}}>{props.error}</FormHelperText>}
  </FormControl>
}

export default CompanySelect;
