import {HarvestCompanyDto, useHarvestClientsQuery} from "../../api/sdl";
import {CircularProgress, FormControl, FormHelperText, FormLabel, MenuItem, Select} from "@mui/material";
import React, {useMemo} from "react";

interface HarvestClientSelectProps {
  value: string | null,
  onChange: (value: string | null) => void,
  error?: string,
  disabled?: boolean,
}

export default function HarvestClientSelect(props: HarvestClientSelectProps) {
  const {clients, loading} = useHarvestClients();

  return <FormControl  sx={{width: 1}} disabled={!clients.length} error={!!props.error}>
    <FormLabel >
      Harvest Client
    </FormLabel>
    <Select size={'small'}
            data-test='client'
            value={props.value}
            startAdornment={loading && <CircularProgress size={15} />}
            disabled={loading || props.disabled}
            onChange={(e) => props.onChange(e.target.value)}
    >
      {clients.map((v: HarvestCompanyDto) => (
        <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
      ))}
    </Select>
    {props.error && <FormHelperText sx={{color: '#D3232F'}}>{props.error}</FormHelperText>}
  </FormControl>

}


export const useHarvestClients = () => {
  const {data, loading, called} = useHarvestClientsQuery({fetchPolicy: "no-cache"});

  const clients = useMemo(() => {
    return data?.harvestClients || [];
  }, [data?.harvestClients]);

  return {clients, loading: loading || !called}
}
