import {Alert, Box, Typography} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";

export function SlotAvailabilityAlert() {
  const firstRange = `${DateTime.now().plus({days: 0}).toFormat("LLL dd")} and ${DateTime.now().plus({days: 6} ).toFormat("LLL dd")}`;

  return <Alert sx={{my: 2, fontWeight: 500, fontSize: 14, borderRadius: 2}} variant={'standard'} color={'error'}>
    <Typography>Click on the gray times that you are available to interview. The times will then turn blue to highlight when you are available to interview.</Typography>
    <Typography sx={{pt:1}}>Please add <Typography fontWeight={600} sx={{textDecoration: 'underline'}} component={"span"}>multiple times and days</Typography> you are available to interview between <Typography fontWeight={600} sx={{textDecoration: 'underline'}} component={"span"}>{firstRange}</Typography>. The more times you add, the quicker you will be scheduled.</Typography>
    <Typography sx={{pt:1}}>After you add time, please scroll down, confirm the rate, and click accept. Your application will be sent to the company.</Typography>
  </Alert>;
}
