import {Box} from "@mui/material";
import React from "react";
import {CalendarCell} from "./CalendarCell";
import {MeetingSlotCoords} from "../lib/types";
import {CalendarUtil} from "../lib/CalendarUtil";

export interface CalendarRowProps {
    rowNumber: number,
    availabilityCoords: GridCoordinate[],
    onMouseDown?: (coord: GridCoordinate) => void,
    onMouseUp?: (coord: GridCoordinate) => void,
    onMouseOver?: (coord: GridCoordinate) => void,
    onCellClick: (coord: GridCoordinate) => void,
    meetings: MeetingSlotCoords[],
    selectedCoord?: undefined | MeetingSlotCoords,
}

export interface GridCoordinate {
    row: number,
    column: number,
}

export function CalendarRow({
                                availabilityCoords,
                                rowNumber,
                                onMouseDown,
                                onMouseUp,
                                onMouseOver,
                                onCellClick,
                                meetings,
                                selectedCoord
                            }: CalendarRowProps) {
    const range = [...Array(5).keys()]

    return <tr>
        {range.map((i) => <Box
                component={'td'}
                key={i}
                sx={{height: '50px'}}
                data-cell={`${i}-${rowNumber}`}
                onMouseDown={!!onMouseDown ? () => {
                    onMouseDown({row: rowNumber, column: i})
                } : undefined}
                onMouseUp={!!onMouseUp ? () => {
                    onMouseUp({row: rowNumber, column: i})
                } : undefined}
                onMouseOver={!!onMouseOver ? () => {
                    onMouseOver({row: rowNumber, column: i})
                } : undefined}
                onClick={() => onCellClick({row: rowNumber, column: i})}
            >
                <CalendarCell
                    text={''}
                    isSelected={selectedCoord?.row === rowNumber && selectedCoord?.column === i}
                    time={CalendarUtil.formatTime(rowNumber)}
                    filled={!!availabilityCoords.find((s) => s.row === rowNumber && s.column === i)}
                    meeting={meetings.find((s) => s.row === rowNumber && s.column === i)}
                />
            </Box>
        )}
    </tr>
}
