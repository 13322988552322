import {Box, IconButton, Skeleton, Stack, Typography,} from "@mui/material";
import React from "react";
import Loading from "../../../../shared/components/Loading";
import {X} from "phosphor-react";
import {MeetingCard} from "../../../../entity/meeting/ui/MeetingCard";
import {MeetingControlButtons} from "../../../../entity/meeting/ui/MeetingControlButtons";
import {usePositionMatchApi} from "../../api/usePositionMatchApi";

interface Props {
  positionMatchId: string,
  meetingId: string,
  positionTitle?: string,
  contractorName?: string,
  onCloseClick: () => void,
}

function MeetingPopup(props: Props) {
  const {positionMatchId, positionTitle, contractorName, meetingId, onCloseClick} = props;

  const {loading, meeting} = usePositionMatchApi({positionMatchId: positionMatchId, meetingId: meetingId})

  if(!meeting || loading) {
    return <Stack spacing={1} sx={{p: '16px 18px', borderRadius: '10px',}}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  }

  return (
    <Box data-test='popup' sx={{borderRadius: '10px', p: '16px 18px'}}>
      <Loading in={loading} height={35}>
        <Stack spacing={1}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography sx={{fontWeight: 500}}>{contractorName}</Typography>
            <IconButton size={'small'}
                        color={'secondary'}
                        onClick={onCloseClick}>
              <X size={16} color="#000" weight="regular" />
            </IconButton>
          </Box>
          <Typography sx={{fontWeight: 500, mb: 1}}>{positionTitle}</Typography>
          {meeting && <MeetingCard meeting={meeting}
                                   slots={{
                                     button: <MeetingControlButtons meeting={meeting}
                                                                    positionMatchId={positionMatchId}
                                                                    onButtonClick={onCloseClick}
                                                                    onCancelMeeting={() => {
                                                                      window.location.reload();
                                                                    }} />
                                   }}
          />}
        </Stack>
      </Loading>
    </Box>
  )
}
export default MeetingPopup
