import {EducationApiData, EducationFormFields} from "../education-form/types";
import {EducationEditorModal} from "../education-editor-modal/EducationEditorModal";
import React, {useState} from "react";
import {DisplayMode} from "../../../../feature/employment-history-editable-list/lib";
import {Box, Button, Stack, Typography} from "@mui/material";
import {
  useCopyEducationFromResumeMutation,
  useDeleteEducationMutation,
  useSaveEducationMutation,
} from "../../../../api/sdl";
import {EducationView} from "./ui/EducationView";
import {toApiPayload} from "../education-form/utils";
import {Editable} from "../../../../shared";

interface Props extends Editable{
  educations: EducationApiData[],
  contractorId?: string,
  updatableFromCv?: boolean;
  onUpdate: () => void,
}
export function EducationEditableList(props: Props) {

  const [showEditor, setShowEditor] = useState(false);
  const [modalEdId, setModalEdId] = useState<string>();
  const [displayMode, setDisplayMode] = React.useState(DisplayMode.View);
  const [saveEducation] = useSaveEducationMutation();

  const [deleteApi] = useDeleteEducationMutation();
  const [copyFromResumeRequest] = useCopyEducationFromResumeMutation({variables: {contractorProfileId: props.contractorId}});

  const onFormUpdate = async (value: EducationFormFields) => {

    const payload =  toApiPayload(value, modalEdId);

    await saveEducation({variables: {payload, contractorProfileId: props.contractorId}});
    setModalEdId(undefined);
    setShowEditor(false);
    props.onUpdate()
  }


  function handleAddClick() {
      setModalEdId(undefined)
      setShowEditor(true)
  }

  async function handleCopyFromResume() {
    await copyFromResumeRequest()
    props.onUpdate()
  }

  function handleEditClick() {
    if(displayMode === DisplayMode.Edit) {
      setDisplayMode(DisplayMode.View)
    } else {
      setDisplayMode(DisplayMode.Edit)
    }
  }

  function editItem(educationId: string) {
    setModalEdId(educationId)
    setShowEditor(true)
  }

  function resetModal() {
    setShowEditor(false)
    setModalEdId(undefined)
  }

  async function deleteItem(id: string) {

    await deleteApi({variables: {id}})
    resetModal()

  }

  return <>
    <Box sx={{display: 'flex', gap: 2, justifyContent: 'space-between', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'flex-start', mb: 2}}>
      <Typography sx={{fontWeight: 700, fontSize: 24}}>Education</Typography>
      <Box sx={{display: 'flex', gap: 3}}>
        {displayMode === DisplayMode.Edit && props.updatableFromCv && <Button size='small' variant='outlined' onClick={handleCopyFromResume} data-test={"add-education-button"}>Copy from my Resume</Button>}
        {displayMode === DisplayMode.Edit && <Button size='small' variant='outlined' onClick={handleAddClick} data-test={"add-education-button"}>Add new</Button>}
        {props.editable && <Button size='small' variant='outlined' onClick={handleEditClick} data-test={"edit-education-button"}>
          {displayMode === DisplayMode.View? 'Edit' : 'Preview'}
        </Button>}
      </Box>
    </Box>

    <EducationEditorModal open={showEditor}
                          educationId={modalEdId}
                          onClose={resetModal}
                          onDelete={async () => {
                            if(modalEdId) {
                              await deleteItem(modalEdId)
                              props.onUpdate()
                            }
                          }}
                          onFormSubmit={onFormUpdate}/>

    <Stack spacing={4}>
      {!!props.educations && props.educations?.map((ed, i, {length}) => <React.Fragment key={i}>
          <EducationView education={ed} editable={displayMode === DisplayMode.Edit} onEditClick={() => editItem(ed.id)} />
      </React.Fragment>)}

      {!props.educations?.length && <Box sx={{display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', width: 1}}>
          <Typography sx={{my: 10}} data-test="no-educations">No education available</Typography>
      </Box>}

    </Stack>
  </>
}

